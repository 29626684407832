import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ee80b20"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 3,
  class: "none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === 'success')
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(_ctx.type)
      }, "✓", 2))
    : (_ctx.type === 'warning')
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(_ctx.type)
        }, null, 2))
      : (_ctx.type === 'danger')
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(_ctx.type)
          }, "X", 2))
        : (_openBlock(), _createElementBlock("span", _hoisted_1))
}