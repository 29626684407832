<template>
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="addGuideModal">
    <div class="modal-lg modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Add a new guide for ${currentClinicName}` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <add-guide :initialUser="initialUser" />
      </div>
    </div>
  </div>
  <!-- end modal -->
  <div :class="`card `">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Resources </span>
      </h3>
      <div class="row">
        <select name="" id="" v-model="filter.category" class="col">
          <option value="">All</option>
          <option v-for="cat, index in categories" :key="index" :value="cat"> {{cat}} </option>
        </select>
        <div class="col-1"></div>
        <input
          placeholder="Search..."
          type="text"
          v-model="filter.title"
          class="form-control form-control-solid col"
          id="source"
        />
      </div>

      <!-- <div
        class="card-toolbar mr-3 "
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a new guide"
      >
        <a
          @click="newGuide()"
          class="btn btn-sm btn-light-primary add-user btn-shadow font-14"
        >
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotone/Home/Book.svg" />
          </span>
          New Guide
        </a>
      </div> -->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 row">
      <!--begin::Table container-->
      <div class="table-responsive row">
        <!--begin::Table-->
        <div class="row mb-10 mt-5">
          <div
            v-for="(guide, index) in filteredGuides"
            :key="index"
            class=" col-12 col-sm-6 col-md-4 mb-10   d-flex align-items-stretch"
          >
          <div class="card mx-2 shadow-hover width-fill-available">
            <a :href="guide.link" target="_blank" style="width: -webkit-fill-available">
              <img
                style="width: inherit;"
                :src="`/GuideImages/${guide.image}.png`"
                onerror="this.src='/placeholder-image.png';"
              />
              <div class="card-body">
                <h5 class="card-title">
                  <span class="col">{{ guide.title }}</span>
                </h5>
              </div>
            </a>
          </div>

          </div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 9-->
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import AddGuide from "@/components/Guides/AddGuide.vue";
export default defineComponent({
  name: "Guides",
  components: { AddGuide },
  data() {
    return {
      addGuideModal: null,
    };
  },
  async beforeMount() {},

  async mounted() {
    let modalElement = document.getElementById("addGuideModal");
    this.addGuideModal = new bootstrap.Modal(modalElement);
  },
  methods: {
    newGuide() {
      this.addGuideModal.show();
    },
  },
  computed: {
    categories(){
      let cats = [];
      this.guides.forEach(guide=>{
        if(cats.indexOf(guide.category) == -1){
          cats.push(guide.category)
        }
      })
      return cats;
    },
    filteredGuides() {
      let guides = [];
      this.guides.forEach((guide) => {
        if (
          guide.title
            .toLowerCase()
            .includes(`${this.filter.title.toLowerCase()}`) &&  guide.category.toLowerCase().includes(`${this.filter.category.toLowerCase()}`)
        ) {
          guides.push(guide);
        }
      });
      return guides;
    },
  },
  setup() {
    setCurrentPageTitle("Resources");
    const store = useStore();
    const initialUser = store.getters.currentUser;
    const currentClinicName = initialUser.currentClinic.name;
    let filter = reactive({
      title: "",
      category: "",
    });
    const guides = [
      {
        category: "Guide",
        image: "BestPracticesCleaningDisinfection",
        title:
          "Best Practices for Cleaning, Disinfection and Sterilization of Devices",
        link:
          "https://www.publichealthontario.ca/-/media/documents/b/2013/bp-cleaning-disinfection-sterilization-hcs.pdf?sc_lang=en",
      },
      {
        category: "Guide",
        image: "CDHO-Covid19",
        title: "COVID-19: Guidance on IPAC in Dental Hygiene Practice",
        link:
          "https://www.cdho.org/docs/default-source/pdfs/reference/guidelines/gui-returning-to-practice.pdf",
      },
      {
        category: "Guide",
        image: "DecisionTree",
        title: "Decision Tree for Use of Chemical Indicators",
        link:
          "https://www.cdho.org/docs/default-source/pdfs/reference/guidelines/decision_tree_chemical_indicators.pdf",
      },
      {
        category: "Guide",
        image: "QuickReferenceDentalInstrumentReprocessing",
        title: "Dental instrument reprocessing quick reference",
        link:
          "https://www.regionofwaterloo.ca/en/health-and-wellness/resources/Documents/Dental_IPAC_Poster.pdf",
      },
      // {
      //   category: "Guide",
      //   image: "DubaiGuidelinesDentalInfection",
      //   title: "Guidelines on Dental Infection Prevention and Safety",
      //   link:
      //     "https://www.dha.gov.ae/en/HealthRegulation/Documents/Dental%20Infection%20Control%20Guidelines.pdf",
      // },
      {
        category: "Guide",
        image: "CDHO-IPAC Guidelines",
        title: "Infection Prevention and Control (IPAC) Guidelines",
        link:
          "https://www.cdho.org/docs/default-source/pdfs/reference/guidelines/cdho-ipac-guidelines.pdf",
      },
      {
        category: "Guide",
        image: "IPAC-DentalCheckList",
        title: "IPAC CHECKLIST FOR DENTAL PRACTICE",
        link:
          "https://www.publichealthontario.ca/-/media/documents/C/2019/checklist-ipac-dental-core.pdf",
      },
      {
        category: "Guide",
        image: "RprocessingDentalEquipment",
        title: "Reprocessing of Dental/Medical Devices",
        link:
          "https://www.publichealthontario.ca/-/media/documents/c/2019/checklist-ipac-dental-reprocessing.pdf?la=en",
      },
      {
        category: "Guide",
        image: "SettingTheRecordStraight",
        title:
          "Setting The Record Straight: The Facts About Steam Chemical Indicators",
        link:
          "https://www.cdho.org/docs/default-source/pdfs/reference/guidelines/steam-chemical-indicators.pdf",
      },
      {
        category: "Guide",
        image: "StandardPracticeODA",
        title: "STANDARD OF PRACTICE IPAC in the Dental Office",
        link:
          "https://az184419.vo.msecnd.net/rcdso/pdf/standards-of-practice/RCDSO_Standard_of_Practice_IPAC.pdf",
      },
      {
        category: "Guide",
        image: "ODA_WHMIS",
        title: "A Guide for Dental Practices 2016-2017",
        link: "https://oda.ca/oda-resources/CPAE-resources/ASM21-Speaker-Handouts/McDermott-Neil/ODA-WHMIS-Program-Guide-for-Dental-Practices.pdf"
      },
      // {
      //   category: "IPAC Course",
      //   image:
      //     "https://lms.publichealthontario.ca/data/SCORM/161/index_lms_html5.html",
      //   title: "IPAC Core Copetencies: Additional Precautions",
      //   link:
      //     "https://lms.publichealthontario.ca/data/SCORM/161/index_lms_html5.html",
      // },
      {
        category: "IPAC Course",
        image: "BestPracticesCleaningDisinfection",
        title:
          "IPAC Reprocessing: Personal Protective Equipment for Reprocessing",
        link: "https://lms.publichealthontario.ca/lime/bins/Dashboard.aspx",
      },
      // {
      //   category: "IPAC Online Course",
      //   image: "OPHcourses",
      //   title: "IPAC Reprocessing: Steam Sterilization",
      //   link:
      //     "https://lms.publichealthontario.ca/lime/Pages/Courses/Details.aspx?id=477&lang=1",
      // },
      {
        category: "Manual",
        image: "TuttnauerOperationAndMaintenance",
        title: "Elara 11 Operation and Maintenance Manual",
        link:
          "https://tuttnauerusa.com/wp-content/uploads/2019/07/Elara11-Operators-Manual-2017.pdf",
      },
      {
        category: "Manual",
        image: "UserGuideMidmarkM9-M11",
        title:
          "M9 / M11 Self -Contained Steam Sterilizer Installation Operation Guide",
        link:
          "https://usermanual.wiki/m/0646a6c016e7a5706168dcad161db6df4ff0c1bd1816b2128cf4f9bc250289f4.pdf",
      },
      {
        category: "Manual",
        image: "Statim2000-5000G4",
        title: "STATIM® 2000/5000 G4 CASSETTE AUTOCLAVE",
        link: "https://www.scican.com/us/media/autoclaves/95-113364-4.pdf",
      },
       {
        category: "Tutorial Video",
        image: "CabPrintHeadCleaningPlay",
        title: "Printer video tutorials",
        link:
          "https://www.cab.de/en/marking/label-printer/mach1-2/#anwendungen",
      },

      {
        category: "Tutorial Video",
        image: "CabPrintHeadCleaningPlay",
        title: "Printer Mainenance: Cleaning Print Head",
        link:
          "https://www.youtube.com/watch?v=TlNe5tQ8UI0",
      },
      {
        category: "Tutorial Video",
        image: "CabInsertingLablesPlay",
        title: "Printer Operation: How to load labels and ribbons",
        link: "https://www.youtube.com/watch?v=qWO-an5vAiM",
      },
      {
        category: "Tutorial Video",
        image: "CabInsertingRibbonPlay",
        title: "Printer Operation: Inserting Ribbon",
        link: "https://www.youtube.com/watch?v=_yAqh-HYhys",
      },
      {
        category: "Tutorial Video",
        image: "CabPrintRollerCleaningPlay",
        title: "PrinterMainenance: Cleaning Print Roller",
        link: "https://www.youtube.com/watch?v=4W9f_vschxI",
      },
    ];
    return {
      filter,
      guides,
      initialUser,
      currentClinicName,
    };
  },
});
</script>

<style lang="scss" scoped>
.btn-shadow {
  box-shadow: 4px 4px 5px -3px grey !important;
}
.shadow-hover {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 40%) !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 100%) !important;
}
.font-14 {
  font-size: 14px !important;
}
.width-fill-available{
  width: -webkit-fill-available;
}
</style>
