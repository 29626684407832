
import { defineComponent, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { format } from "date-fns";
import LookupPackage from "@/components/LookupPackage.vue";
import NoPackage from "@/components/NoPackage.vue";
import FailPackages from "@/components/FailPackages.vue";
import * as bootstrap from "bootstrap";

export default defineComponent({
  name: "kt-widget-9",
  components: { LookupPackage, NoPackage, FailPackages },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      packageList: new Array<any>(),
      failNames: new Array<any>(),
      affectedIds: new Array<any>(),
      command: "",
      re: new RegExp(`~(.+):\\sL#(\\d+)\\sP(\\d+)`),
      checked: false,
      checkboxes: new Array<any>(),
      showLookupPackage: false,
      showNoPackage: false,
      showFailPackages: false,
      lookupModal: <bootstrap.Modal>null,
      errorModal: <bootstrap.Modal>null,
      failModal: <bootstrap.Modal>null,
      errorPackage: "",
      allSelected: false,
    };
  },
  mounted() {
    var lookupModalElement = document.getElementById("lookup_package");
    this.lookupModal = new bootstrap.Modal(lookupModalElement);
    if (lookupModalElement) {
      var selfModal = this;
      lookupModalElement.addEventListener("hidden.bs.modal", function (event) {
        selfModal.showLookupPackage = false;
      });
    }

    var errorModalElement = document.getElementById("no_package");
    this.errorModal = new bootstrap.Modal(errorModalElement);
    if (errorModalElement) {
      var selfModal = this;
      errorModalElement.addEventListener("hidden.bs.modal", function (event) {
        selfModal.showNoPackage = false;
      });
    }

    var failModalElement = document.getElementById("fail_packages");
    this.failModal = new bootstrap.Modal(failModalElement);
    if (failModalElement) {
      var selfModal = this;
      failModalElement.addEventListener("hidden.bs.modal", function (event) {
        selfModal.showFailPackages = false;
      });
    }

    const self = this;
    if (!window.onkeydown) {
      window.onkeydown = function (ev) {
        self.keyPress(ev); // declared in your component methods
      };
    }
  },
  methods: {
    checkboxesLength() {
      return this.checkboxes.filter(Boolean).length;
    },
    isAnySelected() {
      if (this.checkboxesLength() >= 1) return this.checkboxesLength();
      return;
    },
    selectOne(index) {
      if (this.checkboxes[index] && this.allSelected) this.allSelected = false;
      else if (
        !this.checkboxes[index] &&
        this.checkboxesLength() == this.packageList.length - 1
      )
        this.allSelected = true;
    },
    uncheckAll() {
      this.allSelected = false;
    },
    selectAll() {
      if (!this.allSelected) {
        for (var key in this.packageList) this.checkboxes[key] = true;
      } else {
        for (var key in this.packageList) this.checkboxes[key] = false;
      }
    },
    getDate(packageDate) {
      if (packageDate !== null)
        return format(new Date(packageDate), "yyyy-MMM-dd");
      else return "Not Found";
    },
    getStatus(packageStatus) {
      if (packageStatus != null)
        if (packageStatus == true) return "Verified";
        else return "Failed";
      return "Unverified";
    },
    async verifyPackages() {
      this.affectedIds.length = 0;
      for (var key in this.checkboxes)
        if (this.checkboxes[key])
          this.affectedIds.push(this.packageList[key].loadedPackageId);

      var pack = {
        ids: this.affectedIds,
        passFail: true,
        notes: "",
      };
      try {
        await ApiService.postData("Package/result", pack, {});
        for (var key in this.checkboxes) {
          if (this.checkboxes[key]) this.packageList[key].type5Result = true;
        }
      } catch (ex) {
        alert(
          "An error updating the database has occurred. Please contact support."
        );
      }
    },
    failPackages() {
      this.failNames.length = 0;
      this.affectedIds.length = 0;
      for (var key in this.checkboxes) {
        if (this.checkboxes[key]) {
          let pack = {
            name: this.packageList[key].name,
            id: this.packageList[key].id,
          };
          this.failNames.push(pack);
          this.affectedIds.push(this.packageList[key].loadedPackageId);
        }
      }
      let shouldDoSomething = this.failModal !== null;
      if (shouldDoSomething) {
        (<bootstrap.Modal>this.failModal).show();
      }
      this.showFailPackages = true;
    },
    lookupPackage() {
      let shouldDoSomething = this.lookupModal !== null;
      if (shouldDoSomething) {
        (<bootstrap.Modal>this.lookupModal).show();
      }

      this.showLookupPackage = true;
    },
    noPackageFound(loadNumber, packageNumber) {
      let shouldDoSomething = this.errorModal !== null;
      if (shouldDoSomething) {
        (<bootstrap.Modal>this.errorModal).show();
      }
      this.errorPackage = `${loadNumber}-${packageNumber}`;
      this.showNoPackage = true;
    },
    async onFailSubmitCompleted(comments) {
      if (this.failModal) {
        (<bootstrap.Modal>this.failModal).hide();
        this.showFailPackages = false;
      }

      if (comments !== null) {
        var pack = {
          ids: this.affectedIds,
          passFail: false,
          notes: comments,
        };
        try {
          await ApiService.postData("Package/result", pack, {});
          for (var key in this.checkboxes) {
            if (this.checkboxes[key]) this.packageList[key].type5Result = false;
          }
        } catch (ex) {
          alert(
            "An error updating the database has occurred. Please contact support."
          );
        }
      }
    },
    onSubmitCompleted(loadNumber, packageNumber) {
      if (this.lookupModal) {
        (<bootstrap.Modal>this.lookupModal).hide();
        this.showLookupPackage = false;
      }
      if (this.errorModal) {
        (<bootstrap.Modal>this.errorModal).hide();
        this.showNoPackage = false;
      }

      if (loadNumber != null)
        this.getPackage("Custom Package", loadNumber, packageNumber);
    },
    async keyPress(event) {
      if (event.key == "Shift") {
        return;
      }

      if (
        event.key == "Enter" &&
        !this.showLookupPackage &&
        !this.showNoPackage &&
        !this.showFailPackages
      ) {
        console.log(this.command);
        const matches = this.re.exec(this.command);
        console.log(matches);
        if (matches !== null) {
          const loadNumber = matches[2];
          const packageNumber = matches[3];
          const hintName = matches[1];
          await this.getPackage(hintName, loadNumber, packageNumber);
        }

        this.command = "";
      } else {
        this.command = this.command + event.key;
      }
    },
    async getPackage(packageName, loadNumber, packageNumber) {
      const index = this.packageList.findIndex(
        (p) => p.id === `${loadNumber}-${packageNumber}`
      );
      if (index >= 0) {
        console.log(`item ${loadNumber}-${packageNumber} already exists`);
        return;
      }
      let pack: any = null;
      try {
        pack = (
          await ApiService.get(
            `package/bytag/${loadNumber}-${packageNumber}-${packageName}`
          )
        ).data;
      } catch (e: any) {
        if (e.response ) {
          if (e.response.status === 404)
            this.noPackageFound(loadNumber, packageNumber);
          return;
        }
      }
      console.log("pack", pack);
      this.packageList.push(pack);
      console.log("pushed");
      this.uncheckAll();
    },
  },

  setup() {
    setCurrentPageTitle("Package Verification");

    return {};
  },
});
