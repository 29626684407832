
import { computed, defineComponent, reactive, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { format } from "date-fns";
import LookupPackage from "@/components/AddProgram.vue";
import NoPackage from "@/components/NoPackage.vue";
import FailPackages from "@/components/FailPackages.vue";
import * as bootstrap from "bootstrap";
import { helpers, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

export default defineComponent({
  name: "kt-widget-9",
  components: { LookupPackage, NoPackage, FailPackages },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      type: "",
      packageList: new Array<any>(),
      failNames: new Array<any>(),
      affectedIds: new Array<any>(),
      command: "",
      re: new RegExp(`~(.+):\\sL#(\\d+)\\sP(\\d+)`),
      checked: false,
      checkboxes: new Array<any>(),
      showLookupPackage: false,
      showNoPackage: false,
      showFailPackages: false,
      lookupModal: null,
      errorModal: null,
      failModal: null,
      errorPackage: "",
      allSelected: false,
    };
  },
  mounted() {
    var lookupModalElement = document.getElementById("lookup_package");
    this.lookupModal = new bootstrap.Modal(lookupModalElement);
    if (lookupModalElement) {
      var selfModal = this;
      lookupModalElement.addEventListener("hidden.bs.modal", function (event) {
        selfModal.showLookupPackage = false;
      });
    }

    var errorModalElement = document.getElementById("no_package");
    this.errorModal = new bootstrap.Modal(errorModalElement);
    if (errorModalElement) {
      var selfModal = this;
      errorModalElement.addEventListener("hidden.bs.modal", function (event) {
        selfModal.showNoPackage = false;
      });
    }

    var failModalElement = document.getElementById("fail_packages");
    this.failModal = new bootstrap.Modal(failModalElement);
    if (failModalElement) {
      var selfModal = this;
      failModalElement.addEventListener("hidden.bs.modal", function (event) {
        selfModal.showFailPackages = false;
      });
    }
  },
  methods: {
    getType(isBD, isLoad) {
      if (isBD) return "Bowie Dick";
      else if (isLoad) return "Load"
      return "Other";
    },
    getDate(packageDate) {
      if (packageDate !== null)
        return format(new Date(packageDate), "yyyy-MMM-dd");
      else return "Not Found";
    },
    getStatus(packageStatus) {
      if (packageStatus != null)
        if (packageStatus == true) return "Verified";
        else return "Failed";
      return "Unverified";
    },
    async verifyPackages() {
      this.affectedIds.length = 0;
      for (var key in this.checkboxes)
        if (this.checkboxes[key])
          this.affectedIds.push(this.packageList[key].loadedPackageId);

      var pack = {
        ids: this.affectedIds,
        passFail: true,
        notes: "",
      };
      try {
        await ApiService.postData("Sterilizer/result", pack, {});
        for (var key in this.checkboxes) {
          if (this.checkboxes[key]) this.packageList[key].type5Result = true;
        }
      } catch (ex) {
        alert(
          "An error updating the database has occurred. Please contact support."
        );
      }
    },

    lookupPackage() {
      let shouldDoSomething = this.lookupModal !== null;
      if (shouldDoSomething) {
        (<bootstrap.Modal>this.lookupModal).show();
      }

      this.showLookupPackage = true;
    },
    noPackageFound() {
      let shouldDoSomething = this.errorModal !== null;
      if (shouldDoSomething) {
        (<bootstrap.Modal>this.errorModal).show();
      }
      this.showNoPackage = true;
    },

    async addCatalog() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let payload = {
          make: this.state.brand,
          model: this.state.model,
          manufacturer: this.state.brand,
          isPreVacuum: (this.state.isPreVacuum=="True")? true: false,
          imageUrl: this.state.imageUrl,
          programs: this.packageList,
        };
        console.log(payload);
        try {
          await ApiService.postData("Sterilizer/catalog", payload, {});
          this.$router.push({ path: `/settings/sterilizers` });
        } catch (ex) {
          alert("There has been an error");
          return;
        }
      }
    },
    onSubmitCompleted(program) {
      if (this.lookupModal) {
        (<bootstrap.Modal>this.lookupModal).hide();
        this.showLookupPackage = false;
      }
      if (this.errorModal) {
        (<bootstrap.Modal>this.errorModal).hide();
        this.showNoPackage = false;
      }
      if (program != null) this.packageList.push(program);
    },
  },

  setup() {
    setCurrentPageTitle("Sterilizer Catalog");

    const state = reactive({
      brand: "",
      model: "",
      isPreVacuum: "",
      imageUrl: "",
    });

    const rules = computed(() => {
      return {
        brand: {
          required: helpers.withMessage("Brand is required", required),
        },
        model: {
          required: helpers.withMessage("Model is required", required),
        },
        isPreVacuum: {
          required: helpers.withMessage(
            "Pre-Vacuum setting required",
            required
          ),
        },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      v$,
      state,
    };
  },
});
