
import { useStore } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { defineComponent, reactive, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
export default defineComponent({
  name: "EditBI",
  props: {
    selectedBI: {
      required: true,
      type: Object,
    },
    biLotNumbers: {
      required: true,
      type: Object,
    },
  },
  components: {},
  updated() {
    console.log("data changed");
    if (this.selectedBI && this.biLotId != this.selectedBI.biLotId) {
      console.log(this.timeConvert(this.selectedBI.incubationTimeInMinutes));

      this.biLotId = this.selectedBI.biLotId;
      this.state.biLotNumber = this.selectedBI.biLotNumber;
      this.state.brandName = this.selectedBI.brandName;
      this.state.expiryDate = this.selectedBI.expiryDate;
      this.incubationTime.hours = this.timeConvert(
        this.selectedBI.incubationTimeInMinutes
      ).hours;
      this.incubationTime.minutes = this.timeConvert(
        this.selectedBI.incubationTimeInMinutes
      ).minutes;
      this.state.quantity = this.selectedBI.quantity;
      this.state.supplier = this.selectedBI.supplier;
    } else {
      this.state.incubationTimeInMinutes = this.incubationMinutes;
    }
  },
  methods: {
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let payload: any = this.state;
        payload.clinicId = this.initialUser.currentClinic.clinicId;
        if (this.selectedBI) {
          payload.biLotId = this.selectedBI.biLotId;
        }
        console.log(payload, this.incubationTime, this.incubationMinutes);

        let response = await ApiService.postData("BI/Lot", payload, {});
        if (response.status === 200) {
          console.log(response.data);
          this.$emit("biAdded");
        } else {
          console.error(response);
        }
      }
    },
  },

  setup(props, context) {
    const store = useStore();
    const initialUser = store.getters.currentUser;
    let selectedBiLot = props.selectedBI as Models.BILot;
    let biLotId = 0;
        let timeConvert = (n) => {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return { hours: rhours, minutes: rminutes };
    };
    let incubationTime: any = reactive({
      hours: props.selectedBI ? timeConvert(props.selectedBI.incubationTimeInMinutes).hours : 0,
      minutes: props.selectedBI ? timeConvert(props.selectedBI.incubationTimeInMinutes).minutes : 0,
    });
    let incubationMinutes = computed(() => {
      return (
        parseInt(`${incubationTime.hours * 60}`) +
        parseInt(`${incubationTime.minutes}`)
      );
    });
    let state: Models.BILotPayload = reactive({
      biLotNumber: "",
      brandName: "",
      expiryDate: "",
      incubationTimeInMinutes: incubationMinutes.value,
      quantity: 0,
      supplier: "",
    });
    if (selectedBiLot) {
      biLotId = selectedBiLot.biLotId;
      const pastBiLotNumber = selectedBiLot.biLotNumber;
      let stateEdit: Models.BILotPayload = reactive({
        biLotNumber: selectedBiLot.biLotNumber,
        brandName: selectedBiLot.brandName,
        expiryDate: selectedBiLot.expiryDate,
        incubationTimeInMinutes: incubationMinutes.value,
        quantity: selectedBiLot.quantity,
        supplier: selectedBiLot.supplier,
      });
      state = stateEdit;
    }



    const mustBeUniqueNumber = (value) => checkBiLotNumber(value);

    const checkBiLotNumber = (value) => {
      let flag = true;
      props.biLotNumbers.forEach((num) => {
        if (num == value.toString()) {
          flag = false;
          return flag;
        }
      });
      console.log(flag);
      return flag;
    };

    const rules = computed(() => {
      return {
        biLotNumber: {
          required: helpers.withMessage("BI lot number is required.", required),
          mustBeUniqueNumber: helpers.withMessage(
            "BI lot number already exists.",
            mustBeUniqueNumber
          ),
        },
        brandName: {
          required: helpers.withMessage("Brand name is required.", required),
        },
        expiryDate: {
          required: helpers.withMessage("Expiry date is required.", required),
        },
        incubationTimeInMinutes: {
          required: helpers.withMessage(
            "Incubation time is required.",
            required
          ),
        },
        quantity: {
          required: helpers.withMessage("Quantity is required.", required),
        },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      timeConvert,
      incubationMinutes,
      incubationTime,
      state,
      v$,
      initialUser,
      biLotId,
      checkBiLotNumber,
    };
  },
});
