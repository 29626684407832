<template>
  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder fs-3 mb-1"
        >{{ sterilizer.name }} Programs</span
      >

      <!--<span class="text-muted mt-1 fw-bold fs-7">Over 500 members</span>-->
    </h3>

    <!-- <div
      class="card-toolbar"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-trigger="hover"
      title="Click to add a sterilizer"
    >
      <a @click="addProgram()" class="btn btn-sm btn-light-primary">
        <span class="svg-icon svg-icon-3">
          <inline-svg src="/media/icons/duotone/Files/File-Plus.svg" />
        </span>
        New Program
      </a>
    </div> -->
  </div>
  <div class="py-5 row height-50">
    <ag-grid-vue
      :defaultColDef="defaultColDef"
      :columnDefs="columnDefs"
      :rowData="rowData"
      @grid-ready="onGridReady"
      @gridSizeChanged="gridSizeChanged"
      style="width: 100%; height: 100%"
      class="ag-theme-balham"
    >
    </ag-grid-vue>
  </div>
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="addSterilizerProgram">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `${programToEdit ? "Edit" : "Add"} program` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <add-program
          :sterilizerId="sterId"
          :sterName="''"
          :programToEdit="programToEdit"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->
</template>
<script>
import { defineComponent } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { useStore } from "vuex";
import BtnCellRederer from "@/components/settings/BtnCellRederer.vue";
import CheckBoxCellRenderer from "@/components/settings/CheckBoxCellRenderer.vue";
import * as bootstrap from "bootstrap";
import AddProgram from "@/components/settings/Sterilizers/AddProgram.vue";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  components: {
    AgGridVue,
    CheckBoxCellRenderer,
    BtnCellRederer,
    AddProgram,
  },
  props: {
    sterilizer: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      programs: null,
      addProgramModal: null,
      programToEdit: null,

      columnDefs: null,
      rowData: null,
      defaultColDef: null,
      api: null,
      columnApi: null,
    };
  },
  async mounted() {
    await this.refreshTable();
    this.api.sizeColumnsToFit();
    var modalElement = document.getElementById("addSterilizerProgram");
    this.addProgramModal = new bootstrap.Modal(modalElement);
  },
  methods: {
    addProgram() {
      this.programToEdit = null;
      this.openProgModal();
    },
    editProgram(data) {
      this.programToEdit = data;
      this.openProgModal();
    },
    openProgModal() {
      this.addProgramModal.show();
    },
    closeProgModal() {
      this.addProgramModal.hide();
      this.programToEdit = null;
    },

    onGridReady(params) {
      console.log("grid ready");
      this.api = params.api;
      this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
      console.log("grid ready");
      this.api.sizeColumnsToFit();
    },
    /* ParamsFormat(params) {
      if (params) {
        if (params.value) {
          return "yes";
        } else {
          return "";
        }
      }
    },
    BDFormat(params) {
      if (params) {
        if (params.value) {
          return "yes";
        } else {
          return "";
        }
      }
    },
    timeFormat(params) {
      if (params) {
        if (params.value) {
          return value.toString();
        } else {
          return "-";
        }
      }
    }, */
    async refreshTable() {
      let res = await ApiService.get(
        `/Sterilizer/${this.sterilizer.sterilizerId}/program`
      );
      res=res.data;
      let self = this;
      this.defaultColDef = {
        resizable: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
      };
      this.columnDefs = [
        {
          field: "name",
          headerName: "Name",
          sortable: true,
          filter: true,
          // maxWidth: "120",
        },
        {
          field: "isBD",
          headerName: "Type",
          filter: true,
          sortable: true,
        },
/*         {
          field: "holdingTimeInSeconds",
          headerName: "Holding Time (s)",
          filter: true,
          sortable: true,
        },
        {
          field: "dryingTimeInSeconds",
          headerName: "Drying Time (s)",
          filter: true,
          sortable: true,
        },
        {
          field: "tempInC",
          headerName: "Temp (C)",
          filter: true,
          sortable: true,
        },
        {
          field: "pressure",
          headerName: "Pressure",
          filter: true,
          sortable: true,
        },
        {
          field: "maxWeightInkg",
          headerName: "Max Weight (KG)",
          filter: true,
          sortable: true,
        }, */
        // {
        //   field: "edit",
        //   headerName: "Actions",
        //   resizable: true,
        //   cellRendererFramework: BtnCellRederer,
        //   maxWidth: "100",
        //   cellRendererParams: {
        //     clicked: function(selectedData) {
        //       console.log("edit program", selectedData);
        //       self.editProgram(selectedData);
        //     },
        //     deleteClicked: function(selectedData) {
        //       console.log("clicked delete", selectedData);
        //     },
        //   },
        // },
      ];

      
      for (let i = 0; i < res.length; i++) {
        //add edit here in case needed
        if (res[i].isBD !== null) {
          if (res[i].isBD) res[i].isBD = "Bowie Dick";
          else if (res[i].needsParamVerification)
            res[i].isBD = "Load";
          else res[i].isBD = "Other";
        }
      }
      console.log(res);
      this.rowData = res;
      console.log(this.rowData);
    },
  },
  setup() {
    const route = useRoute();
    const sterId = route.params.id;
    const store = useStore();
    const initialUser = store.getters.currentUser;
    return {
      initialUser,
      sterId,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
// .ag-theme-material {
//   .ag-theme-material .ag-ltr .ag-cell {
//     color: black !important;
//     font-weight: 500 !important;
//   }
//   .ag-header-cell-text {
//     font-size: 15px;
//   }
//   .ag-row {
//     background: white !important;
//     border-bottom: 1px dashed grey;
//     border-bottom-color: #e4e6ef;
//     color: black !important;
//   }
//   .ag-cel {
//     font-weight: bold;
//     padding-top: 1rem;
//     padding-bottom: 1rem;
//   }
// }
.height-50 {
  height: 50vh;
}
</style>
