<template>
  <div class="">
    <div class="py-3">
      <div class="row">
        <div
          :class="`col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4  my-1 ${
            isDue[index] <= 1 ? '' : 'display-none'
          }`"
          v-for="(test, index) in foilTests"
          v-show="isTodayorPast(timeDueIn[index])"
          :key="index"
        >
          <router-link
            :to="`?${
              test.cleanerType == 1 ? 'foilTest' : 'washerTest'
            }=true&id=${test.cleanerId}`"
            class="d-flex flex-stack py-4 px-4 bg-white border-radius-5px"
          >
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35px me-4">
                <span class="bg-white symbol-label">
                  <!-- <span :class="`svg-icon-${timeDueIn[index] < 0 ? 'danger' : 'warning'} svg-icon `">
                  <inline-svg src="/media/icons/duotone/Code/Warning-2.svg" style="width: 2.5rem;"/>
                   </span> -->
                  <!-- <span :class="`badge-${timeDueIn[index] < 0 ? 'danger' : 'warning'} badge fw-bolder me-auto `">
                        !
                    </span> -->
                  <!--  -->
                  <span
                    :class="`svg-icon-${
                      isOverdue(timeDueIn[index]) && test.interval !== 1
                        ? 'danger'
                        : 'warning'
                    } svg-icon `"
                  >
                    <inline-svg
                      src="/media/icons/duotone/General/Notifications1.svg"
                      style="width: 2.5rem; height: 2.5rem"
                    />
                    <!-- <img :src="`/media/${timeDueIn[index] > 0 ? 'NotificationBellRed' : 'NotificationBell'}.png`" style="width: 100%" alt=""> -->
                  </span>
                </span>
              </div>
              <div class="mb-0 me-2">
                <a
                  v-if="test.cleanerType == 1"
                  href="#"
                  class="fs-6 text-gray-800 text-hover-primary fw-bolder"
                >
                  {{ test.name }}

                  {{
                    `${
                      isOverdue(timeDueIn[index])
                        ? "Ultrasonic Cleaner foil test overdue"
                        : "Ultrasonic Cleaner foil test due"
                    }`
                  }}</a
                >
                <a
                  v-else-if="test.cleanerType == 2"
                  href="#"
                  class="fs-6 text-gray-800 text-hover-primary fw-bolder"
                >
                  {{
                    `${
                      isOverdue(timeDueIn[index]) && test.interval !== 1
                        ? "Washer daily test overdue"
                        : "Washer daily test due"
                    }`
                  }}</a
                >
                <!-- <div class="text-gray-400 fs-7">
                  {{ createDateDifference(test.dueDate) }}
                </div> -->
              </div>
            </div>
            <!-- <span class="badge badge-light fs-8">{{ createDateDifference(test.dueDate) }}</span> -->
          </router-link>
        </div>
      </div>
      <!-- <div class="row">
        <div
          class="col mt-2 d-flex align-items-stretch"
          v-for="(test, index) in foilTests"
          :key="index"
        >
          <div
            :class="
              `card ${timeDueIn[index] < 0 ? 'border-danger' : 'border-warn'}`
            "
          >
            <div class="card-body">
              <h5 class="card-title">
                {{
                  `${
                    timeDueIn[index] < 0 ? "Foil test overdue" : "Foil test due"
                  }`
                }}
              </h5>
              <h6 class="card-subtitle mb-2 text-muted">
                in {{ createDateDifference(test.dueDate) }}
              </h6>
              <p class="card-text">
                Perform test with {{ test.name }}
              </p>
              <router-link
                :to="`?foilTest=true&id=${test.ultrasonicCleanerId}`"
              >
                <a class="card-link"> Perform test with {{ test.name }} </a>
              </router-link>
              <router-link :to="`?foilTest=true&id=${test.ultrasonicCleanerId}`">
                <a
                  class="
                  btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  me-1
                  btn-100px
                  ml-2
                "
                >
                  <span class="svg-icon svg-icon-2">
                    <inline-svg
                      src="/media/icons/duotone/Navigation/Arrow-right.svg"
                    />
                  </span>
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <table>
        <thead>
          <tr>
            <th class=""></th>
            <th></th>
            <th class="w-max-110px mx-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(test, index) in foilTests" :key="index">
            <td>
              <message-box
                :warn="true"
                :message="
                  `Foil test due in ${createDateDifference(test.dueDate)} for ${
                    test.name
                  }`
                "
                v-if="timeDueIn[index] > 0"
              />
              <message-box
                :danger="true"
                :message="
                  `Foil test overdue from ${Math.abs(test.dueDate)} day${
                    Math.abs(timeDueIn) > 1 ? 's' : ''
                  }`
                "
                v-if="timeDueIn[index] < 0"
              />
            </td>
            <td>
              <span class="mx-2"></span>
            </td>
            <td class="text-end w-max-110px">
              <router-link :to="`?foilTest=true&id=${test.ultrasonicCleanerId}`">
                <a
                  class="
                  btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  me-1
                  btn-100px
                  ml-2
                "
                >
                  <span class="svg-icon svg-icon-2">
                    <inline-svg
                      src="/media/icons/duotone/Navigation/Arrow-right.svg"
                    />
                  </span>
                </a>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table> -->
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import MessageBox from "@/components/MessageBox.vue";
import ApiService from "@/core/services/ApiService";
import { differenceInDays } from "date-fns/fp";
import { Models } from "@/Models/Models";
import { differenceInHours } from "date-fns";
import InlineSvg from "vue-inline-svg";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import isTomorrow from "date-fns/isTomorrow";
import isPast from "date-fns/isPast";

export default defineComponent({
  components: { MessageBox },
  data() {
    return {
      foilTests: [],
      timeDueIn: [],
      isDue: [],
    };
  },
  async mounted() {
    this.refreshData();
  },
  methods: {
    async refreshData() {
      let x = await ApiService.get("/Cleaner/duedate");
      this.foilTests = x.data;
      console.log(this.foilTests);
      this.foilTests.forEach((test) => {
        let due = new Date(test.dueDate);
        //let now = new Date(Date.now());
        //const diffTime = Math.abs(due - now);
        //const diffDays = diffTime / (1000 * 60 * 60 * 24);
        //let sign = new Date(test.dueDate).getTime() - Date.now() >= 0 ? 1 : -1;
        //this.timeDueIn.push(diffDays * sign);
        if(test.inService)
          this.timeDueIn.push(due);
      });
      if (!this.checkIfShowReminders() || this.foilTests.length == 0) {
        this.$emit("noFoilTests");
      }
    },
    isOverdue(date) {
      console.log(date);
      return isPast(date) && !isYesterday(date);
    },
    isTodayorPast(date) {
      return isPast(date) || isToday(date);
    },
    createDateDifference(old) {
      let final = "";
      let diff = differenceInDays(Date.now(), new Date(old));
      if (isToday(new Date(old))) {
        return "Today";
      } else if (isYesterday(new Date(old))) {
        return "Yesterday";
      } else {
        final = `${Math.abs(diff)} day${Math.abs(diff) > 1 ? "s" : ""}`;
        if (Math.abs(diff) == 1) {
          final = `${Math.abs(diff) + 1} days`;
        }
        return final;
      }
    },

    checkIfShowReminders() {
      let show = false;
      this.timeDueIn.forEach((due, i) => {
        console.log("due", due);
        this.isDue[i] = isToday(due) || isPast(due);
        if (!show) show = this.isDue[i];
      });
      return show;
    },
  },
  watch: {
    "$route.query.foilTest": function () {
      let showFoilTest = this.$route.query.foilTest;
      if (!showFoilTest) {
        this.foilTests = [];
        this.timeDueIn = [];
        this.isDue = [];
        let self = this;
        self.refreshData();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.border-danger {
  border: 1px solid #f5f8fa; // or fef4f8 light red
}
.border-warn {
  border: 1px solid #f5f8fa; // or fee9a9 light yellow
}
.display-none {
  display: none;
}
</style>
