
import { defineComponent, computed } from "vue";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  name: "TestCard",
  components: {},
  props: {
    notes: String,
  },

  methods: {
    passResult(result) {
      if (result !== null) return result;
      return 2;
    },
  },
});
