
import { defineComponent } from "vue";

export default defineComponent({
  name: "ForeignAvatar",
  props: {
    avatarSize: { type: Number, default: 3 },
    role: String,
    firstName: String,
    lastName: String,
    preferences: String,
  },
  computed: {
    userInfo(this): any {
        console.log(this.preferences);
    if(this.firstName &&this.lastName&&this.preferences)
    {
      let currentUserInfo = {
        initials:
          this.firstName.charAt(0).toUpperCase() +
          this.lastName.charAt(0).toUpperCase(),
        fullName: this.role +": "+ this.firstName + " " + this.lastName,
        avatarColor: "#0078D7",
      };

      if (this.preferences) {
        let prefs = JSON.parse(this.preferences);
        if (prefs.avatarColor) {
          currentUserInfo.avatarColor = prefs.avatarColor;
        }
      }
      return currentUserInfo;
    }
  },
  },

});
