import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bf2afec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-stack flex-grow-1 pt-3" }
const _hoisted_2 = { class: "fw-bold" }
const _hoisted_3 = { class: "fs-6 text-gray-600" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
      `notice d-flex align-items-center bg-light-${
        _ctx.warn ? 'warning' : (_ctx.danger ? 'danger' : 'primary')
      } rounded border-${
        _ctx.warn ? 'warning' : (_ctx.danger ? 'danger' : 'primary')
      } border  my-3 p-3`
    )
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(
        `svg-icon svg-icon-2tx svg-icon-${_ctx.warn ? 'warning' : (_ctx.danger ? 'danger' : 'primary')} me-4`
      )
    }, [
      _createVNode(_component_inline_svg, {
        src: 
          `/media/icons/duotone/Code/${
            _ctx.warn ? 'warning-2.svg' : 'Info-circle.svg'
          }`
        
      }, null, 8, ["src"])
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", { innerHTML: _ctx.message }, null, 8, _hoisted_4)
        ])
      ])
    ])
  ], 2))
}