
import useValidate from "@vuelidate/core";
import { required, helpers, sameAs } from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from 'vue';
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";

interface FormState {
  firstName: string;
  lastName: string;
  pin: string;
  confirm: string;
}

export default defineComponent({
  name: "EditUser",
  props: {
    selectedUser: {
      required: true,
      type: Object,
    },
    initialUser: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      errorMessageCustom: "",
    };
  },
  updated() {
    if (this.selectedUser && this.userId != this.selectedUser.userId) {
      this.userId = this.selectedUser.userId;
      this.state.firstName = this.selectedUser.firstName;
      this.state.lastName = this.selectedUser.lastName;
    }
  },
  methods: {
    showPin(id, e){
      let input = document.getElementById(id) as HTMLInputElement
      input.type = input.type == 'text' ? 'password' : 'text';
      console.log(e.target);
      e.target.classList.value = input.type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'
    },
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          // const initialUser = this.initialUser as any;
          const user = await ApiService.get(`User/current`);
          const currentUser = user.data as Models.CurrentUser;
          // const clinicAccess: Models.ClinicAccess[] = [];
          // currentUser.clinics.forEach((clinic) => {
          //   const clinicId = clinic.clinicId;
          //   let access = false;
          //   if (
          //     clinicId === initialUser.currentClinic.clinicId &&
          //     this.state.isAdmin
          //   ) {
          //     access = true;
          //   }
          //   clinicAccess.push({
          //     clinicId: clinicId,
          //     isClinicAdmin: access,
          //   });
          // });
          // const customerId = initialUser.currentClinic.customerId;
          let payload: any = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            pin: this.state.pin,
            preferences: this.selectedUser ? JSON.stringify(this.selectedUser.preferences) : "",
          };
          if (this.selectedUser) {
            payload.userId = this.selectedUser.userId;
            delete payload.pin;
          }

          if (!payload) {

            return;
          } else {
            console.log(payload);
            const response = await ApiService.postData(
              "User/operator",
              payload,
              {}
            );
            console.log("RESPONSE",response);
            
            if (response.status === 200) {
              console.log(response.data);
              if (this.selectedUser) {
                this.$emit("closeEditUserForm");
              } else {
                this.$emit("closeAddUserForm");
              }
            } else {
              console.error(response);
              this.showErrorMessage("Error occured please try again later.");
            }
          }
        } catch (ex: any) {
          console.warn(ex);
          if (ex.response.status === 461) {
            this.toggleSamePin();
          } else {
            this.showErrorMessage("Error occured please try again later.");
          }
        }
      }
    },
    toggleSamePin(){
      let self = this;
      this.samePin = true;
      setTimeout(() => {
        self.samePin = false;
      }, 5000);
    },
    discard() {
      console.log("discard");
      this.$emit("verifyParamsSubmitCompleted");
    },
  },

  setup(props, context) {
    // create user form
    let samePin = ref(false);
    console.log("props: ", props);
    let userId = 0;
    let state: FormState = reactive({
      firstName: "",
      lastName: "",
      pin: "",
      confirm: "",
    });

    if (props.selectedUser) {
      const user_to_edit = props.selectedUser as Models.UserToEdit;
      userId = user_to_edit.userId;
      let stateEdit: any = reactive({
        firstName: user_to_edit.firstName,
        lastName: user_to_edit.lastName,
      });
      state = stateEdit;
    }

    const rules = computed(() => {
      return {
        firstName: {
          required: helpers.withMessage("First name is required.", required),
        },
        lastName: {
          required: helpers.withMessage("Last name is required.", required),
        },
        pin: {
          required: helpers.withMessage("PIN is required.", required),
        },
        confirm: {
          required: helpers.withMessage("Please confirm PIN", required),
          sameAs: helpers.withMessage(
            "Value doesn't match PIN",
            sameAs(state.pin)
          ),
        },
      };
    });
    let editOperatorRules = computed(() => {
      return {
        firstName: {
          required: helpers.withMessage("First name is required.", required),
        },
        lastName: {
          required: helpers.withMessage("Last name is required.", required),
        },
      };
    });

    let v$ = useValidate(props.selectedUser ? editOperatorRules : rules, state);

    const closeForm = () => {
      context.emit("closeEditUserForm");
    };

    return {
      //  create new user
      state,
      closeForm,
      v$,
      userId,
      samePin,
      //  end create new user
    };
  },
});
