
import useValidate from "@vuelidate/core";
import { required, helpers, sameAs } from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";

interface FormState {
  source: string;
  category: string;
  pubDate: string;
  title: string;
  controlTipText: string;
  notes: string;
}

export default defineComponent({
  name: "AddGuide",
  props: {
    initialUser: {
      required: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  updated() {},
  methods: {
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
        } catch (ex) {
          console.warn(ex);
        }
      }
    },
  },

  setup(props, context) {
    let state: FormState = reactive({
      source: "",
      category: "",
      pubDate: "",
      title: "",
      controlTipText: "",
      notes: "",
    });

    const rules = computed(() => {
      return {
        source: {
          required: helpers.withMessage("Source is required.", required),
        },
        category: {
          required: helpers.withMessage("Category is required.", required),
        },
        pubDate: {
          required: helpers.withMessage(
            "Publication date is required.",
            required
          ),
        },
        title: {
          required: helpers.withMessage("Title is required.", required),
        },
        controlTipText: {
          required: helpers.withMessage(
            "Control Tip Text is required.",
            required
          ),
        },
        notes: {
          required: helpers.withMessage("Notes are required.", required),
        },
      };
    });

    let v$ = useValidate(rules, state);

    return {
      state,
      v$,
    };
  },
});
