
import { defineComponent, ref } from "vue";
import VerifyParams from "@/components/VerifyParams.vue";
import CreateNewLoad from "@/components/CreateNewLoad.vue";
import CreateBDLoad from "@/components/CreateBDLoad.vue";
import VerifyBD from "@/components/VerifyBD.vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
import { NextStep, ParamsState, BIState, BDState, Type5State } from "@/core/Enums";
import StatusRenderer from '@/components/StatusRenderer.vue';

export default defineComponent({
  name: "kt-widget-9",
  components: { VerifyParams, CreateNewLoad, CreateBDLoad, VerifyBD, StatusRenderer },
  props: {
    widgetClasses: String,
  },
  emits: ["submitCompleted"],
  data() {
    return {
      list: [],
      selectedSterilizer: null,
      latestLoadNumber: 0,
      verifyParams: false,
      showVerifyParams: false,
      showCreateNewLoad: false,
      showCreateBDLoad: false,
      showVerifyBD: false,
      modal: null,
      NextStep,
      BDState,
      modalSize: "xl",
    };
  },

  async mounted() {
    this.loadData();
    const modalElement = document.getElementById("sterilizer_modal");
    this.modal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      modalElement.addEventListener("hidden.bs.modal", function(event) {
        self.showVerifyParams = false;
        self.showCreateNewLoad = false;
        self.showCreateBDLoad = false;
        self.showVerifyBD = false;
      });
    }
  },
  methods: {
    async loadData() {      
      let list = (await ApiService.get("SterilizerSummary")).data;
      list = list.map((s) => ({
        sterilizerId: s.sterilizerId,
        name: s.name,
        isPreVacuum: s.isPreVacuum,
        loadNumber: s.loadNumber,
        cycleNumber: s.cycleNumber,
        latestLoadId: s.latestLoadId,
        nextSteps: s.nextStep as NextStep,
        paramsState: this.GetParamsState(s.paramsState as ParamsState),
        type5State: this.GetType5State(s.type5State  as Type5State),
        biState: this.GetBiState(s.biState),
        bdState: this.GetBDState(s.bdState as BDState),
        lastProgram: s.lastProgram
      }));
      this.list = list;
    },
    async clearTodaysLoads(item) {
      await ApiService.delete(`Sterilizer/${item.sterilizerId}/TodaysLoads`);
      this.loadData();
    },
    takeNextStep(item) {
      console.log("item to work on ", item);

      const shouldDoSomething = this.modal !== null;
      if (shouldDoSomething) {
        (this.modal as bootstrap.Modal).show();
      }
      this.selectedSterilizer = item;
      // eslint-disable-next-line prefer-spread
      this.latestLoadNumber = Math.max.apply(
        Math,
        this.list.map(function(s) {
          return (s as any).loadNumber;
        })
      );

      if (item.nextSteps === NextStep.VerifyParams) {
        console.log("VerifyParams");

        this.showVerifyParams = true;
        this.modalSize = "";
      } else if (
        item.nextSteps === NextStep.Load ||
        item.nextSteps === NextStep.LoadIncludeBI
      ) {
        console.log("CreateNewLoad");
        this.showCreateNewLoad = true;
        this.modalSize = "modal-fullscreen";
      } else if (item.nextSteps === NextStep.BDLoad) {
        console.log("showCreateBDLoad");
        this.showCreateBDLoad = true;
        this.modalSize = "";
      } else if (item.nextSteps == NextStep.VerifyBD) {
        this.showVerifyBD = true;
        this.modalSize = "";
      }
    },
    GetBiState(biState: BIState): object {
      switch (biState) {
        case BIState.DoesNotNeedBI: {
          return {
            color: "success",
            message: "A Biological Indicator test has been run",
          };
        }
        case BIState.LastBIFailed: {
          return {
            color: "danger",
            message: "The last Biological Indicator test failed",
          };
        }
        case BIState.BIPending: {
          return {
            color: "success",
            message: "A Biological Indicator test has been run and results are still pending",
          };
        }
        case BIState.NeedsBI: {
          return {
            color: null,
            message:
              "This sterilizer requires a Biological Indicator in it's next load",
          };
        }
        default: {
          return {
            color: "danger",
            message:
              "This sterilizer requires a Biological Indicator in it's next load.",
          };
        }
      }
    },
    GetNextStepsText(nextSteps: NextStep) {
      switch (nextSteps) {
        case NextStep.Load:
          return "Load Sterilizer";
        case NextStep.VerifyParams:
          return "Verify Cycle Parameters";
        case NextStep.LoadIncludeBI:
          return "Load Sterilizer, Include a Biological Indicator";
        case NextStep.BDLoad:
          return "Start a Bowie Dick Test";
        case NextStep.VerifyBD:
          return "Verify The Bowie Dick Test";
        case NextStep.RetestBD:
          return "Re-Test With Bowie Dick";
      }
    },
    GetBDState(bdState: BDState) {
      switch (bdState) {
        case BDState.DoesNotNeedBD: {
          return {
            color: "success",
            message: "Last Bowie Dick test was successful. You are good to go",
          };
        }
        case BDState.LastBDFailed: {
          return {
            color: "danger",
            message: "Last Bowie Dick test failed",
          };
        }
        case BDState.BDPending: {
          return {
            color: "warning",
            message: "The Bowie Dick test results need to be verified",
          };
        }
        case BDState.NeedsBD: {
          return {
            color: null,
            message:
              "This sterilizer required a Bowie Dick test before your next load",
          };
        }
        default: {
          return {
            color: "danger",
            message:
              "Cannot determine state of Bowie Dick for this sterilizer. Contact support",
          };
        }
      }
    },
    GetParamsState(paramsState: ParamsState): Record<string, any> {
      switch (paramsState) {
        case ParamsState.DoesNotNeedVerification: {
          return {
            color: "success",
            message: "Parameter Verification on this sterilizer is good to go",
          };
        }
        case ParamsState.LastParamsFailed: {
          return {
            color: "danger",
            message: "Last paremeter verification on this sterilizer failed",
          };
        }
        case ParamsState.NeedsVerification: {
          return {
            color: null,
            message: "The last load on this sterilizer requires verification",
          };
        }
        default: {
          return {
            color: "danger",
            message:
              "Contact Verify support, something is wrong with this indicator",
          };
        }
      }
    },
    GetType5State(type5State: Type5State): Record<string, any> {
      switch (type5State) {
        case Type5State.DoesNotNeedVerification: {
          return {
            color: "success",
            message: "",
          };
        }
        case Type5State.LastType5Failed: {
          return {
            color: "danger",
            message: "Last type 5 check on this sterilizer failed",
          };
        }
        case Type5State.NeedsVerification: {
          return {
            color: null,
            message: "The last load on this sterilizer requires verification",
          };
        }
        default: {
          return {
            color: "danger",
            message:
              "Contact Verify support, something is wrong with this indicator",
          };
        }
      }
    },
    onSubmitCompleted() {
      this.loadData();

      console.log(this.modal);
      if (this.modal) {
        (this.modal as bootstrap.Modal).hide();
      }
      this.showVerifyParams = false;
      this.showCreateNewLoad = false;
      this.showCreateBDLoad = false;
      this.showVerifyBD = false;
      this.$emit("submitCompleted");
    },
  },
    computed: {
    showBD() {
      let flag = false;
      this.list.forEach(element => {
        const elt = element as any;
        if (elt.isPreVacuum) {
          flag = true;
          return flag;
        }
      });
      return flag;
    }
  },

  setup() {
    const checked = ref(false);

    return {
      checked,
    };
  },
});
