
import {
  defineComponent,
  ref,
  reactive,
  computed,
} from "vue";
import ApiService from "@/core/services/ApiService";
import Avatar from "@/components/Avatar.vue";
import MessageBox from "@/components/MessageBox.vue";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

interface ParamsData {
  passFail: string;
  cycleNumber: number;
}

export default defineComponent({
  name: "VerifyBD",
  props: {
    sterilizer: Object,
    loadNumber: Number,
  },
  components: {
    Avatar, MessageBox
  },
  emits: ["submitCompleted"],
  methods: {
    async submit () {
      if (!this.submitButtonRef) {
        return;
      }

      //Disable button
      this.submitButtonRef.disabled = true;
      // Activate indicator
      this.submitButtonRef!.setAttribute("data-kt-indicator", "on");
      this.v$.$validate();
      if(!this.v$.$error){
        try {
          await ApiService.put(
            `Load/${this.sterilizer?.latestLoadId}/BD`,
            {
              cycleNumber: this.state.cycleNumber,
              LoadId: this.sterilizer?.latestLoadId,
              Passed: this.state.passFail === "passed" ? true : false,
              UserId: 1,
            },
            {}
          );

          if (this.submitButtonRef) {
            this.submitButtonRef.disabled = false;
            this.$emit("submitCompleted");
          }
        } catch (ex) {
          alert("An error updating the database has occurred. Please contact support");
          if (this.submitButtonRef ) {
            this.submitButtonRef.disabled = false;
            this.submitButtonRef.removeAttribute("data-kt-indicator");
          }
        }
      }else{
        if (this.submitButtonRef) {
            this.submitButtonRef.disabled = false;
            this.submitButtonRef.removeAttribute("data-kt-indicator");
          }
      }
    },
    discard() {
      this.$emit("submitCompleted");
    },
  },

  setup(props, context) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    // const paramsData = ref<ParamsData>({
    //   passFail: "",
    // });

    let state: ParamsData = reactive({
      passFail: "",
      cycleNumber: props.sterilizer?.cycleNumber
    })
    const rules = computed(()=>{
      return {
        passFail: { required: helpers.withMessage("Pass/Fail is required", required) },
        cycleNumber: {
          required: helpers.withMessage("Cycle number required", required),
        },

      }
    })
    let v$ = useValidate(rules, state);

    // const validationSchema = Yup.object({
    //   passFail: Yup.string()
    //     .label("Parameters Result")
    //     .required(),
    // });

    // const { handleSubmit } = useForm({ validationSchema: validationSchema });
    // const { value: passFail, errorMessage: paramsResultError } = useField(
    //   "passFail"
    // );



    return {
      state,
      v$,
      // paramsData,
      // validationSchema,
      // submit,
      submitButtonRef,
      // passFail,
      // paramsResultError,
    };
  },
});
