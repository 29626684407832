
import { defineComponent } from "vue";
import Avatar from "@/components/Avatar.vue";

export default defineComponent({
  name: "NoPackage",
  props: {
    errorPackage: String,
  },
  components: {
    Avatar,
  },

  emits: ["submitCompleted"],
  methods: {
    discard() {
      this.$emit("submitCompleted", null, null);
    },

  },
});
