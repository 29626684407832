
import useValidate from "@vuelidate/core";
import { required, helpers, sameAs, requiredIf } from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import { format } from "date-fns/esm";
import { useStore } from "vuex";

interface FormState {
  cleanerId: string;
  notes: string;
  passed: string;
}

export default defineComponent({
  name: "AddFoilTest",
  components: {},
  data() {
    return {
      selectedUltrasonicId: null,
    };
  },
  async mounted() {
    let query = Object.assign({}, this.$route.query);
    if (query.foilTest && !query.id) {
      let ultraSonicList = await ApiService.get("/Cleaner/1");
      this.ultrasonicList = ultraSonicList.data;
      this.selectedUltrasonicId = ultraSonicList.data[0].cleanerId;
      this.showUltrasonicList = true;
    } else {
      this.showUltrasonicList = false;
    }
  },
  methods: {
    removeQuery() {
      this.$emit("removeQuery");
    },
    async submit() {
      this.updateCurrentUser();
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          let payload: any = this.state;
          payload.passed = payload.passed === "failed" ? false : true;
          if (payload.passed) {
            payload.notes = "";
          }
          let query = Object.assign({}, this.$route.query);
          if (query.foilTest && query.id) {
            payload.cleanerId = query.id;
          }

          if (this.showUltrasonicList) {
            payload.cleanerId = this.selectedUltrasonicId;
          }
          console.log(payload);
          const res = await ApiService.postData(
            "Cleaner/mifutest",
            payload,
            {}
          );
          if (res.status === 200) {
            this.removeQuery();
            this.$emit("completed");
          }
        } catch (ex) {
          console.warn(ex);
        }
      }
    },
  },

  setup(props, context) {
    const store = useStore();
    let currentUser = reactive(store.getters.currentUser as Models.CurrentUser);
    let state: FormState = reactive({
      cleanerId: "",
      notes: "",
      passed: "",
    });

    const updateCurrentUser = function() {
      currentUser = reactive(store.getters.currentUser as Models.CurrentUser);
    };

    let ultrasonicList = reactive({});
    let showUltrasonicList = ref(false);
    const rules = computed(() => {
      return {
        passed: {
          required: helpers.withMessage(
            "Results are required.",
            requiredIf(() => {
              return state.passed === "";
            })
          ),
        },
        cleanerId: {
          required: helpers.withMessage(
            "Ultrasonic device required",
            requiredIf(() => {
              return (
                showUltrasonicList.value && state.cleanerId !== ""
              );
            })
          ),
        },
      };
    });

    let v$ = useValidate(rules, state);

    return {
      showUltrasonicList,
      ultrasonicList,
      state,
      v$,
      currentUser,
      updateCurrentUser,
    };
  },
});
