<template>
  <ag-grid-vue
    style="width: 100%"
    class="ag-theme-balham"
    :columnDefs="columnDefs"
    :rowData="rowData"
    @first-data-rendered="onFirstDataRendered"
    @gridSizeChanged="onFirstDataRendered"
  >
    >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  name: "ImpactedBi",
  props: {
    id: Number,
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
    };
  },
  methods: {
    onFirstDataRendered(params) {
      params.api.setDomLayout("autoHeight");
      params.api.sizeColumnsToFit();
    },
  },

  async mounted() {
    const packagesData = await ApiService.get(`/Load/impacted/${this.id}`);
    const finalPckgData = packagesData.data;
    this.columnDefs = [
      {
        field: "loadNumber",
        headerName: "Load Number",
        filter: false,
        sortable: true,
      },
      {
        field: "programName",
        headerName: "Program",
        sortable: true,
        filter: false,
      },
    ];
    this.rowData = finalPckgData;
  },
});
</script>

<style lang="scss">
@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>