
import {
  defineComponent,
  onUpdated,
  onMounted,
  ref,
  onUnmounted,
  onBeforeUnmount,
  reactive,
  computed,
} from "vue";
import useValidate from "@vuelidate/core";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Avatar from "@/components/Avatar.vue";
import { helpers, required } from "@vuelidate/validators";
import MessageBox from "@/components/MessageBox.vue";

export default defineComponent({
  name: "LookupPackage",
  components: {
    Avatar,
    MessageBox,
  },
  data() {
    return {
      example: "123-456",
    };
  },
  emits: ["submitCompleted"],
  methods: {
    discard() {
      this.$emit("submitCompleted", null);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let isBD = false;
        let isLoad=false;
        if (this.state.mode == "Bowie Dick") isBD = true;
        else if (this.state.mode == "Load") isLoad=true;
        let program = {
          needsParamVerification: isLoad,
          isBD: isBD,
          name: this.state.name,
          holdingTimeInSeconds: this.state.holdingTime,
          dryingTimeInSeconds: this.state.dryingTime,
          tempInC: this.state.tempInC,
          pressure: this.state.pressure,
          maxWeightInkg: this.state.maxWeightInkg,
        };
        console.log(program);
        this.$emit("submitCompleted", program);
      }
    },
  },

  setup(props, context) {
    const state = reactive({
      mode: "",
      name: "",
      holdingTime: 0,
      dryingTime: 0,
      tempInC: 0,
      pressure: 0,
      maxWeightInkg: 0,
    });

    const rules = computed(() => {
      return {
        mode: {
          required: helpers.withMessage("Mode is required", required),
        },
        name: {
          required: helpers.withMessage("Name is required", required),
        },
        holdingTime: {
          required: helpers.withMessage("Holding time required", required),
        },
        dryingTime: {
          required: helpers.withMessage("Drying time required", required),
        },
        tempInC: {
          required: helpers.withMessage("Temperature required", required),
        },
        pressure: {
          required: helpers.withMessage("Pressure required", required),
        },
        maxWeightInkg: {
          required: helpers.withMessage("Max Weight required", required),
        },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      v$,
      state,
    };
  },
});
