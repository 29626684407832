
import { defineComponent, computed } from "vue";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  name: "PackagesCard",
  components: {},
  data() {
    return {
      allSelected: false,

      /* checkboxes: new Array<any>(), */

    };
  },
  props: {
    loadID: Number,
    loadPacks: Array,
  },

  methods: {
    passResult(result) {
      if (result !== null) return result;
      return 2;
    },

    getStatus(packageStatus) {
      if (packageStatus != null)
        if (packageStatus == true) return "Verified";
        else return "Failed";
      return "Unverified";
    },


/*     checkboxesLength() {

      return this.checkboxes.filter(Boolean).length;
    },
    isAnySelected() {
      if (this.checkboxesLength() >= 1) return this.checkboxesLength();
      return;
    },
    selectOne(index, loadPacks) {
      if (this.checkboxes[index] && this.allSelected) this.allSelected = false;
      else if (
        !this.checkboxes[index] &&
        this.checkboxesLength() == loadPacks.length - 1
      )
        this.allSelected = true;
    },
    uncheckAll() {
      this.allSelected = false;
    },
    selectAll() {
      if (!this.allSelected) {
        for (var key in this.loadPacks) this.checkboxes[key] = true;
      } else {
        for (var key in this.loadPacks) this.checkboxes[key] = false;
      }

    }, */

  },
});
