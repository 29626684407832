
export enum BIState
{
    NeedsBI = 1,
    DoesNotNeedBI = 0,
    BIPending = 2,
    LastBIFailed = 3
}

export enum NextStep
{
    Load = 0,
    VerifyParams = 1,
    BDLoad = 2,
    VerifyBD = 4,
    LoadIncludeBI = 5,
    RetestBD = 6
}

export enum ParamsState
{
    DoesNotNeedVerification = 0,
    NeedsVerification = 1,            
    LastParamsFailed = 2            
}

export enum Type5State
{
    DoesNotNeedVerification = 0,
    NeedsVerification = 1,            
    LastType5Failed = 2            
}

export enum BDState
{
    NeedsBD = 1,
    DoesNotNeedBD = 0,
    BDPending = 2,
    LastBDFailed = 3
}