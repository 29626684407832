
import { defineComponent, ref } from "vue";
import Avatar from "@/components/Avatar.vue";
import ImpactedBi from "@/components/ImpactedBi.vue";
import MessageBox from '@/components/MessageBox.vue';
import {
  format,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  subWeeks,
  subMonths,
  subYears,
} from "date-fns";

export default defineComponent({
  name: "DownloadRecords",
  props: {
    printMethod: String,
  },
  components: {
    Avatar,
    ImpactedBi,
    MessageBox
  },

  emits: ["submitCompleted"],
  data() {
    return {
      programs: [
        "All",
        "Today",
        "Yesterday",
        "This Week",
        "Last Week",
        "This Month",
        "Last Month",
        "This Year",
        "Last Year",
      ] as string[],
    };
  },
  methods: {
    discard() {
      this.$emit("submitCompleted", null);
    },
    selectType(e){
      this.selectedValue = e.target.value
    },
    selectionString() {
      let today = new Date(Date.now());
      let currentTime = format(new Date(Date.now()), "h:mm a");
      let lowerCaseMethod = this.printMethod?.toLowerCase();
      if (this.programSelection === "All")
        return ` Includes all ${lowerCaseMethod} records until today at ${currentTime}.`;
      if (this.programSelection === "Today")
        return `Includes ${lowerCaseMethod} records from today at ${currentTime}.`;
      if (this.programSelection === "Yesterday")
        return `Includes ${lowerCaseMethod} records from ${format(
          endOfYesterday(),
          "cccc"
        )}.`;
      if (this.programSelection === "This Week")
        return `Includes ${lowerCaseMethod} records from ${format(
          startOfWeek(today),
          "MMMM do"
        )} to today at ${currentTime}.`;
      if (this.programSelection === "Last Week")
        return `Includes ${lowerCaseMethod} records from ${format(
          startOfWeek(subWeeks(today, 1)),
          "MMMM do"
        )} to
        ${format(endOfWeek(subWeeks(today, 1)), "MMMM do")}.`;
      if (this.programSelection === "This Month")
        return `Includes ${lowerCaseMethod} records from ${format(
          today,
          "MMMM"
        )} 1st to today at ${currentTime}.`;
      if (this.programSelection === "Last Month")
        return `Includes all ${lowerCaseMethod} records in ${format(
          subMonths(today, 1),
          "MMMM yyyy"
        )}.`;
      if (this.programSelection === "This Year")
        return `Includes ${lowerCaseMethod} records from January 1, ${format(
          today,
          "yyyy"
        )} to today at ${currentTime}.`;
      if (this.programSelection === "Last Year")
        return `Includes all ${lowerCaseMethod} records in ${format(
          subYears(today, 1),
          "yyyy"
        )}.`;
    },
  },

  setup(props, context) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    let programSelection = ref<String>("All");
    let selectedValue = ref<String>("pdf");

    const submit = async (values) => {
      if (!submitButtonRef.value) {
        return;
      }

      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      try {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          context.emit("submitCompleted", programSelection , selectedValue );
        }
      } catch (ex) {
        alert(ex);
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
      }
    };

    return {
      props,
      submit,
      submitButtonRef,
      programSelection,
      selectedValue
    };
  },
});
