
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    warn: { type: Boolean },
    info: { type: Boolean },
    danger: {type: Boolean},
    message: { type: String, required: true },
  },
});
