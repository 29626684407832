
import {
  defineComponent,
  reactive,
  computed,
} from "vue";
import useValidate from "@vuelidate/core";
import Avatar from "@/components/Avatar.vue";
import { helpers, required } from "@vuelidate/validators";
import ImpactedPackages from "@/components/ImpactedPackages.vue";
export default defineComponent({
  name: "FailPackages",
    props: {
        failNames: Array,
  },
  components: {
    Avatar, ImpactedPackages
  },
  data() {
    return {
      example: "ex. Internal chemical indicators did not change color",
    };
  },
  emits: ["submitCompleted"],
  methods: {
    discard() {
      this.$emit("submitCompleted", null);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submitCompleted", this.state.comments);
      }
    },
  },

  setup(props, context) {
      
    const state = reactive({
      comments: "",
    });


    const rules = computed(() => {
      return {
        comments: {
          required: helpers.withMessage(
            "Submission notes are required",
            required
          ),
        },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      v$,
      state,
    };
  },
});
