
import useValidate from "@vuelidate/core";
import {
  required,
  helpers,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";

interface FormState {
  pin: string;
  confirm: string;
}

export default defineComponent({
  name: "Pin",
  props: {
    selectedUser: {
      required: true,
      type: Object,
    },
  },
  components: {},
  data() {
    return { errorMessageCustom: "" };
  },
  methods: {
        showPin(id, e){
      let input = document.getElementById(id) as HTMLInputElement
      input.type = input.type == 'text' ? 'password' : 'text';
      console.log(e.target);
      e.target.classList.value = input.type == 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'
    },
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          let payload = {
            userId: this.selectedUser.userId,
            pin: this.state.pin,
          };
          let res = await ApiService.postData("User/pin", payload, {});
          if (res.status === 200) {
            // ok
            this.$emit("closePinModal");
          }
        } catch (exception: any) {
          console.log(exception.response.status);
          
          if (exception.response.status === 461) {
           this.toggleSamePin()
          } else {
            this.showErrorMessage("Error occured please try again later.");
          }
          console.log(exception);
        }
      }
    },
    toggleSamePin(){
      let self = this;
      this.samePin = true;
      setTimeout(() => {
        self.samePin = false;
      }, 5000);
    },
    discard() {
      console.log("discard");
      this.$emit("verifyParamsSubmitCompleted");
    },
  },

  setup(props, context) {
    // create user form
    console.log("props: ", props);
    let userId = 0;
    let state: FormState = reactive({
      pin: "",
      confirm: "",
    });

    const rules = computed(() => {
      return {
        pin: {
          required: helpers.withMessage("PIN is required.", required),
          minLength: minLength(4),
          maxLength: maxLength(4),
        },
        confirm: {
          required: helpers.withMessage("Please confirm PIN", required),
          sameAs: helpers.withMessage(
            "Value doesn't match PIN",
            sameAs(state.pin)
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditUserForm");
    };
    let samePin = ref(false);

    return {
      samePin,
      //  create new user
      state,
      closeForm,
      v$,
      userId,
      //  end create new user
    };
  },
});
