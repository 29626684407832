
import {
  defineComponent,
  onUpdated,
  onMounted,
  ref,
  onUnmounted,
  onBeforeUnmount,
  reactive,
  computed,
} from "vue";
import useValidate from "@vuelidate/core";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Avatar from "@/components/Avatar.vue";
import { helpers, required } from "@vuelidate/validators";
import MessageBox from "@/components/MessageBox.vue";

export default defineComponent({
  name: "LookupPackage",
  components: {
    Avatar,
    MessageBox,
  },
  props : {
    defaultValues: Object,
  },
  data() {
    return {
      example: "123-456",
    };
  },
  emits: ["submitCompleted"],
  methods: {
    discard() {
      this.$emit("submitCompleted", null);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let payload = {
          make: this.state.brand,
          model: this.state.model,
          manufacturer: this.state.manufacturer,
          isPreVacuum: (this.state.isPreVacuum=="True")? true: false,
        };
        console.log(payload);
        this.$emit("submitCompleted", payload);
      }
    },
  },

  setup(props, context) {
    const state = reactive({
      brand: props.defaultValues?.make,
      model: props.defaultValues?.model,
      manufacturer: props.defaultValues?.manufacturer,
      isPreVacuum: props.defaultValues?.isPreVacuum,
    });

    const rules = computed(() => {
      return {
        brand: {
          required: helpers.withMessage("Brand is required", required),
        },
        model: {
          required: helpers.withMessage("Model is required", required),
        },
        manufacturer: {
          required: helpers.withMessage("Manufacturer name required", required),
        },
        isPreVacuum: {
          required: helpers.withMessage(
            "Pre-Vacuum setting required",
            required
          ),
        },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      v$,
      state,
    };
  },
});
