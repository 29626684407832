
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import VerifyVial from "@/components/VerifyVial.vue";
import moment from "moment-timezone";
import * as bootstrap from "bootstrap";
import { format } from 'date-fns';
import differenceInHours from "date-fns/differenceInHours/index";
export default defineComponent({
  name: "kt-widget-12",
  components: { VerifyVial },
  emits: ["submitCompleted"],
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      list: [],
      showVerifyVial: false,
      selectedSterilizer: "",
      selectedLoadNumber: 0,
      selectedbiId: 0,
      modal: null,
    };
  },
  async mounted() {
    this.loadData();
    var modalElement = document.getElementById("incubator_modal");
    this.modal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function(event) {
        self.showVerifyVial = false;
      });
    }
  },
  methods: {
    createDateDifference(old) {
      let diff = differenceInHours(Date.now(), new Date(old));
      // get total seconds between the times
      let delta = Math.abs(Date.now() - new Date(old).getTime()) / 1000;

      // calculate (and subtract) whole days
      let days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      let seconds = delta % 60; // in theory the modulus is not required
      let final  = "";
      if(days >= 1 ){
        final += `${days}d `;
      }
      if(hours >=1){
        final += `${hours}h `;
      }
      if(minutes >=1){
                final += `${minutes}m`;
      }
      return final;
    },
    convertUTCDateToLocalDate(date, datetimeformat: string) {
    // date = new Date(date);
    // var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
    // var offset = date.getTimezoneOffset() / 60;
    // var hours = date.getHours();
    // newDate.setHours(hours - offset);
    return format(new Date(date), datetimeformat);   
},
    async loadData() {
    let list = (await ApiService.get("BI/active")).data;    
    console.log(list)
    list=list.map(s =>
    ({
          timeIn: {
            date:  this.convertUTCDateToLocalDate(s.dateInSterilizer, "	PP"),
            time: this.convertUTCDateToLocalDate(s.dateInSterilizer, "p"),
            timeElapsed: this.createDateDifference(s.dateInSterilizer),

            },
          testedBy: s.testedBy,
          program: s.program,
          sterilizer: s.sterilizer,
          cycle: s.cycle,
          load: s.loadNumber,
          biLot: s.biLot,
          biId: s.biId,
          biState: this.GetBiState(s.biState)
    }
    ))

    this.list=list;
    console.log(list);

    
  },
  GetBiState(biState): object {
    if (biState === 0){
      return {
            color: "warning",
            message: "Biological Indicator test is not yet ready for verification",
            label: "Incubating"
          };
    } else if (biState === 1){
      return {
            color: "success",
            message: "The Biological Indicator test is ready for verification",
            label: "Ready"
          };
    } else {
      return {
            color: "danger",
            message: "Something is wrong with this widget. Please contact support",
            label: "Error"
          } 
    }




  },
  takeNextStep(item) {    
    (this.modal as bootstrap.Modal).show();   
    this.selectedSterilizer=item.sterilizer;
    this.selectedLoadNumber=item.load;
    this.selectedbiId=item.biId;
    this.showVerifyVial=true;
  },
onSubmitCompleted() {
      this.loadData();

      console.log(this.modal);
      if (this.modal) {
        (<bootstrap.Modal>this.modal).hide();
      }
      this.showVerifyVial = false;
      this.$emit("submitCompleted");
    },
  GetParsedDate(timeIn) : string
    {
      
      const options={month:'numeric',day:'numeric',year:'numeric'}
      const localdate = timeIn.toLocaleString("en-US",options)

      return localdate
    },
    GetParsedTime(timeIn): string
    {
      const LocalTimeZone=Intl.DateTimeFormat().resolvedOptions().timeZone
      const options={timeZone: LocalTimeZone,timeZoneName: 'short',hour: 'numeric',minute:'numeric'}
      const localtime = timeIn.toLocaleString("en-US",options)
      return localtime
    },
  },
  setup() {
    const checked = ref(false);

    return {
      checked,
    };
  },
});
