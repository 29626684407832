<template>
  <div class="backdrop-custom" v-if="show" :style="style">
    <div
      class="row d-flex justify-content-center align-items-center modal-container"
    >
      <div
        class="modal-header-custom row d-flex justify-content-between align-items-center "
      >
        <div class="modal-title col">
          <h2 class="margin-zero">{{ title }}</h2>
        </div>
        <div
          class="btn btn-icon btn-sm btn-active-light-primary ms-2"
          @click="close()"
          aria-label="Close"
        >
          <span class="svg-icon svg-icon-2x"></span>
          <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
        </div>
      </div>
      <div class="modal-body-custom row">
        <slot class="col-12"> </slot>
      </div>
      <div class="modal-footer-custom text-end  p-5" v-if="footer">
        <button class="btn btn-secondary " v-if="cancelText" @click="no">
          {{ cancelText }}
        </button>
        <button class="btn btn-primary ml-1" v-if="okText" @click="ok">{{ okText }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";


// this is a custom modal created, it takes a title, buttons text, btns actions and a slot you can put whatever you need in the modal body just use it like that:
// <modal ...props>html that you want to appear in the body</modal>
// you can also put footer = false(footer will be removed) and create your own footer with the body

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    okText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    footer:{
        type: Boolean,
        required: true,
    }
  },
  mounted() {
    const modals = document.querySelector("div.backdrop-custom");
    if (modals && modals.length) {
      this.style = "z-index: " + 1900 + modals.length;
    }
  },
  data() {
    return {
      style: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    ok() {
      this.$emit("ok");
    },
    no() {
      this.$emit("no");
    },
  },
});
</script>
<style lang="scss" scoped>
$border: 1px solid #e4e6ef;
.backdrop-custom {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  top: 0px;
  z-index: 1900;
  left: 0px;
  .modal-header-custom {
    .margin-zero {
      margin: 0px !important;
    }
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    border-bottom: $border;
  }
  .modal-container {
    width: 50%;
    border-radius: 1%;
    background: white;
    position: fixed;
    left: 25%;
    animation-name: godown;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    @keyframes godown {
      from {
        top: 0px;
      }
      to {
        top: 50px;
      }
    }
  }
  .modal-body-custom {
    padding: 3rem;
  }
  .modal-footer-custom {
    .ml-1 {
      margin-left: 1rem;
    }
    border-top: $border;
  }
}
@media only screen and (max-width: 900px) {
  .backdrop-custom {
    .modal-container{
      width: 80%;
      left: 11%
    }
  }
}
@media only screen and (max-width: 575px) {
  .backdrop-custom {
    .modal-container{
      width: 90%;
      left: 8%
    }
  }
}
</style>
