
import { defineComponent, computed, reactive } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import { mapMutations } from "vuex";
import { useAuth0 } from "./auth";
import ApiService from "@/core/services/ApiService";
import Keypad from "@/components/Keypad.vue";

import KTLoader from "@/components/Loader.vue";
import * as bootstrap from "bootstrap";
import Avatar from "@/components/Avatar.vue";
import AddFoilTest from "@/components/MifuTest/AddFoilTest.vue";
import AddWasherTest from "@/components/MifuTest/AddWasherTest.vue";
export default defineComponent({
  name: "app",
  components: { KTLoader, Avatar, AddFoilTest, AddWasherTest},
  data() {
    return {
      asideEnabled: asideEnabled,
      themeLightLogo: themeLightLogo,
      themeDarkLogo: themeDarkLogo,
      loaderLogo: loaderLogo,
      modal: null,
      showKeypad: false,
    };
  },
  methods: {
    removeQuery() {
      //remove foilTest query from url so it doesnt stay and intiate it
      console.log("Snipe", "removing query ");

      let query = Object.assign({}, this.$route.query);
      if (query.foilTest)
        delete query.foilTest;
      if(query.washerTest)
        delete query.washerTest;
      delete query.id;
      this.$router.replace({ query });
    },
    ...mapMutations(["setAccessToken"]),
    async beforeCreate() {
      //need to externalize
      console.log("before create");

      console.log("setting signedin = true");
      //this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    },
  },
  mounted() {
    let elm = document.getElementById("foilTestModal");
    this.foilTestModal.modal = new bootstrap.Modal(elm);
    (this.foilTestModal.modal as bootstrap.Modal)._ignoreBackdropClick = true;
    (this.foilTestModal.modal as bootstrap.Modal)._config.keyboard = false;
    // (this.foilTestModal.modal as bootstrap.Modal)._backdrop._config.clickCallback = this.removeQuery();
    console.log(this.foilTestModal.modal);
    if (this.$route.query.foilTest) {
      this.showFoilTest();
    }

        let wlm = document.getElementById("washerTestModal");
    this.washerTestModal.modal = new bootstrap.Modal(wlm);
    (this.washerTestModal.modal as bootstrap.Modal)._ignoreBackdropClick = true;
    (this.washerTestModal.modal as bootstrap.Modal)._config.keyboard = false;
    // (this.foilTestModal.modal as bootstrap.Modal)._backdrop._config.clickCallback = this.removeQuery();
    console.log(this.washerTestModal.modal);
    if (this.$route.query.washerTest) {
      this.showWasherTest();
    }
  },
  watch: {
    "$route.query.foilTest": function () {
      let showFoilTest = this.$route.query.foilTest;
      if (showFoilTest) {

        this.showFoilTest();
      }
    },
    "$route.query.washerTest": function () {
      let showWasherTest = this.$route.query.washerTest;
      if (showWasherTest) {
        this.showWasherTest();
      }
    },
    "foilTestModal.modal._isShown": function () {
      let showFoilTest = this.$route.query.foilTest;
      console.log(
        "Snipe",
        this.foilTestModal.modal &&
          !(this.foilTestModal.modal as bootstrap.Modal)._isShown &&
          showFoilTest
      );
      if (
        this.foilTestModal.modal &&
        !(this.foilTestModal.modal as bootstrap.Modal)._isShown &&
        showFoilTest
      ) {
        this.removeQuery();
      }
    },
    "washerTestModal.modal._isShown": function () {
      let showWasherTest = this.$route.query.washerTest;
      console.log(
        "Snipe",
        this.washerTestModal.modal &&
          !(this.washerTestModal.modal as bootstrap.Modal)._isShown &&
          showWasherTest
      );
      if (
        this.washerTestModal.modal &&
        !(this.washerTestModal.modal as bootstrap.Modal)._isShown &&
        showWasherTest
      ) {
        this.removeQuery();
      }
    },
  },
  setup() {
    const store = useStore();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    // foil test
    let foilTestModal = reactive({ modal: null });
    const showFoilTest = () => {
      console.log(`opening modal ${foilTestModal.modal}`);

      (foilTestModal.modal as any).show();
    };
    const foilTestCompleted = () => {
      (foilTestModal.modal as any).hide();
    };
    // end foil test

    // washer test
    let washerTestModal = reactive({ modal: null });
    const showWasherTest = () => {
      console.log(`opening modal`, washerTestModal);
      
      (washerTestModal.modal as any).show();
    };
    const washerTestCompleted = () => {
      (washerTestModal.modal as any).hide();
    };
        const redirectWasher = () => {
      (washerTestModal.modal as any).hide();
      
    };

    // end washer test

    // show page loading
    //store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    return {
      foilTestCompleted,
      showFoilTest,
      foilTestModal,
      washerTestCompleted,
      showWasherTest,
      washerTestModal,
      pageTitle: pageTitle,
      contentWidthFluid,
      loaderEnabled,
    };
  },
});
