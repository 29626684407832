import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 col-sm-12 col-md-12 col-lg-12"
}
const _hoisted_3 = { class: "col-12 col-sm-12 col-md-12 col-lg-12" }
const _hoisted_4 = { class: "col-12 col-sm-12 col-md-12 col-lg-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MifuTestReminder = _resolveComponent("MifuTestReminder")!
  const _component_Sterilizers = _resolveComponent("Sterilizers")!
  const _component_Todo = _resolveComponent("Todo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showFoilReminders)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_MifuTestReminder, {
            onNoFoilTests: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFoilReminders= false))
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Sterilizers, {
        ref: "sterilizerComponent",
        onSubmitCompleted: _ctx.onSubmitCompleted
      }, null, 8, ["onSubmitCompleted"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Todo, {
        ref: "todoComponent",
        onSubmitCompleted: _ctx.onIncubatorSubmitCompleted
      }, null, 8, ["onSubmitCompleted"])
    ])
  ]))
}