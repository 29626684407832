
import { defineComponent, reactive, computed, ref } from "vue";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import AddProgram from "@/components/settings/Sterilizers/AddProgram.vue";

interface SterForm {
  name: string;
  isPreVacuum: boolean;
  manufacturer: string;
  model: string;
  startingCycleNumber: number;
}

export default defineComponent({
  components: { AddProgram },
  props: {
    initialUser: {
      type: Object,
      required: true,
    },
    selectedSterilizer: {
      required: true,
      type: Object,
    },
    allSterilizers: {
      required: true,
      type: [Object],
    },
  },
  data() {
    return {
      addPrograms: false,
    };
  },
  updated() {
    if (this.selectedSterilizer && this.selectedSterilizer.id != this.sterId) {
      this.state.name = this.selectedSterilizer.name;
      this.state.model = this.selectedSterilizer.model;
      this.state.isPreVacuum = this.selectedSterilizer.isPreVacuum;
      this.inService.state = !this.selectedSterilizer.isDecomissioned;
    }
  },
  methods: {
    async updateServiceSterilizer(id, name, inService) {
      console.log(id);
      try {
        let payload: any = {
          name: name,
          isDecommissioned: !inService, // !inService bcz its the opposite of decomissioned
          startingCycleNumber: this.state.startingCycleNumber,
        };
        let res = await ApiService.postData(`/Sterilizer/${id}`, payload, {});
        if (res.status === 200) {
          console.log("setrilizer service updated to ", res);
        }
      } catch (exception) {
        console.warn(exception);
      }
    },
    hideAddProgram() {
      this.addPrograms = false;
    },
    closeModal() {
      this.$emit("closeSterModal");
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let isPreVacuum= this.state.isPreVacuum == null || this.state.isPreVacuum == false
              ? false
              : true
        // here call api then take the new ster id
        const payload: Models.AddSterilizerFromCatalogue = {
          sterilizerCatalogId: isPreVacuum? 1: 2, //1: is a pre-vaccum, 4: is not a pre-vaccum. Change these values if necessary
          name: this.state.name,
          isPreVacuum: isPreVacuum,
          model: this.state.model,
          startingCycleNumber: this.state.startingCycleNumber,
          manufacturer: this.state.manufacturer,
        };
        console.log(payload);
        console.log(this.state.startingCycleNumber);
        let res;
        if (this.selectedSterilizer && this.selectedSterilizer.sterilizerId) {
          let editPayload: any = payload;
          editPayload.isDecommissioned = !this.inService.state;

                     res = await ApiService.postData(
            `/Sterilizer/${this.selectedSterilizer.sterilizerId}`,
            payload,
            {}
          ); 

        } else {
          res = await ApiService.postData("/Sterilizer", payload, {});
        }

        if (res.status === 200) {
          console.log(res);
          if (
            this.selectedSterilizer &&
            !this.selectedSterilizer.sterilizerId
          ) {
            let serviceupdatRes = await this.updateServiceSterilizer(
              res.data,
              payload.name,
              this.inService.state
            );
          }
          // check if NOT in service send another api with the steri id to /Sterilizer/{sterilizerId} with isDecommissioned = true else do nothing
          // ok
          // this.sterilizerId = -1; // here chnage it to the returned value from api
          // this.addPrograms = true;
        } else {
          // not okay
        }
        this.closeModal();
      }
    },
  },
  setup(props, context) {
    console.log(props.selectedSterilizer);
    const sterId = props.selectedSterilizer
      ? props.selectedSterilizer.id
      : null;
    const clinicId = props.initialUser.currentClinic.clinicId;
    let inService = reactive({
      state: !props.selectedSterilizer.isDecommissioned,
    });

    let state: SterForm = reactive({
      name: props.selectedSterilizer.name || "",
      isPreVacuum: props.selectedSterilizer
        ? props.selectedSterilizer.isPreVacuum
        : false,
      model: props.selectedSterilizer ? props.selectedSterilizer.model : "",
      manufacturer: props.selectedSterilizer
        ? props.selectedSterilizer.manufacturer
        : "",
      startingCycleNumber: props.selectedSterilizer
        ? props.selectedSterilizer.startingCycleNumber || 0
        : 0,
    });
    let allSteris: any = props.allSterilizers;
    const checkSteriName = (value) => {
      let flag = true;
      allSteris.forEach((steri: any) => {
        if (
          steri.name.toLowerCase() == value.toLowerCase() &&
          steri.sterilizerId != props.selectedSterilizer.sterilizerId
        ) {
          flag = false;
          return flag;
        }
      });
      return flag;
    };

    const mustBeUniqueName = (value) => checkSteriName(value);
    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage("Name is required.", required),
          mustBeUniqueName: helpers.withMessage(
            "Alias already in use.",
            mustBeUniqueName
          ),
        },
        model: {
          required: helpers.withMessage("Model is required.", required),
        },
        manufacturer: {
          required: helpers.withMessage("Brand name is required.", required),
        },
        startingCycleNumber: {
          required: helpers.withMessage(
            "Starting cycle number is required.",
            required
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      allSteris,
      inService,
      sterId,
      clinicId,
      state,
      v$,
    };
  },
});
