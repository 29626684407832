<template>
  <div class="row mb-10 mt-5">
    <div
      v-for="(ster, index) in filteredSelection"
      :key="index"
      class="col-12 col-sm-6 col-md-4 mb-10 d-flex align-items-stretch"
      :style="`${filteredSelection.length == 1 ? 'height: fit-content;' : ''}`"
    >
      <div class="card mx-2 shadow-hover width-fill-available tex-center">
        <img
          class="catalogue-image mt-1"
          :src="`${ster.imageUrl}`"
          onerror="this.src='/SterilizerDefaultImage.png';"
        />
        <div class="card-body d-flex flex-column">
          <h5 class="card-title">
            <span class="col">{{ ster.model }}</span>
          </h5>
          <p class="card-text">
            Brand: {{ ster.make }}

            <br />
            Pre-Vacuum: {{ ster.isPreVacuum ? "Yes" : "No" }}
          </p>
          <!-- <button type="button" class="btn btn-primary" @click="addSter(ster)">Add Sterilizer</button> -->
          <div class="d-flex align-items-end mt-auto" style="height: inherit">
            <a @click="addSter(ster)" class="card-link ml-5">Add Sterilizer</a>
            <a
              v-if="isSuperAdmin"
              @click="sterEdit(ster)"
              class="card-link ml-5"
              >Edit</a
            >
            <a
              v-if="ster.guide"
              :href="ster.guide"
              target="_blank"
              class="card-link ml-5"
              >Guide</a
            >
          </div>
        </div>
      </div>
    </div>
    <p v-if="filteredSelection.length == 0">No records found.</p>
  </div>
</template>
<script>
import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    filter: {
      type: Object,
      required: true,
    },
    isSuperAdmin: Boolean,
  },
  data() {
    return {
      sterilizers: [],
    };
  },
  computed: {
    filteredSelection() {
      if (!this.filter.model) {
        return this.sterilizers;
      }
      return this.sterilizers.filter((value) => {
        return value.model
          .toLowerCase()
          .includes(this.filter.model.toLowerCase());
      });
    },
  },
  methods: {
    sterEdit(selectedData) {
      var queryData = JSON.stringify({
        sterilizerCatalogId: selectedData.sterilizerCatalogId,
        make: selectedData.make,
        model: selectedData.model,
        isPreVacuum: selectedData.isPreVacuum,
        imageUrl: selectedData.imageUrl,
      });
      this.$router.push({
        name: "SterilizerInfo",
        params: { selectedSterilizer: queryData },
      });
    },
    addSter(sterInfo) {
      this.$emit("addSterilizer", sterInfo);
    },
  },
  async mounted() {
    let res = await ApiService.get("/Sterilizer/catalog");
    if (res.status == 200) {
      this.sterilizers = res.data;
      console.log(this.sterilizers);
    }
  },
});
</script>
<style lang="scss" scoped>
.btn-shadow {
  box-shadow: 4px 4px 5px -3px grey !important;
}
.shadow-hover {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 40%) !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 100%) !important;
}
.font-14 {
  font-size: 14px !important;
}
.width-fill-available {
  width: -webkit-fill-available;
}
.catalogue-image {
  width: 250px;
  height: 200px;
  object-fit: cover;
  margin: auto;
}
</style>
