
import { defineComponent, computed } from "vue";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  name: "TestCard",
  components: {},
  props: {
    didParamsPass: Boolean,
    didAllExternalCisPass: Boolean,
    didBDTestPass: Array,
    didBiTestPass: Array,
  },

  methods: {
    passResult(result) {
      if (result !== null) return result;
      return 2;
    },
    passResultBi(result) {
      if (result[1] != null) return result[1] && result[2];
      return 2;
    },
  },

  setup(props) {
    let results = {};

    if (props.didBDTestPass != null && props.didBiTestPass != null) {
      if (props.didBDTestPass[0] == true) {
        results = { "Bowie Dick": props.didBDTestPass[1] };
      } else {
        if (props.didBiTestPass[0] == true) {
          let biResult = props.didBiTestPass;
          let finalBi = 2;
          if (biResult[1] != null) {
            if (biResult[1] && biResult[2]) finalBi = 1;
            finalBi = 0;
          }
          results = {
            Parameter: props.didParamsPass,
            "Type Five": props.didAllExternalCisPass,
            BI: finalBi,
          };
        } else {
          results = {
            Parameter: props.didParamsPass,
            "Type Five": props.didAllExternalCisPass,
          };
        }
      }
    }

    console.log(props.didBDTestPass);
    return {
      results,
    };
  },
});
