<template>
  <div
    class="modal fade"
    id="sterilization_records"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl;">
      <DownloadRecords
        ref="downloadRecords"
        v-if="showDownloadRecords"
        @submitCompleted="onSubmitCompleted"
        :printMethod="this.method"
      />
    </div>
  </div>
  <div class="row gy-5 g-xl-8" style="width: 100%; height: 100%">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
      <div
        :class="widgetClasses"
        class="card"
        style="width: 100%; height: 100%"
      >
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >Incubation Records</span
            >
            <!--<span class="text-muted mt-1 fw-bold fs-7">Over 500 members</span>-->
          </h3>

          <div class="card-toolbar" title="Click to Print">
            <a
              @click="downloadCsv()"
              class="btn btn-sm btn-light-primary invisible button-font-shadow shadow"
            >
              <i class="fas fa-file-csv"></i>Download CSV
            </a>
            <a
              v-if="isFilterEnabled"
              @click="downloadCustom()"
              class="btn btn-sm btn-light-primary button-font-shadow shadow"
            >
              <i class="fas fa-file-signature"></i>Filtered Report
            </a>
            <a @click="downloadPdf()" class="btn btn-sm btn-light-primary m-1 button-font-shadow shadow">
              <i class="fas fa-file-pdf"></i>Generate Report
            </a>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body py-3">
          <ag-grid-vue
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            class="ag-theme-balham"
            style="width: 100%; height: 100%"
            @grid-ready="onGridReady"
            @gridSizeChanged="gridSizeChanged"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
  <!--end::Tables Widget 9-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import ApiService from "@/core/services/ApiService";
import DownloadRecords from "@/components/Reports/DownloadRecords.vue";
import * as bootstrap from "bootstrap";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent, ref } from "vue";
import moment from "moment-timezone";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useStore } from "vuex";
import {
  isToday,
  isYesterday,
  isThisWeek,
  subWeeks,
  isSameWeek,
  isThisMonth,
  subMonths,
  isSameMonth,
  isThisYear,
  subYears,
  isSameYear,
  format,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
} from "date-fns";

export default defineComponent({
  name: "IncubatiorsReport",
  components: { AgGridVue, DownloadRecords },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      sterilizers: null,
      method: "Incubation",
      columnDefs: null,
      defaultColDef: null,
      isFilterEnabled: false,
      rowData: null,
      api: null,
      columnApi: null,
      showDownloadRecords: false,
      modal: null,
    };
  },
  async mounted() {
    var modalElement = document.getElementById("sterilization_records");
    this.modal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function (event) {
        self.showDownloadRecords = false;
      });
    }
  },

  methods: {
    createCustomCsv(filteredArray){
      const csvString = [
      [
        "Load",
        "Date",
        "Operator",
        "Sterilizer",
        "Cycle",
        "Program",
        "BiLot",
        "Control Vial",
        "Test Vial",
        "Result",
        "Verified By",
        "Verified Date"
      ],
      ...filteredArray.map(item => [
        item.loadNumber,
        item.sterilizationDate,
        item.testedBy,
        item.sterilizer,
        item.cycle,
        item.program,
        item.biLot,
        item.controlVialResult,
        item.testVialResult,
        item.result,
        item.verifiedBy,
        item.verifiedDate
      ])
    ]
    .map(e => e.join(",")) 
    .join("\n");

    return csvString;
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], {type: "text/csv"});

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    downloadCsv() {
      this.api.exportDataAsCsv();
    },
    downloadCustom() {
      let customFiltered = [];
      this.api.forEachNodeAfterFilter((node) => customFiltered.push(node.data));

      //const doc = new jsPDF();
      const doc = new jsPDF("landscape","mm","letter");
      doc.addImage("/Logo.png", "PNG", 12, 8, 15, 15);
      doc.text("Incubation Report", 30, 18);
      doc.text(this.clinicName, 265, 18, "right");
      //doc.text(this.clinicName, 196, 18, "right");
      doc.setTextColor("#777");
      doc.setFontSize(10);
      doc.text("Custom Query", 30, 23);

      let table = {
        styles: { fontSize: 7 },
        columns: this.columnDefs.map((c) => ({
          header: c.headerName,
          dataKey: c.field.endsWith("Date") ? `${c.field}Print` : c.field,
        })),
        body: customFiltered,
        startY: 28,
      };
      doc.autoTable(table);

      if (!customFiltered.length) {
        doc.setFont(undefined, "bold");
        doc.setTextColor("#B22222");
        doc.setFontSize(14);
        doc.text("No Records Found", 15, 50);
      }
      doc.save("IncubationReport.pdf");
    },
    downloadPdf() {
      let shouldDoSomething = this.modal !== null;
      if (shouldDoSomething) {
        this.modal.show();
      }

      this.showDownloadRecords = true;
    },
    dateFormatter(params) {
      if (params.value === null) {
        return null;
      } else {
        return params.value;
      }
    },
    onGridReady(params) {
      this.api = params.api;
      this.columnApi = params.columnApi;
      this.api.sizeColumnsToFit();
      params.api.addGlobalListener(() => {
        if (params.api.isColumnFilterPresent()) this.isFilterEnabled = true;
        else this.isFilterEnabled = false;
      });
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
    onSubmitCompleted(programSelection , type) {
      if (this.modal) {
        this.modal.hide();
      }
      this.showVerifyVial = false;
      if (programSelection !== null && type !== null) {
          const userSelection = programSelection.value;
          let filtered = null;
            if (userSelection === "All") filtered = [...this.rowData];
            else {
              filtered = this.getDocFilter(userSelection);
            }
        if(type.value === 'pdf'){
          //const doc = new jsPDF();
          const doc = new jsPDF("landscape","mm","letter");
          doc.addImage("/Logo.png", "PNG", 12, 8, 15, 15);
          doc.text("Incubation Report", 30, 18);
          doc.text(this.clinicName, 265, 18, "right");
          //doc.text(this.clinicName, 196, 18, "right");
          doc.setTextColor("#777");
          doc.setFontSize(10);
          doc.text(this.getSelectionString(userSelection), 30, 23);

          let table = {
            styles: { fontSize: 7 },
            columns: this.columnDefs.map((c) => ({
              header: c.headerName,
              dataKey: c.field.endsWith("Date") ? `${c.field}Print` : c.field,
            })),
            body: filtered,
            startY: 28,
          };
          doc.autoTable(table);

          if (!filtered.length) {
            doc.setFont(undefined, "bold");
            doc.setTextColor("#B22222");
            doc.setFontSize(14);
            doc.text("No Records Found", 15, 50);
          }

          doc.save("IncubationReport.pdf");
        }else{
          var csv = this.createCustomCsv(filtered);
          this.downloadCSVFile(csv,"IncubationReport.csv");
        }
      }
    },
    getDocFilter(userSelection) {
      const timeElapsed = Date.now();
      let today = new Date(timeElapsed);
      var printData = [...this.rowData];
      printData = printData.filter(function (value, index, arr) {
        var logDate = new Date(value.sterilizationDatePrint);
        if (userSelection === "Today") return isToday(logDate);
        if (userSelection === "Yesterday") return isYesterday(logDate);
        if (userSelection === "This Week") return isThisWeek(logDate);
        if (userSelection === "Last Week")
          return isSameWeek(logDate, subWeeks(today, 1));
        if (userSelection === "This Month") return isThisMonth(logDate);
        if (userSelection === "Last Month")
          return isSameMonth(logDate, subMonths(today, 1));
        if (userSelection === "This Year") return isThisYear(logDate);
        if (userSelection === "Last Year")
          return isSameYear(logDate, subYears(today, 1));
      });
      return printData;
    },
    convertUTCDateToLocalDate(date, datetimeformat) {
      return format(new Date(date), datetimeformat);
    },
    getSelectionString(userSelection) {
      let today = new Date(Date.now());
      let currentTime = format(new Date(Date.now()), "h:mm a");
      if (userSelection === "All")
        return `All records (until ${format(
          today,
          "MMMM d, yyyy"
        )} at ${currentTime})`;
      if (userSelection === "Today")
        return `${format(today, "MMMM d, yyyy")} (until ${currentTime})`;
      if (userSelection === "Yesterday")
        return `${format(endOfYesterday(), "MMMM d, yyyy")}`;
      if (userSelection === "This Week")
        return `${format(startOfWeek(today), "MMMM d, yyyy")} - ${format(
          today,
          "MMMM d, yyyy"
        )} (until ${currentTime})`;
      if (userSelection === "Last Week")
        return `${format(
          startOfWeek(subWeeks(today, 1)),
          "MMMM d, yyyy"
        )} - ${format(endOfWeek(subWeeks(today, 1)), "MMMM d, yyyy")}`;
      if (userSelection === "This Month")
        return ` ${format(
          today,
          "MMMM 1, yyyy - MMMM d, yyyy"
        )} (until ${currentTime})`;
      if (userSelection === "Last Month")
        return `${format(subMonths(today, 1), "MMMM yyyy")}`;
      if (userSelection === "This Year")
        return `January 1, ${format(today, "yyyy")} - ${format(
          today,
          "MMMM d, yyyy"
        )} (until ${currentTime})`;
      if (userSelection === "Last Year")
        return `January 1, ${format(
          subYears(today, 1),
          "yyyy"
        )} - December 31, ${format(subYears(today, 1), "yyyy")}`;
    }
  },

  async beforeMount() {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
        suppressAndOrCondition: true,
      },
    };
    this.columnDefs = [
      {
        field: "loadNumber",
        headerName: "Load",
        filter: "agNumberColumnFilter",
        maxWidth: "80",
      },
      {
        field: "sterilizationDate",
        sortable: true,
        filter: "agDateColumnFilter",
        headerName: "Date",
        valueFormatter: this.dateFormatter,
      },
      {
        field: "testedBy",
        sortable: true,
        filter: true,
        headerName: "Operator",
      },
      {
        field: "sterilizer",
        sortable: true,
        filter: true,
        headerName: "Sterilizer",
      },
      {
        field: "cycle",
        sortable: true,
        filter: "agNumberColumnFilter",
        headerName: "Cycle",
        maxWidth: "75",
      },
      { field: "program", sortable: true, filter: true, headerName: "Program" },
      { field: "biLot", sortable: true, filter: true, headerName: "BiLot" },
      {
        field: "controlVialResult",
        sortable: true,
        filter: true,
        headerName: "Control Vial",
      },
      {
        field: "testVialResult",
        sortable: true,
        filter: true,
        headerName: "Test Vial",
      },
      { field: "result", sortable: true, filter: true, headerName: "Result" },
      {
        field: "verifiedBy",
        sortable: true,
        filter: true,
        headerName: "Verified By",
      },
      {
        field: "verifiedDate",
        sortable: true,
        filter: "agDateColumnFilter",
        headerName: "Verified Date",
      },
    ];

    try {
      const x = await ApiService.get("BI");
      const allSters = x.data;
      console.log(allSters);
      const timezoneFormat = "yyyy-MM-dd HH:mm";
      for (let i = 0; i < allSters.length; i++) {
        allSters[i].sterilizationDatePrint = "";
        allSters[i].verifiedDatePrint = "";
        if (allSters[i].dateInSterilizer) {
          allSters[i].sterilizationDate = this.convertUTCDateToLocalDate(allSters[i].dateInSterilizer, timezoneFormat)
          //
          allSters[i].sterilizationDatePrint = this.convertUTCDateToLocalDate(allSters[i].dateInSterilizer, timezoneFormat)
        }
        if (allSters[i].verifiedDate) {
          allSters[i].verifiedDate = this.convertUTCDateToLocalDate(allSters[i].verifiedDate, timezoneFormat)
          //.format(timezoneFormat);
          allSters[i].verifiedDatePrint = this.convertUTCDateToLocalDate(allSters[i].verifiedDate, timezoneFormat)
        }
        if (allSters[i].result !== null) {
          allSters[i].result = allSters[i].result ? "Passed" : "Failed";
        } else allSters[i].result = "Pending";

        if (allSters[i].controlVialResult === true) {
          allSters[i].controlVialResult = "Positive";
        } else if (allSters[i].controlVialResult === false) {
          allSters[i].controlVialResult = "Negative";
        }

        if (allSters[i].testVialResult === true) {
          allSters[i].testVialResult = "Positive";
        } else if (allSters[i].testVialResult === false) {
          allSters[i].testVialResult = "Negative";
        }
      }
      this.rowData = allSters;
      console.log("summary ", allSters);
    } catch (error) {
      console.error(error);
    }
  },
  setup() {
    setCurrentPageTitle("Incubation Report");
    const checked = ref(false);
    const store = useStore();
    const clinicName = store.getters.currentUser.currentClinic.name;

    return {
      checked,
      clinicName,
    };
  },
});
</script>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
