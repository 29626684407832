
import {
  defineComponent,
  onUpdated,
  onMounted,
  ref,
  reactive,
  computed,
} from "vue";

import useValidate from "@vuelidate/core";
import { required, helpers, sameAs } from "@vuelidate/validators";
import MessageBox from "@/components/MessageBox.vue";
import ApiService from "@/core/services/ApiService";
import Avatar from "@/components/Avatar.vue";
import { useStore } from "vuex";

interface ParamsData {
  passFail: string;
  cycleNumber: number;
  externalCisPassed: string;
  notes: string;
  time: boolean;
  temp: boolean;
  pressure: boolean;
}

export default defineComponent({
  name: "VerifyParams",
  props: {
    sterilizer: Object,
  },
  components: {
    Avatar,
    MessageBox
  },
  emits: ["submitCompleted"],
  methods: {
    async submit() {
      if (!this.submitButtonRef?.value) {
        return;
      }

      //Disable button
      this.submitButtonRef.disabled = true;
      // Activate indicator
      this.submitButtonRef.setAttribute("data-kt-indicator", "on");
      this.v$.$validate();
      let guard = false;
      if (
        this.state.externalCisPassed === "passed" &&
        this.state.passFail === "passed"
      ) {
        guard = true;
      } else if (
        this.state.passFail === "passed" &&
        this.state.externalCisPassed === "failed" &&
        this.state.notes !== ""
      ) {
        guard = true;
      } else if (this.state.passFail === "failed") {
        guard = false;
      }
      if (!this.v$.$error || guard) {
        try {
          let payload: any = {
            LoadId: this.sterilizer?.latestLoadId,
            Passed: this.state.passFail === "passed" ? true : false,
            notes: this.state.notes,
            externalCisPassed:
              this.state.externalCisPassed === "passed" ? true : false,
            UserId: this.initialUser.userId,
            cycleNumber: this.state.cycleNumber,
            readings: {},
          };
          if (this.state.passFail === "failed") {
            payload.readings.temperature = !this.state.temp;
            payload.readings.pressure = !this.state.pressure;
            payload.readings.time = !this.state.time;
          } else if (this.state.passFail === "passed") {
            payload.readings.temperature = true;
            payload.readings.pressure = true;
            payload.readings.time = true;
          }
          console.log(payload);

          await ApiService.postData("Load/params", payload, {});

          if (this.submitButtonRef) {
            this.submitButtonRef.disabled = false;
            //submitButtonRef.value?.removeAttribute("data-kt-indicator");
            this.$emit("submitCompleted");
            //window.location.reload(); //we don't want to reload the page. Just hide the modal and refresh
          }
        } catch (ex) {
          alert(
            "An error updating the database has occurred. Please contact support"
          );
          if (this.submitButtonRef) {
            this.submitButtonRef.disabled = false;
            this.submitButtonRef.removeAttribute("data-kt-indicator");
          }
        }
      } else {
        if (this.submitButtonRef) {
          this.submitButtonRef.disabled = false;
          this.submitButtonRef.removeAttribute("data-kt-indicator");
        }
      }
    },
    discard() {
      this.$emit("submitCompleted");
    },
  },

  setup(props, context) {
    const store = useStore();
    const initialUser = store.getters.currentUser;
    onUpdated(() => {}), onMounted(() => {});

    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    const state: ParamsData = reactive({
      passFail: "",
      externalCisPassed: "",
      notes: "",
      cycleNumber: props.sterilizer?.cycleNumber || 0,
      temp: false,
      time: false,
      pressure: false,
    });

    let rules = computed(() => {
      return {
        passFail: {
          required: helpers.withMessage("Pass/Fail required", required),
        },
        cycleNumber: {
          required: helpers.withMessage("Cycle number required", required),
        },
        externalCisPassed: {
          required: helpers.withMessage(
            "Chemical indicator required",
            required
          ),
        },
        notes: {
          required: helpers.withMessage("Notes are required", required),
        },
        temp: {
          required: helpers.withMessage(
            "Temperature result required.",
            required
          ),
        },
        time: {
          required: helpers.withMessage("Time result required.", required),
        },
        pressure: {
          required: helpers.withMessage("Pressure result required.", required),
        },
      };
    });

    let v$ = useValidate(rules, state);

    return {
      state,
      v$, //validation
      initialUser,
      // paramsData,
      // validationSchema,
      // submit,
      submitButtonRef,
      // passFail,
      // paramsResultError,
    };
  },
});
