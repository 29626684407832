<template>
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="user_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Add authorized user for ${currentClinicName}` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <edit-user
          v-if="showCreateEditUser"
          :selectedUser="null"
          :initialUser="initialUser"
          @closeAddUserForm="closeAddUserForm"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!--end::Modal - New Card-->

  <div :class="`card ${widgetClasses}`" style="width:100%;height:100%;">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Authorized Sterilization Verification Staff</span>

        <!--<span class="text-muted mt-1 fw-bold fs-7">Over 500 members</span>-->
      </h3>

      <div
        class="card-toolbar mr-3 "
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a new user"
      >
        
        <a @click="newUser()" class="btn btn-sm btn-light-primary add-user button-font-shadow shadow">
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotone/Communication/Add-user.svg" />
          </span>
          New User
        </a>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 row">
      <!--begin::Table container-->
      <div class="table-responsive row">
        <!--begin::Table-->
        <div class="row">
          <ag-grid-vue
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @grid-ready="onGridReady"
            @gridSizeChanged="gridSizeChanged"
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
          >
          </ag-grid-vue>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>

  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="editUser_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Edit authorized user for ${currentClinicName}` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <edit-user
          v-if="editUserVisible"
          :selectedUser="editUserModalObject"
          :initialUser="initialUser"
          @closeEditUserForm="closeEditUserForm"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="pin_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `PIN` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <AddEditPin
          :selectedUser="editUserModalObject"
          @closePinModal="closePinModal"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->

    <!-- start delete modal -->
    <div class="modal fade" tabindex="-1" id="deleteUser_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="userToDelete">
            Delete Operator  "{{ userToDelete.firstName }} {{ userToDelete.lastName }}""
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <p v-if="userToDelete">
            Are you sure you want to delete "{{ userToDelete.firstName }} {{ userToDelete.lastName }}"?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancel
          </button>
          <button
            v-if="userToDelete"
            type="button"
            class="btn btn-danger"
            @click="deleteUser(userToDelete.userId)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end delete modal -->

  <!--end::Tables Widget 9-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
// import CreateEditUser from "@/components/settings/CreateEditUser.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EditUser from "@/components/settings/EditUser.vue";
import { useStore } from "vuex";
import UserManagmentBtnCellRenderer from "./UserManagmentBtnCellRenderer.vue";
import AddEditPin from "@/components/settings/AddEditPin.vue";
export default defineComponent({
  name: "kt-widget-9",
  components: {
    // CreateEditUser,
    AgGridVue,
    EditUser,
    AddEditPin,
    UserManagmentBtnCellRenderer,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      list: [],
      modal: null,
      editUserModal: null,
      deleteUserModal: null,
      userToDelete: null,
      pinModal: null,
      showCreateEditUser: false,
      editUserVisible: false,
      editUserModalObject: null,

      //ag-grid
      defaultColDef: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      api: null,
    };
  },
  async beforeMount() {
    this.refreshTable();
  },

  async mounted() {
    //     const store = useStore();
    // const initialUser = store.getters.currentUser;
    // if(!this.initialUser.currentClinic.isAdmin){
    //   this.$router.push("/dashboard")
    // }
    this.loadData();
    let modalElement = document.getElementById("user_modal");
    this.modal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      const self = this;
      modalElement.addEventListener("hidden.bs.modal", function(event) {
        self.showCreateEditUser = false;
      });
    }
    // editUser_modal
    modalElement = document.getElementById("editUser_modal");
    this.editUserModal = new bootstrap.Modal(modalElement);
  },
  methods: {
    async refreshTable() {
      this.defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
      };

      const self = this;
      this.columnDefs = [
        { field: "firstName", filter: true },
        { field: "lastName", filter: true },
        {
          field: "edit",
          headerName: "Actions",
          cellRendererFramework: UserManagmentBtnCellRenderer,
          resizable: false,
          filter: false,
          maxWidth: 187,
          cellRendererParams: {
            clicked: function(selectedData) {
              if (self.showCreateEditUser) {
                self.showCreateEditUser = !self.showCreateEditUser;
              }
              self.editUserModalObject = selectedData;
              self.editUserVisible = true;
              self.editUserModal.show();
            },
            keyClicked: function(selectedData) {
              self.editPin(selectedData);
            },
            deleteClicked: function(selectedData) {
              self.openDeleteUserModal(selectedData);
            },
          },
        },
      ];

      let usersList = await ApiService.get("User/operator");
      usersList = usersList.data;
      console.log(usersList);
      for (let i = 0; i < usersList.length; i++) {
        usersList[i].edit = usersList[i];
        // usersList[i].
      }
      usersList = usersList.filter((user) => {
        if (user.isAdmin) {
          return false;
        } else {
          return true;
        }
      });
      console.log(usersList)
      this.rowData = usersList;
    },
    closeAddUserForm() {
      this.refreshTable();
      this.modal.hide();
      this.showCreateEditUser = false;
    },
    editPin(userData) {
      this.editUserModalObject = userData;
      const modalElement = document.getElementById("pin_modal");
      this.pinModal = new bootstrap.Modal(modalElement);
      this.pinModal.show();
    },
    openDeleteUserModal(userData) {
      this.userToDelete = userData;
      const modalElement = document.getElementById("deleteUser_modal");
      this.deleteModal = new bootstrap.Modal(modalElement);
      this.deleteModal.show();
    },
    async deleteUser(userId) {
      await ApiService.delete(`User/operator/${userId}`);
      this.refreshTable();
      this.deleteModal.hide();
    },
    closePinModal() {
      this.refreshTable();
      this.pinModal.hide();
    },
    closeDeleteUserModal() {      
      this.deleteModal.hide();
    },
    closeEditUserForm() {
      this.refreshTable();
      this.editUserVisible = false;
      this.editUserModal.hide();
    },
    onGridReady(params) {
      this.api = params.api;
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
    getSelectedRows() {
      try {
        if (this.showCreateEditUser) {
          this.showCreateEditUser = !this.showCreateEditUser;
        }
        const selectedNodes = this.gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data);
        console.log("data", selectedData);
        console.log(`Selected nodes: ${selectedData}`);
        if (!selectedNodes[0]) {
          throw "Select a user first.";
        }
        this.editUserModalObject = selectedData[0];
        this.editUserVisible = true;
        this.editUserModal.show();
      } catch (err) {}
    },
    newUser() {
      this.modal.show();
      this.showCreateEditUser = true;
    },
    async loadData() {      
      let list = (await ApiService.get("user")).data;
      this.list = list;
    },
    onSubmitCompleted() {
      console.log("hitting here");
      this.loadData();

      this.modal.hide();
    },
  },
  setup() {
    setCurrentPageTitle("User Management");
    const checked = ref(false);
    const store = useStore();
    const initialUser = store.getters.currentUser;
    const currentClinicName = initialUser.currentClinic.name;

    return {
      checked,
      initialUser,
      currentClinicName,
    };
  },
});
</script>

<style lang="scss" scoped>
.mr-3{
  margin-right: 3rem !important;
}

// @import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
// @import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
