
import { computed, defineComponent, reactive, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import Avatar from "@/components/Avatar.vue";
import MessageBox from "@/components/MessageBox.vue";
import { helpers, required } from "@vuelidate/validators";
import useValidate from '@vuelidate/core';
export default defineComponent({
  name: "CreateBDLoad",
  props: {
    sterilizer: Object,
    latestLoadNumber: Number,
  },
  emits: ["submitCompleted"],
  methods: {
    discard() {
      this.$emit("submitCompleted");
    },
  },
  components: { Avatar, MessageBox },

  setup(props, context) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const state = reactive({
      cycleNumber: props.sterilizer?.cycleNumber +1
    });
        let rules = computed(() => {
      return {
        cycleNumber: {
          required: helpers.withMessage("Cycle number required", required),
        },
      };
    });
        let v$ = useValidate(rules, state);

    const submit = async (values) => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      v$.value.$validate();
      if(!v$.value.$error){
        try {
        await ApiService.postData(
          "Load/BD",
          {
            SterilizerId: props.sterilizer?.sterilizerId,
            cycleNumber: state.cycleNumber,
            UserId: 1,
          },
          {}
        );

        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          context.emit("submitCompleted");
        }
      } catch (ex) {
        alert("An error updating the database has occurred. Please contact support");
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
      }
      }


    };

    return {
      v$,
      state,
      submit,
      submitButtonRef,
    };
  },
});
