import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_grid_vue = _resolveComponent("ag-grid-vue")!

  return (_openBlock(), _createBlock(_component_ag_grid_vue, {
    style: {"width":"100%"},
    class: "ag-theme-balham",
    columnDefs: _ctx.columnDefs,
    rowData: _ctx.rowData,
    pagination: true,
    paginationPageSize: 5,
    onFirstDataRendered: _ctx.onFirstDataRendered,
    onGridSizeChanged: _ctx.onFirstDataRendered
  }, {
    default: _withCtx(() => [
      _createTextVNode(" > ")
    ]),
    _: 1
  }, 8, ["columnDefs", "rowData", "onFirstDataRendered", "onGridSizeChanged"]))
}