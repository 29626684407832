<template>
  <div class="card" style="width:100%;height:100%;">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">BI Lots</span>
      </h3>
      <h3 class="card-title align-items-end flex-column">
        <div
          class="card-toolbar card-label fw-bolder fs-3 mb-1"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a user"
        >
          <a
            @click="addBI"
            class="btn btn-sm btn-light-primary button-font-shadow shadow"
            data-bs-toggle="modal"
            data-bs-target="#addBIModal"
            style="margin-right:-1rem;"
          >
            <i class="fas fa-vial"></i>
            New BI Lot
          </a>
        </div>
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 row ">
      <!--begin::Table container-->
      <ag-grid-vue
        :defaultColDef="defaultColDef"
        :columnDefs="columnDefs"
        :rowData="rowData"
        style="width: 100%; height: 100%;"
        class="ag-theme-balham"
        @grid-ready="onGridReady"
        @first-data-rendered="gridSizeChanged"
        @gridSizeChanged="gridSizeChanged"
      >
      </ag-grid-vue>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="addBIModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add BI</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!-- <AddBI @biAdded="biAdded" v-if="addBIVisible" :biLotNumbers="biLotNumbers"/> -->
        <EditBi
          @biAdded="biAdded"
          :selectedBI="null"
          :biLotNumbers="biLotNumbers"
          v-if="addBIVisible"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="editBi_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit BI</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <EditBi
          @biAdded="biAdded"
          :selectedBI="editBiInfo"
          :biLotNumbers="biLotNumbers"
          v-if="editBIVisible"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!-- start delete confirm Modal -->
  <div
    class="modal fade"
    tabindex="-1"
    id="deleteBI_modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"  v-if="biLotToDelete">Delete BI Lot {{ biLotToDelete.biLotNumber }}</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <p v-if="biLotToDelete">
            Are you sure you want to delete BI lot {{ biLotToDelete.biLotNumber }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancel
          </button>
          <button
           v-if="biLotToDelete"
            type="button"
            class="btn btn-danger"
            @click="deleteBi(biLotToDelete.biLotId)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end delete confirm Modal -->

  <!--end::Tables Widget 9-->
</template>

<script>
import EditBi from "@/components/settings/BIlot/EditBi.vue";
// import AddBI from "@/components/settings/BIlot/AddBI.vue";
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import DeleteBtnCellRenderer from "../UserManagmentBtnCellRenderer.vue";
import BtnCellRederer from "../BtnCellRederer.vue";
import moment from "moment-timezone";

import { format } from 'date-fns'


import * as bootstrap from "bootstrap";
export default defineComponent({
  name: "BI",
  components: {
    AgGridVue,
    BtnCellRederer,
    DeleteBtnCellRenderer,
    // AddBI,
    EditBi,
  },
  props: {},
  data() {
    return {
      deleteBiModal: null,
      biLotToDelete: null,
      editBIVisible: false,
      addBIVisible: false,
      columnDefs: null,
      rowData: null,
      modal: null,
      editModal: null,
      editBiInfo: null,
      defaultColDef: null,
      biLotNumbers: [],
      api: null,
      columnApi: null,
    };
  },
  async beforeMount() {},

  mounted() {
    let modal = document.getElementById("deleteBI_modal") 
    this.deleteBiModal = new bootstrap.Modal(modal);
    try {
      setCurrentPageTitle("BI Lots");
    } catch (err) {}
  },
  async beforeMount() {
    this.refreshTable();
  },
  

  methods: {
    async deleteBi(biLotId) {
      try{
        let res = await ApiService.delete(`BI/lot/${biLotId}`);
        if(res.status === 200){
          this.deleteBiModal.hide();
          this.refreshTable()
        }
      }catch(e){
        console.warn(e);
      }

    },
    onGridReady(params) {
      console.log("grid ready")
      this.api = params.api;
      this.columnApi = params.columnApi;      
    },
    gridSizeChanged() {
      console.log("grid ready")
      this.api.sizeColumnsToFit();
    },
    async refreshTable() {
      let biList = await ApiService.get("BI/lot");
      biList = biList.data;
      console.log(biList);
      biList.forEach(bilot => {
        this.biLotNumbers.push(bilot.biLotNumber);
      });
      const timezoneFormat = "yyyy-MM-dd";
      for (let index = 0; index < biList.length; index++) {
        const bi = biList[index];
        biList[index].edit = bi;
        biList[index].delete = bi;
        biList[index].expiryDate = this.convertUTCDateToLocalDate(biList[index].expiryDate, timezoneFormat);
      }
      let self = this;
      this.defaultColDef = {
        resizable: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
      };

      this.columnDefs = [
        {
          field: "biLotNumber",
          headerName: "BI Lot #",
          sortable: true,
          filter: true,
          // maxWidth: "120",
        },
        {
          field: "brandName",
          headerName: "Brand",
          filter: true,
          sortable: true,
          // maxWidth: "120",
        },
        {
          field: "expiryDate",
          headerName: "Expiry Date",
          sortable: true,
          filter: true,
          // filter: "agDateColumnFilter",
          // maxWidth: "150",
        },
        {
          field: "incubationTimeInMinutes",
          headerName: "Incubation Time",
          sortable: true,
          filter: true,
          valueFormatter: this.minutesFormater,
          // maxWidth: "190",
        },
        {
          field: "quantity",
          sortable: true,
          filter: "agNumberColumnFilter",
          // maxWidth: "110",
        },
        {
          field: "supplier",
          sortable: true,
          filter: true,
        },
        {
          field: "edit",
          headerName: "Actions",
          resizable: true,
          cellRendererFramework: BtnCellRederer,
          maxWidth: "100",
          cellRendererParams: {
            clicked: function(selectedData) {
              var filtered = self.biLotNumbers.filter(function(value, index, arr){ 
                  return value != selectedData.biLotNumber;
              });
              self.biLotNumbers = filtered;
              self.editBiLot(selectedData);
            },
            deleteClicked: function(selectedData) {
              self.biLotToDelete = selectedData;
              self.deleteBiModal.show();
              console.log("clicked delete", selectedData);
            },
          },
        },
      ];
      this.rowData = biList;
    },
    convertUTCDateToLocalDate(date, datetimeformat) {
      return format(new Date(date), datetimeformat);
    },
    minutesFormater(n){
      if(n.value){
        var num = n.value;
        console.log(num)
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return `${rhours > 0 ? rhours + " h " : ''} ${rminutes > 0 ? rminutes + ' m ' : ''}`
      }
    },
    biAdded() {
      document.getElementById("closeAddBiModal").click();
      this.addBIVisible = false;
      this.refreshTable();
    },
    editBiLot(data) {
      console.log(data);
      this.editBIVisible = true;
      this.editBiInfo = data;
      var modalElement = document.getElementById("editBi_modal");
      this.editModal = new bootstrap.Modal(modalElement);
      if (modalElement) {
        var self = this;
        modalElement.addEventListener("hidden.bs.modal", function(event) {});
      }
      this.editModal.show();
    },
    addBI() {
      this.addBIVisible = true;
      var modalElement = document.getElementById("addBi_modal");
      this.modal = new bootstrap.Modal(modalElement);
      if (modalElement) {
        var self = this;
        modalElement.addEventListener("hidden.bs.modal", function(event) {
          self.showKeypad = false;
        });
      }
      this.modal.show();
    },
  },
});
</script>

<style lang="scss">
div.ag-cell[col-id="edit"],
div.ag-cell[col-id="delete"] {
  padding: 0px !important;
}
</style>
