import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Users from "../components/settings/Users.vue";
import SterilizersReport from '@/components/Reports/SterilizationRecords.vue';
import PackageReport from '@/components/Reports/PackageRecords.vue'; 
import IncubationReport from '@/components/Reports/IncubationRecords.vue';
import Packages from '@/components/settings/Packages.vue'
import BI from '@/components/settings/BIlot/BI.vue'
import store from '../store/index'
import KeyPadView from '@/views/KeyPadView.vue';
import Profile from '@/components/Profile/Profile.vue';
import Sterilizers from '@/components/settings/Sterilizers/Sterilizers.vue'
import SpecificSterilizer from '@/components/settings/Sterilizers/SpecificSterilizer.vue'
import Load from "@/components/Load.vue"
import Error500 from "@/views/error/Error500.vue"
import PackageVerification from "@/views/PackageVerification.vue"
import AddSterilizerCatalog from "@/components/settings/Catalog/AddSterilizerCatalog.vue"
import SterilizerInfo from "@/components/settings/Catalog/SterilizerInfo.vue"
import Login from "@/views/Login.vue"
import { defineComponent } from "vue";
import { getInstance } from "@/auth/auth"
import { useAuth0 } from "@/auth/auth";
import { Actions } from "@/store/enums/StoreEnums";
//import FoilTest from "@/components/MifuTest/UltrasonicCleaner.vue"
import Devices from "@/components/MifuTest/Devices.vue"
import FoilTestRecords from "@/components/Reports/FoilTestRecords.vue"
import WasherTestRecords from "@/components/Reports/WasherTestRecords.vue"
// import { UserContext } from "@/auth/UserContext";
// import { useRoute } from "vue-router";
import Guides from "@/components/Guides/Guides.vue"

const authenticationGuard = (to, from, next) => {
  console.log("guard checking to", to)
  console.log("guard checking from", from)
  console.log("guard checking next", next)
  console.log("guard checking currentuser", store.getters.currentUser)

  if (to.name !== 'login' && !store.getters.currentUser) {
    next({ name: 'login', query: { redirectFrom: to.fullPath } })
  }
  else {
    next()
  }
};

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: authenticationGuard,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/load",
        name: "Load",
        component: Load,
      },
      {
        path: "/verify",
        name: "Verify",
        component: PackageVerification,
      },


      {
        path: "/settings/addcatalog",
        name: "Add Sterilizer Catalog",
        component: AddSterilizerCatalog,
        beforeEnter: async (to, from, next) => {
          const initialUser = store.getters.currentUser;
          if (initialUser.isSuperAdmin) {
            next();
          } else {
            next(false);
          }
        }
      },
      {
        path: "/guides",
        name: "Guides",
        component: Guides,
      },
      {
        path: "/settings/users",
        name: "Users",
        component: Users,
        beforeEnter: async (to, from, next) => {
          const initialUser = store.getters.currentUser;
          if (initialUser.currentClinic.isAdmin) {
            next();
          } else {
            next(false);
          }
        }
      },
      {
        path: "/settings/sterilizers",
        name: "Sterilizers",
        component: Sterilizers,
      }, {
        path: "/settings/specific/:id",
        name: "SpecificSterilizer",
        component: SpecificSterilizer,
      },
      {
        path: "/settings/sterilizerinfo",
        name: "SterilizerInfo",
        component: SterilizerInfo,
        props: true,
        beforeEnter: async (to, from, next) => {
          const initialUser = store.getters.currentUser;
          if (initialUser.isSuperAdmin) {
            next();
          } else {
            next(false);
          }
        }
      },

      {
        path: "/reports/sterilizationrecords",
        name: "SterilizationReport",
        component: SterilizersReport,
      },
      {
        path: "/reports/packagereport",
        name: "PackageReport",
        component: PackageReport,
      },
      {
        path: "/reports/foiltests",
        name: "FoilTestReport",
        component: FoilTestRecords
      },
      {
        path: "/reports/washertests",
        name: "WasherTestReport",
        component: WasherTestRecords
      },
      {
        path: "/reports/incubationrecords",
        name: "IncubationReport",
        component: IncubationReport,
      },
      {
        path: "/settings/packages",
        name: "Packages",
        component: Packages,
        beforeEnter: async (to, from, next) => {
          const initialUser = store.getters.currentUser;
          if (initialUser.currentClinic.isAdmin) {
            next();
          } else if (store.getters.currentClinicInfo.allowOperatorsToManagePackages) {
            next();
          } else {
            next(false);
          }
        }
      },
      {
        path: "/settings/BI",
        name: "BI",
        component: BI
      },
      // {
      //   path: "/settings/ultrasonicCleaner",
      //   name: "UltrasonicCleaner",
      //   component: FoilTest
      // },
      {
        path: "/settings/devices",
        name: "Devices",
        component: Devices
      },
    ]
  },
  {
    path: "/pin",
    name: "Pin",
    component: KeyPadView,
    beforeEnter: authenticationGuard,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: defineComponent({
      async mounted() {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        localStorage.removeItem("ClinicUserToken");
        if (store.getters.currentUser) {
          await getInstance().logout({ returnTo: window.location.origin });
        } else {
          const domain = "dev-qpn294pj.us.auth0.com";
          const clientId = "9faOlN5h7HpBLPeIPVQMTFGmJ8wHyGyQ";
          const audience = "https://api.verify.ca";

          const auth0 = await useAuth0({
            domain,
            clientId,
            audience,
            redirectUri: `${window.location.origin}/login`,
            onRedirectCallback: (appState) => {
              console.log("appstate", appState);
              this.$router.push(
                appState && appState.targetUrl
                  ? appState.targetUrl
                  : window.location.pathname
              );
            },
          });
          await auth0.logout({ returnTo: window.location.origin });
        }
      }
    }),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/500",
    name: "Internal Error",
    component: Error500,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.currentUser) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router;
