<template>
  <div class="card mb-5 mb-xxl-8">
    <div class="card-body pt-9 pb-0" v-if="editSterilizerObject">
      <router-link to="/settings/sterilizers"
        ><i class="fas fa-arrow-left" style="color: inherit"></i> back
      </router-link>
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <div class="me-7 mb-4">
          <div
            class="
              symbol symbol-100px symbol-lg-160px symbol-fixed
              position-relative
            "
          >
            <img
              style="object-fit: contain"
              :src="`${
                editSterilizerObject.catalog &&
                editSterilizerObject.catalog.imageUrl
                  ? editSterilizerObject.catalog.imageUrl
                  : '/SterilizerDefaultImage.png'
              }`"
              alt="image"
            />
          </div>
        </div>
        <div class="flex-grow-1">
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >{{ editSterilizerObject.name }}</a
                >
              </div>
            </div>
            <!-- <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <button
                  :class="`btn ${editSterilizerObject.isDecommissioned ? 'btn-primary' : 'btn-danger'}`"
                  type="button"
                  @click="deactivateSterilizer(editSterilizerObject.sterilizerId, editSterilizerObject.name, editSterilizerObject.isDecommissioned)"
                >
                {{editSterilizerObject.isDecommissioned ? 'Activate Sterilizer' : 'Deactivate Sterilizer'}}
                  
                </button>
              </div>
            </div> -->
          </div>
          <div class="d-flex flex-wrap flex-stack">
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <div class="d-flex flex-wrap">

                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-100px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Title-->
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bolder">
                      {{ editSterilizerObject.model }}
                    </div>
                  </div>
                  <!--end::cycle-->
                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Model</div>
                  <!--end::Label-->
                </div>
                <div
                  v-if="editSterilizerObject.manufacturer != null"
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-100px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Title-->
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bolder">
                      {{ editSterilizerObject.manufacturer }}
                    </div>
                  </div>
                  <!--end::cycle-->
                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Brand</div>
                  <!--end::Label-->
                </div>
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <div class="d-flex align-items-center">
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="4500"
                      data-kt-countup-prefix="$"
                    >
                      {{ editSterilizerObject.isPreVacuum ? "Yes" : "No" }}
                    </div>
                  </div>
                  <div class="fw-bold fs-6 text-gray-400">Pre Vacuum</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="
            nav nav-stretch nav-line-tabs nav-line-tabs-2x
            border-transparent
            fs-5
            fw-bolder
            flex-nowrap
          "
        >
          <li class="nav-item">
            <a
              aria-current="page"
              role="button"
              class="
                active
                router-link-exact-active
                nav-link
                text-active-primary
                me-6
              "
              ref="sterilizerInfo"
              @click="showSterilizerInfo"
            >
              Edit Sterilizer
            </a>
          </li>
          <li class="nav-item">
            <a
              @click="showSterilizerPrograms"
              ref="sterilizerPrograms"
              role="button"
              class="nav-link text-active-primary me-6"
            >
              List Programs
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card mb-5 mb-xxl-8 p-5">
    <add-sterilizer
      v-if="initialUser && changeSterInfo && editSterilizerObject"
      :allSterilizers="allSters"
      :initialUser="initialUser"
      :selectedSterilizer="editSterilizerObject"
      @closeSterModal="$router.push({ path: '/settings/sterilizers' })"
    />
    <specific-ster-programs
      v-if="!changeSterInfo && editSterilizerObject"
      :sterilizer="editSterilizerObject"
    />
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import AddSterilizer from "@/components/settings/Sterilizers/AddSterilizer.vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import SpecificSterPrograms from "@/components/settings/Sterilizers/SpecificSterPrograms.vue";
import InlineSvg from "vue-inline-svg";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  components: {
    AddSterilizer,
    SpecificSterPrograms,
    InlineSvg,
  },
  data() {
    return {
      changeSterInfo: true,
      editSterilizerObject: null,
      allSters: [],
    };
  },
  methods: {
    async deactivateSterilizer(id, name, isDecom) {
      console.log(id);
      try {
        let payload = {
          name: name,
          isDecommissioned: !isDecom,
        };
        let res = await ApiService.postData(`/Sterilizer/${id}`, payload, {});
        if (res.status === 200) {
          this.$router.go(-1);
        }
      } catch (exception) {
        console.warn(exception);
      }
    },

    showSterilizerInfo(e) {
      this.sterilizerInfo.classList.value =
        "active router-link-exact-active nav-link text-active-primary me-6 ";
      this.sterilizerPrograms.classList.value =
        "nav-link text-active-primary me-6";
      this.changeSterInfo = true;
      // clicker.remove
    },
    showSterilizerPrograms(e) {
      this.sterilizerPrograms.classList.value =
        "active router-link-exact-active nav-link text-active-primary me-6 ";
      this.sterilizerInfo.classList.value = "nav-link text-active-primary me-6";
      this.changeSterInfo = false;
    },
    async getAllSter() {
      let res = await ApiService.get("/Sterilizer");
      if (res.status === 200) {
        let allSters = res.data;
        this.allSters = allSters;
        allSters.forEach((ster) => {
          if (ster.sterilizerId == this.id) {
            this.editSterilizerObject = ster;
          }
        });
      }
    },
  },
  async mounted() {
    await this.getAllSter();
    setCurrentPageTitle(this.editSterilizerObject.name);
  },
  setup(props, context) {
    setCurrentPageTitle("");
    const route = useRoute();
    const id = route.params.id;
    const sterilizerInfo = ref(HTMLAnchorElement);
    const sterilizerPrograms = ref(HTMLAnchorElement);

    const store = useStore();
    const initialUser = store.getters.currentUser;
    return {
      initialUser,
      sterilizerInfo,
      sterilizerPrograms,
      id,
    };
  },
});
</script>
