
import {
  defineComponent,
  ref,
  reactive,
  computed,
} from "vue";

import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import MessageBox from "@/components/MessageBox.vue";
import Avatar from "@/components/Avatar.vue";
import ApiService from "@/core/services/ApiService";

import ImpactedBi from "@/components/ImpactedBi.vue";

interface ParamsData {
  positiveNegativeControl: string;

  positiveNegativeTest: string;
}

export default defineComponent({
  name: "VerifyVial",
  props: {
    sterilizer: String,
    LoadNumber: Number,
    biId: Number,
  },

  components: {
    Avatar,
    ImpactedBi,
    MessageBox
  },

  emits: ["submitCompleted"],
  methods: {
    async submit () {
      if (!this.submitButtonRef?.value) {
        return;
      }


      //Disable button
      this.submitButtonRef.disabled = true;
      // Activate indicator
      this.submitButtonRef.setAttribute("data-kt-indicator", "on");
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
        await ApiService.postData(
          "BI/result",
          {
            biId: this.biId,
            testVialResult:
              this.state.positiveNegativeTest === "positive" ? true : false,
            controlVialResult:
              this.state.positiveNegativeControl === "positive" ? true : false,
          },
          {}
        );

        if (this.submitButtonRef) {
          this.submitButtonRef.disabled = false;
          this.$emit("submitCompleted");
        }
      } catch (ex) {
        console.log(ex);
        if (this.submitButtonRef) {
          this.submitButtonRef.disabled = false;
          this.submitButtonRef.removeAttribute("data-kt-indicator");
        }
      }
      }else{
        if (this.submitButtonRef) {
          this.submitButtonRef.disabled = false;
          this.submitButtonRef.removeAttribute("data-kt-indicator");
        }
      }

    },
    discard() {
      this.$emit("submitCompleted");
    },
  },

  setup(props, context) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    // const paramsData = ref<ParamsData>({
    //   positiveNegativeControl: "",
    //   positiveNegativeTest: "",
    // });

    // const validationSchema = Yup.object({
    //   positiveNegativeControl: Yup.string().label("Control Result").required(),
    //   positiveNegativeTest: Yup.string().label("Test Result").required(),
    // });

    // const { handleSubmit } = useForm({ validationSchema: validationSchema });
    // const { value: positiveNegativeControl, errorMessage: controlResultError } =
    //   useField("positiveNegativeControl");
    // const { value: positiveNegativeTest, errorMessage: testResultError } =
    //   useField("positiveNegativeTest");

      const state: ParamsData = reactive({
        positiveNegativeControl: "",
        positiveNegativeTest: "",
      });
      const rules = computed(()=>{
        return {
          positiveNegativeControl: {
            required: helpers.withMessage("Control vial result is required", required),
          },
          positiveNegativeTest: {
            required: helpers.withMessage("Test vial result is required", required),
          }
        }
      });
      let v$ = useValidate(rules, state);



    return {
      state,
      v$,

      // paramsData,
      props,
      // validationSchema,
      // submit,
      submitButtonRef,
      // positiveNegativeControl,
      // positiveNegativeTest,
      // controlResultError,
      // testResultError,
    };
  },
});
