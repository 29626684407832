<template>
  <div class="modal fade" id="program_package" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl;">
      <AddProgram
        ref="AddProgram"
        v-if="showAddProgram"
        @submitCompleted="programSubmitCompleted"
      />
    </div>
  </div>
  <div class="modal fade" id="edit_program" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl;">
      <EditProgram
        ref="EditProgram"
        v-if="showEditProgram"
        :defaultValues="editProgramQueue"
        @submitCompleted="editProgramSubmitCompleted"
      />
    </div>
  </div>
  <div class="modal fade" id="lookup_package" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl;">
      <EditStrerilizer
        ref="EditStrerilizer"
        v-if="showEditSterilizer"
        :defaultValues="editSterilizerQueue"
        @submitCompleted="editSubmitCompleted"
      />
    </div>
  </div>
  <div class="card mb-5 mb-xxl-8">
    <modal
      title="Please Confirm"
      okText="Yes"
      :cancelText="'No'"
      :show="showDeleteProgramConfirmation"
      @close="showDeleteProgramConfirmation = false"
      @no="showDeleteProgramConfirmation = false"
      @ok="deleteProgram"
      :footer="true"
    >
      <p>
        Are you sure you want to delete Program #
        {{ deleteProgramQueue.programCatalogId }}?
      </p>
      <p><b> This action cannot be undone. </b></p>
    </modal>

    <modal
      title="Please Confirm"
      okText="Yes"
      :cancelText="'No'"
      :show="showDeactivateConfirmation"
      @close="showDeactivateConfirmation = false"
      @no="showDeactivateConfirmation = false"
      @ok="deactivateSterilizer"
      :footer="true"
    >
      <p>
        Are you sure you want to deactivate the
        {{ sterilizerInfo.make }} Sterilizer?
      </p>
    </modal>
    <div class="card-body pt-9 pb-0">
      <router-link to="/settings/sterilizers"
        ><i class="fas fa-arrow-left" style="color: inherit"></i> Back
      </router-link>
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <div class="me-7 mb-4">
          <div
            class="
              symbol symbol-100px symbol-lg-160px symbol-fixed
              position-relative
            "
          >
            <img
              style="object-fit: contain"
              :src="`${sterilizerInfo.imageUrl}`"
              onerror="this.src='/SterilizerDefaultImage.png';"
              alt="image"
            />
          </div>
        </div>
        <div class="flex-grow-1">
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >{{ sterilizerInfo.make }}</a
                >
                <div class="px-3">
                  <button
                    class="btn btn-primary mr-3"
                    type="button"
                    @click="editButtonClicked()"
                  >
                    Edit Sterilizer
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <button
                  class="btn btn-danger"
                  type="button"
                  @click="deactivateButtonClicked()"
                >
                  Deactivate Sterilizer
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap flex-stack">
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <div class="d-flex flex-wrap">
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-100px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Title-->
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bolder">
                      {{ sterilizerInfo.model }}
                    </div>
                  </div>
                  <!--end::cycle-->
                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Model</div>
                  <!--end::Label-->
                </div>
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-100px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Title-->
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bolder">
                      {{ sterilizerInfo.isPreVacuum ? "Yes" : "No" }}
                    </div>
                  </div>
                  <!--end::cycle-->
                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Pre-Vacuum</div>
                  <!--end::Label-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <!--begin::Card-->
  <a class="card mt-4 mb-5 mb-xxl-8 p-5 h-50">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-9">
      <!--begin::Card Title-->
      <!--begin::Name-->
      <div class="col-9">
        <div class="fs-2 fw-bolder text-dark">Programs</div>
      </div>
      <div class="col-3" style="display: flex; justify-content: right">
        <a
          @click="addProgram()"
          class="btn btn-sm m-1 btn-light-primary"
          style="
            font-size: 14px !important;
            box-shadow: 4px 4px 5px -3px grey !important;
          "
        >
          <i class="fas fa-plus"></i>Add Program
        </a>
      </div>
      <!--end::Name-->
      <!--end::Car Title-->
    </div>
    <!--end:: Card header-->

    <!--begin:: Card body-->
    <div class="card-body p-9 pt-0">
      <div class="py-5 row" style="height: 100%">
        <ag-grid-vue
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          @grid-ready="onGridReady"
          @gridSizeChanged="gridSizeChanged"
          style="width: 100%; height: 100%"
          class="ag-theme-balham"
        >
        </ag-grid-vue>
      </div>
    </div>
    <!--end:: Card body-->
  </a>
  <!--end::Card-->
</template>
<script>
import { defineComponent, ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { useRoute } from "vue-router";
import AddSterilizer from "@/components/settings/Sterilizers/AddSterilizer.vue";
import BtnCellRederer from "@/components/settings/BtnCellRederer.vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import InlineSvg from "vue-inline-svg";
import ApiService from "@/core/services/ApiService";
import Modal from "@/components/Modal.vue";
import AddProgram from "@/components/AddProgram.vue";
import EditProgram from "@/components/EditProgram.vue";
import EditStrerilizer from "@/components/settings/EditSterilizer.vue";
import * as bootstrap from "bootstrap";
export default defineComponent({
  components: {
    AgGridVue,
    BtnCellRederer,
    InlineSvg,
    Modal,
    AddProgram,
    EditProgram,
    EditStrerilizer,
  },
  props: {
    selectedSterilizer: String,
  },

  data() {
    return {
      changeSterInfo: true,
      sterilizerInfoObject: this.$route.params.selectedSterilizer,
      columnDefs: null,
      rowData: null,
      defaultColDef: null,
      api: null,
      columnApi: null,
      editSterilizerModal: false,
      programModel: false,
      editProgramModal: false,
      showEditProgram: false,
      showDeactivateConfirmation: false,
      showDeleteProgramConfirmation: false,
      showAddProgram: false,
      showEditSterilizer: false,
      deleteProgramQueue: {},
      editSterilizerQueue: {},
      editProgramQueue: {},
    };
  },
  mounted() {
    var programModalElement = document.getElementById("program_package");
    this.programModel = new bootstrap.Modal(programModalElement);
    if (programModalElement) {
      var selfModal = this;
      programModalElement.addEventListener("hidden.bs.modal", function (event) {
        selfModal.showAddProgram = false;
      });
    }

    var editSterilizerModalElement = document.getElementById("lookup_package");
    this.editSterilizerModal = new bootstrap.Modal(editSterilizerModalElement);
    if (editSterilizerModalElement) {
      var selfModal = this;
      editSterilizerModalElement.addEventListener(
        "hidden.bs.modal",
        function (event) {
          selfModal.showEditSterilizer = false;
        }
      );
    }

    var editProgramModalElement = document.getElementById("edit_program");
    this.editProgramModal = new bootstrap.Modal(editProgramModalElement);
    if (editProgramModalElement) {
      var selfModal = this;
      editProgramModalElement.addEventListener(
        "hidden.bs.modal",
        function (event) {
          selfModal.showEditProgram = false;
        }
      );
    }
    this.refreshTable();
  },
  methods: {
    addProgram() {
      let shouldDoSomething = this.programModel !== null;
      if (shouldDoSomething) {
        this.programModel.show();
      }

      this.showAddProgram = true;
    },
    onSubmitCompleted() {
      if (this.showAddProgram) {
        this.programModel.hide();
        this.showAddProgram = false;
      }
    },

    async editSubmitCompleted(payload) {
      this.showEditSterilizer = false;
      this.editSterilizerModal.hide();
      if (payload != null) {
        payload.sterilizerCatalogId = this.sterilizerInfo.sterilizerCatalogId;
        try {
          console.log("payload", payload);
          await ApiService.postData("Sterilizer/catalog", payload, {});
          this.$router.push({ path: `/settings/sterilizers` });
        } catch (ex) {
          alert("There has been an error");
          return;
        }
      }
    },
    async editProgramSubmitCompleted(payload) {
      this.showEditProgram = false;
      this.editProgramModal.hide();
      if (payload != null) {
        payload.sterilizerCatalogId = this.sterilizerInfo.sterilizerCatalogId;

        try {
          console.log("payload", payload);
          await ApiService.postData("Sterilizer/catalog/program", payload, {});
          this.refreshTable();
        } catch (ex) {
          alert("There has been an error");
          return;
        }
      }
    },

    async programSubmitCompleted(program) {
      this.programModel.hide();
      this.showAddProgram = false;
      program.sterilizerCatalogId = this.sterilizerInfo.sterilizerCatalogId;
      console.log(program);
      try {
        await ApiService.postData("Sterilizer/catalog/program", program, {});
        this.refreshTable();
      } catch (ex) {
        alert(
          "An error updating the database has occurred. Please contact support."
        );
      }
    },
    editProgramButtonClicked(programInfo) {
      this.editProgramQueue = {
        programCatalogId: programInfo.programCatalogId,
        name: programInfo.name,
        needsParamVerification: programInfo.needsParamVerification,
        isBD: programInfo.isBD,
        holdingTimeInSeconds: programInfo.holdingTimeInSeconds,
        dryingTimeInSeconds: programInfo.dryingTimeInSeconds,
        tempInC: programInfo.tempInC,
        pressure: programInfo.pressure,
        maxWeightInkg: programInfo.maxWeightInkg,
      };
      let shouldDoSomething = this.editSterilizerModal !== null;
      if (shouldDoSomething) {
        this.editProgramModal.show();
      }

      this.showEditProgram = true;
    },
    editButtonClicked() {
      this.editSterilizerQueue = {
        make: this.sterilizerInfo.make,
        model: this.sterilizerInfo.model,
        manufacturer: this.sterilizerInfo.manufacturer,
        isPreVacuum: this.sterilizerInfo.isPreVacuum == "True" ? true : false,
      };
      let shouldDoSomething = this.editSterilizerModal !== null;
      if (shouldDoSomething) {
        this.editSterilizerModal.show();
      }

      this.showEditSterilizer = true;
    },

    deleteProgramClicked(sterilizerId, programId) {
      this.deleteProgramQueue = {
        sterilizerCatalogId: sterilizerId,
        programCatalogId: programId,
      };
      this.showDeleteProgramConfirmation = true;
    },
    deactivateButtonClicked() {
      this.showDeactivateConfirmation = true;
    },

    async deleteProgram() {
      console.log(this.deleteProgramQueue);
      try {
        let res = await ApiService.delete(
          "/Sterilizer/catalog/" +
            this.deleteProgramQueue.sterilizerCatalogId +
            "/delete/" +
            this.deleteProgramQueue.programCatalogId
        );
        this.showDeleteProgramConfirmation = false;
        this.refreshTable();
      } catch (exception) {
        console.warn(exception);
      }
    },
    async deactivateSterilizer() {
      try {
        let res = await ApiService.postData(
          "/Sterilizer/catalog/" +
            this.sterilizerInfo.sterilizerCatalogId +"/deactivate/"
        );
        if (res.status === 200) {
          this.$router.push({ path: `/settings/sterilizers` });
        }
      } catch (exception) {
        console.warn(exception);
      }
    },
    onGridReady(params) {
      this.api = params.api;
      this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
    async refreshTable() {
      let self = this;
      this.defaultColDef = {
        resizable: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
      };

      this.columnDefs = [
        {
          field: "name",
          headerName: "Name",
          sortable: true,
          filter: true,
        },
        {
          field: "isBD",
          headerName: "Mode",
          filter: true,
          sortable: true,
          // cellRendererFramework: CheckBoxCellRenderer,
          // maxWidth: "120",
        },
        {
          field: "holdingTimeInSeconds",
          sortable: true,
          filter: "agNumberColumnFilter",
          headerName: "Holding Time (s)",
        },
        {
          field: "dryingTimeInSeconds",
          sortable: true,
          filter: "agNumberColumnFilter",
          headerName: "Drying Time (s)",
        },

        {
          field: "tempInC",
          sortable: true,
          filter: "agNumberColumnFilter",
          headerName: "Temperature (C)",
        },
        {
          field: "pressure",
          sortable: true,
          filter: "agNumberColumnFilter",
          headerName: "Pressure",
        },
        {
          field: "maxWeightInkg",
          sortable: true,
          filter: "agNumberColumnFilter",
          headerName: "Max Weight (kg)",
        },
        {
          field: "edit",
          headerName: "Actions",
          sortable: false,
          filter: false,
          resizable: true,
          cellRendererFramework: BtnCellRederer,
          maxWidth: "100",
          cellRendererParams: {
            clicked: function (selectedData) {
              console.log("edit program", selectedData);
              self.editProgramButtonClicked(selectedData);
            },
            deleteClicked: function (selectedData) {
              console.log("clicked delete", selectedData);
              self.deleteProgramClicked(
                selectedData.sterilizerCatalogId,
                selectedData.programCatalogId
              );
            },
          },
        },
      ];
      try {
        const x = await ApiService.get(
          `Sterilizer/catalog/${this.sterilizerInfo.sterilizerCatalogId}/programs`
        );
        const allProgs = x.data;
        console.log(allProgs);
        for (let i = 0; i < allProgs.length; i++) {
          if (allProgs[i].isBD !== null) {
            if (allProgs[i].isBD) allProgs[i].isBD = "Bowie Dick";
            else if (allProgs[i].needsParamVerification)
              allProgs[i].isBD = "Load";
            else allProgs[i].isBD = "Other";
          }
          allProgs[i].edit = allProgs[i];
        }
        this.rowData = allProgs;
        console.log("summary ", allProgs);
      } catch (error) {
        console.error(error);
      }
    },

    showSterilizerInfo(e) {
      this.sterilizerInfo.classList.value =
        "active router-link-exact-active nav-link text-active-primary me-6 ";
      this.sterilizerPrograms.classList.value =
        "nav-link text-active-primary me-6";
      this.changeSterInfo = true;
      // clicker.remove
    },
    showSterilizerPrograms(e) {
      this.sterilizerPrograms.classList.value =
        "active router-link-exact-active nav-link text-active-primary me-6 ";
      this.sterilizerInfo.classList.value = "nav-link text-active-primary me-6";
      this.changeSterInfo = false;
    },
  },
  setup(props, context) {
    setCurrentPageTitle("");
    const route = useRoute();
    const store = useStore();
    const initialUser = store.getters.currentUser;
    let sterilizerInfo = JSON.parse(route.params.selectedSterilizer);
    console.log("passed vvalue", route.params.selectedSterilizer);
    return {
      initialUser,
      sterilizerInfo,
    };
  },
});
</script>