<template>
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="testModal">
    <div class=" modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Ultrasonic Foil Test` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <add-foil-test
          :initialUser="initialUser"
          @completed="foilTestCompleted"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!-- start filter modal -->
  <div
    class="modal fade"
    id="FoilTestRecords"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl;">
      <DownloadRecords
        ref="downloadRecords"
        v-if="showDownloadRecords"
        @submitCompleted="onSubmitCompleted"
        :printMethod="method"
      />
    </div>
  </div>
  <!-- end filter modal -->

  <!--end::Modal - New Card-->

  <div :class="`card `" style="width:100%;height:100%;">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1"
          >Ultra Sonic Bath Tests</span
        >
      </h3>

      <div
        class="card-toolbar mr-3 "
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
      >
      <a
              v-if="isFilterEnabled"
              @click="downloadCustom()"
              class="btn btn-sm btn-light-primary button-font-shadow shadow"
            >
              <i class="fas fa-file-signature"></i>Filtered Report
            </a>
        <a
          title="Click to generate a report"
          @click="downloadPdf"
          class="btn btn-sm btn-light-primary add-user mx-5 button-font-shadow shadow"
        >
          <span class="svg-icon svg-icon-3">
            <i class="fas fa-file-pdf"></i>
          </span>
          Generate Report
        </a>
        <!-- <a
        title="Click to preform a new test"
          @click="newTest"
          class="btn btn-sm btn-light-primary add-user"
          style="font-size: 14px !important; box-shadow: 4px 4px 5px -3px grey !important;"
        >
          <span class="svg-icon svg-icon-3">
            <i class="fa fa-flask" aria-hidden="true"></i>
          </span>
          New Test
        </a> -->
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 row">
      <!--begin::Table container-->
      <div class="table-responsive row">
        <!--begin::Table-->
        <div class="row">
          <ag-grid-vue
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @grid-ready="onGridReady"
            @gridSizeChanged="gridSizeChanged"
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
          >
          </ag-grid-vue>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>

  <!--end::Tables Widget 9-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import AddFoilTest from "@/components/MifuTest/AddFoilTest.vue";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  isToday,
  isYesterday,
  isThisWeek,
  subWeeks,
  isSameWeek,
  isThisMonth,
  subMonths,
  isSameMonth,
  isThisYear,
  subYears,
  isSameYear,
  format,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import DownloadRecords from "@/components/Reports/DownloadRecords.vue";
export default defineComponent({
  name: "FoilTestRecords",
  components: {
    // CreateEditUser,
    AgGridVue,
    AddFoilTest,
    DownloadRecords,
  },
  data() {
    return {
      modal: null,
      isFilterEnabled: false,
      showDownloadRecords: false,
      method: "Foil Test",
      //ag-grid
      defaultColDef: null,
      columnDefs: null,
      rowData: [],
      gridApi: null,
      columnApi: null,
      api: null,
    };
  },
  async beforeMount() {
    this.refreshTable();
  },

  async mounted() {
    this.refreshTable();
    var modalElement = document.getElementById("FoilTestRecords");
    this.modal = new bootstrap.Modal(modalElement);
  },
  methods: {
    createCustomCsv(filteredArray){
      const csvString = [
      [
        "Name",
        "Model",
        "Verified By",
        "Time",
        "Result",
        "Notes"
      ],
      ...filteredArray.map(item => [
        item.name,
        item.model,
        item.userName,
        item.verifiedBy,
        item.passed,
        item.notes
      ])
    ]
    .map(e => e.join(",")) 
    .join("\n");

    return csvString;
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], {type: "text/csv"});

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    formatDate(params){
      if(params.value){
        return format(new Date(params.value), "yyyy-MM-dd HH:mm")
      }else if(params){
        return format(new Date(params), "yyyy-MM-dd HH:mm")
      }
    },
    downloadPdf() {
      const shouldDoSomething = this.modal !== null;
      if (shouldDoSomething) {
        this.modal.show();
      }

      this.showDownloadRecords = true;
    },
    foilTestCompleted() {
      this.modal.hide();
      this.refreshTable();
    },
    getSelectionString(userSelection) {
      let today = new Date(Date.now());
      let currentTime = format(new Date(Date.now()), "h:mm a");
      if (userSelection === "All")
        return `All records (until ${format(
          today,
          "MMMM d, yyyy"
        )} at ${currentTime})`;
      if (userSelection === "Today")
        return `${format(today, "MMMM d, yyyy")} (until ${currentTime})`;
      if (userSelection === "Yesterday")
        return `${format(endOfYesterday(), "MMMM d, yyyy")}`;
      if (userSelection === "This Week")
        return `${format(startOfWeek(today), "MMMM d, yyyy")} - ${format(
          today,
          "MMMM d, yyyy"
        )} (until ${currentTime})`;
      if (userSelection === "Last Week")
        return `${format(
          startOfWeek(subWeeks(today, 1)),
          "MMMM d, yyyy"
        )} - ${format(endOfWeek(subWeeks(today, 1)), "MMMM d, yyyy")}`;
      if (userSelection === "This Month")
        return ` ${format(
          today,
          "MMMM 1, yyyy - MMMM d, yyyy"
        )} (until ${currentTime})`;
      if (userSelection === "Last Month")
        return `${format(subMonths(today, 1), "MMMM yyyy")}`;
      if (userSelection === "This Year")
        return `January 1, ${format(today, "yyyy")} - ${format(
          today,
          "MMMM d, yyyy"
        )} (until ${currentTime})`;
      if (userSelection === "Last Year")
        return `January 1, ${format(
          subYears(today, 1),
          "yyyy"
        )} - December 31, ${format(subYears(today, 1), "yyyy")}`;
    },
    onSubmitCompleted(programSelection , type) {
      if (this.modal) {
        this.modal.hide();
      }
      this.showVerifyVial = false;
      if (programSelection !== null && type !== null) {
          const userSelection = programSelection.value;
          let filtered = null;
            if (userSelection === "All") filtered = [...this.rowData];
            else {
              filtered = this.getDocFilter(userSelection);
            }
        if(type.value === 'pdf'){
          //const doc = new jsPDF();
          const doc = new jsPDF("landscape", "mm", "letter");
          doc.addImage("/Logo.png", "PNG", 12, 8, 15, 15);
          doc.text("Foil Tests Report", 30, 18);
          doc.text(this.currentClinicName, 265, 18, "right");
          //doc.text(this.clinicName, 196, 18, "right");
          doc.setTextColor("#777");
          doc.setFontSize(10);
          doc.text(this.getSelectionString(userSelection), 30, 23);

          const table = {
            styles: { fontSize: 7 },
            columns: this.columnDefs.map((c) => ({
              header: c.headerName,
              dataKey:  c.field === "verifiedBy" ? 'timePrint' :c.field,
            })),
            body: filtered,
            startY: 28,
          };
          doc.autoTable(table);

          if (!filtered.length) {
            doc.setFont(undefined, "bold");
            doc.setTextColor("#B22222");
            doc.setFontSize(14);
            doc.text("No Records Found", 15, 50);
          }

          doc.save("FoilTestsRecords.pdf");
        }else{
          var csv = this.createCustomCsv(filtered);
          this.downloadCSVFile(csv,"FoilTestsRecords.csv");
        }
        this.showDownloadRecords = false;
      }
    },
    getDocFilter(userSelection) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let printData = [...this.rowData];
      printData = printData.filter(function(value, index, arr) {
        var logDate = new Date(value.verifiedBy);
        if (userSelection === "Today") return isToday(logDate);
        if (userSelection === "Yesterday") return isYesterday(logDate);
        if (userSelection === "This Week") return isThisWeek(logDate);
        if (userSelection === "Last Week")
          return isSameWeek(logDate, subWeeks(today, 1));
        if (userSelection === "This Month") return isThisMonth(logDate);
        if (userSelection === "Last Month")
          return isSameMonth(logDate, subMonths(today, 1));
        if (userSelection === "This Year") return isThisYear(logDate);
        if (userSelection === "Last Year")
          return isSameYear(logDate, subYears(today, 1));
      });
      return printData;
    },
    print() {
      //const doc = new jsPDF();
      const doc = new jsPDF("landscape", "mm", "letter");
      doc.addImage("/Logo.png", "PNG", 12, 8, 15, 15);
      doc.text("Foil Test Report", 30, 18);
      doc.text(this.currentClinicName, 265, 18, "right");
      doc.setTextColor("#777");
      doc.setFontSize(10);
      doc.text(format(new Date(Date.now()), "dd/MM/yyyy"), 30, 23);
      let filtered = null;
      filtered = [...this.rowData];

      let table = {
        styles: { fontSize: 7 },
        columns: this.columnDefs.map((c) => ({
          header: c.headerName,
          dataKey: c.field,
        })),
        body: filtered,
        startY: 28,
      };
      doc.autoTable(table);

      if (!filtered.length) {
        doc.setFont(undefined, "bold");
        doc.setTextColor("#B22222");
        doc.setFontSize(14);
        doc.text("No Records Found", 15, 50);
      }
      doc.save("SterilizationReport.pdf");
    },
    newTest() {
      this.modal.show();
    },
    async refreshTable() {
      let foiltests = await ApiService.get("/Cleaner/1/mifutest")
      console.log(foiltests);
      this.defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
      };

      this.columnDefs = [
        { field: "name", filter: true, headerName: "Name" },
        { field: "model", filter: true, headerName: "Model" },
        { field: "userName", filter: true, headerName: "Verified By" },
        { field: "verifiedBy", filter: true, headerName: "Time", valueFormatter: this.formatDate },
        { field: "passed", filter: true, headerName: "Result" },
        { field: "notes", filter: true, headerName: "Notes" },
      ];
      foiltests = foiltests.data
      for (let i = 0; i < foiltests.length; i++) {
        foiltests[i].verifiedBy = this.formatDate(foiltests[i].verifiedBy);
        foiltests[i].timePrint = this.formatDate(foiltests[i].verifiedBy);
        foiltests[i].passed = foiltests[i].passed ? 'passed' : '';
      }

      this.rowData = foiltests;
      this.gridSizeChanged();
    },
    resultsFormatter(params) {
      if (params.value === null) {
        return null;
      } else {
        return params.value == true ? 'passed' : '';
      }
    },
    downloadCustom() {
      let customFiltered = [];
      this.api.forEachNodeAfterFilter((node) => customFiltered.push(node.data));

      //const doc = new jsPDF();
      const doc = new jsPDF("landscape","mm","letter");
      doc.addImage("/Logo.png", "PNG", 12, 8, 15, 15);
      doc.text("Foil Test Report", 30, 18);
      doc.text(this.currentClinicName, 265, 18, "right");
      //doc.text(this.clinicName, 196, 18, "right");
      doc.setTextColor("#777");
      doc.setFontSize(10);
      doc.text("Custom Query", 30, 23);

      let table = {
        styles: { fontSize: 7 },
        columns: this.columnDefs.map((c) => ({
          header: c.headerName,
          dataKey: c.field === "verifiedBy" ? 'timePrint' :c.field,
        })),
        body: customFiltered,
        startY: 28,
      };
      doc.autoTable(table);

      if (!customFiltered.length) {
        doc.setFont(undefined, "bold");
        doc.setTextColor("#B22222");
        doc.setFontSize(14);
        doc.text("No Records Found", 15, 50);
      }
      doc.save("FoilTestReport.pdf");
    },
    onGridReady(params) {
      this.api = params.api;
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      //this.api.sizeColumnsToFit();
      params.api.addGlobalListener(() => {
        if (params.api.isColumnFilterPresent()) this.isFilterEnabled = true;
        else this.isFilterEnabled = false;
      });
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
  },
  setup() {
    setCurrentPageTitle("Foil Test");
    const store = useStore();
    const initialUser = store.getters.currentUser;
    const currentClinicName = initialUser.currentClinic.name;

    return {
      initialUser,
      currentClinicName,
    };
  },
});
</script>

<style lang="scss" scoped>
.mr-3 {
  margin-right: 3rem !important;
}
</style>
