<template>
  <div class="card" style="width: 100%; height: 100%;">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Packages</span>
      </h3>
      <h3 class="card-title align-items-end flex-column">
        <div
          class="card-toolbar card-label fw-bolder fs-3 mb-1"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a package"
        >
          <a @click="addPackage" class="btn btn-sm btn-light-primary button-font-shadow shadow" style="margin-right: -1rem">
            <i class="fas fa-box-open"></i>
            New Package
          </a>
        </div>
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 row d-flex justify-content-center">
      <!--begin::Table container-->
      <ag-grid-vue
        :defaultColDef="defaultColDef"
        :columnDefs="columnDefs"
        :rowData="rowData"
        @first-data-rendered="gridSizeChanged"
        @grid-ready="onGridReady"
        @gridSizeChanged="gridSizeChanged"
        style="width: 100%; height: 99%; "
        class="ag-theme-balham"
        id="packagesGrid"
      >
      </ag-grid-vue>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>

  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="editPackage_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Package</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <EditPackage
          v-if="editPackageModalObject && packages"
          v-model:selectedPackage="editPackageModalObject"
          :packages="packages"
          @closeEditPackageForm="closeEditPackageForm"
          @editedPackageSuccessfully="editedPackageSuccessfully"
        />
      </div>
    </div>
  </div>
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="addPackage_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Package</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <EditPackage
          v-if="addPackageVisible && packages"
          :selectedPackage="null"
          :packages="packages"
          @closeEditPackageForm="closeEditPackageForm"
          @editedPackageSuccessfully="editedPackageSuccessfully"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!-- start delete package confirmation -->
  <div
    class="modal fade"
    tabindex="-1"
    id="deletePackage_modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"  v-if="packageToDelete">Delete Package {{ packageToDelete.name }}</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <p v-if="packageToDelete">
            Are you sure you want to delete package {{ packageToDelete.name }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancel
          </button>
          <button
           v-if="packageToDelete"
            type="button"
            class="btn btn-danger"
            @click="deletePackage(packageToDelete.packageId)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end delete package confirmation -->
  <!--end::Tables Widget 9-->
</template>

<script>
import * as bootstrap from "bootstrap";
import EditPackage from "./EditPackage.vue";
// import AddPackage from "./AddPackage.vue";
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent, createVNode } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import DeleteBtnCellRenderer from "./UserManagmentBtnCellRenderer.vue";
import BtnCellRederer from "@/components/settings/BtnCellRederer.vue";
import CheckBoxCellRenderer from "@/components/settings/CheckBoxCellRenderer.vue";
export default defineComponent({
  name: "Packages",
  components: {
    AgGridVue,
    BtnCellRederer,
    DeleteBtnCellRenderer,
    EditPackage,
    // AddPackage,
    CheckBoxCellRenderer,
  },
  props: {},
  data() {
    return {
      addModal: null,
      editModal: null,
      deleteModal: null,
      packageToDelete: null,

      packages: null,
      defaultColDef: null,
      columnDefs: null,
      rowData: null,
      api: null,
      columnApi: null,
      addPackageVisible: false,
      editPackageVisible: false,
      editPackageModalObject: null,
    };
  },

  mounted() {
    setCurrentPageTitle("Packages");
    // addPackage_modal
    let modalElement = document.getElementById("addPackage_modal");
    this.addModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function(event) {});
    }

    modalElement = document.getElementById("editPackage_modal");
    this.editModal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function(event) {});
    }
  },
  async beforeMount() {
    this.refreshTable();
  },

  methods: {
    confirmPackageDeletion(data) {
      this.packageToDelete = data;
      let modalElement = document.getElementById("deletePackage_modal");
      if (modalElement) {
        modalElement.addEventListener("hidden.bs.modal", function(event) {});
      }
      this.deleteModal = new bootstrap.Modal(modalElement);
      this.deleteModal.show();
    },
    onGridReady(params) {
      this.api = params.api;
      this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
    // triggerGridSize
    async refreshTable() {
      this.defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
      };

      const packagesData = await ApiService.get("Package");
      let finalPckgData = packagesData.data;
      this.packages = finalPckgData;
      console.log(finalPckgData);
      for (let index = 0; index < finalPckgData.length; index++) {
        const pckg = finalPckgData[index];
        finalPckgData[index].edit = pckg;
        // finalPckgData[index].includeBI = finalPckgData[index].includeBI ? 'yes' : '' 
      }
      let self = this;
      this.columnDefs = [
        {
          field: "name",
          headerName: "Package List",
          filter: true,
          resizable: true,
        },
        {
          field: "includeBI",
          headerName: "Include BI",
          resizable: true,
          valueFormatter: this.includeBiFormatter
        },

        {
          field: "edit",
          headerName: "Actions",
          sortable: false,
          filter: false,
          cellRendererFramework: BtnCellRederer,
          resizable: true,
          maxWidth: "100",
          cellRendererParams: {
            clicked: function(selectedData) {
              self.editPackageModalObject = null;
              self.changeSelectedPackage(selectedData);
              self.editPackageVisible = true;
            },
            deleteClicked: function(selectedData) {
              console.log(selectedData);
              self.confirmPackageDeletion(selectedData);
            },
          },
        },
      ];
      this.rowData = finalPckgData;
    },
    async deletePackage(id) {
      let res = await ApiService.delete(`/Package/${id}`);
      if (res.status === 200) {
        this.refreshTable();
        this.deleteModal.hide();
      } else {
        alert("Something wen wrong while deleting the package.");
      }
    },
    includeBiFormatter(params){
      return params.value ? 'yes': '';
    },
    editedPackageSuccessfully() {
      console.log("came here");
      this.closeAddPackageForm();
      this.closeEditPackageForm();
      this.refreshTable();
    },
    addPackage() {
      this.addModal.show();
      this.addPackageVisible = true;
    },
    changeSelectedPackage(pckg) {
      this.editModal.show();
      console.log(pckg);
      this.editPackageModalObject = pckg;
    },
    closeEditPackageForm() {
      this.editModal.hide();
      this.editPackageVisible = false;
    },
    closeAddPackageForm() {
      this.addModal.hide();
      this.addPackageVisible = false;
    },
  },
});
</script>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
