
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Avatar from "@/components/Avatar.vue";
import { format } from "date-fns";
import TestCard from "@/components/TestCard.vue";
import NoteCard from "@/components/NoteCard.vue";
import PackagesCard from "@/components/PackagesCard.vue";
import ForeignAvatar from "@/components/ForeignAvatar.vue";
import { LoadingOverlayComponent } from "ag-grid-community/dist/lib/rendering/overlays/loadingOverlayComponent";
export default defineComponent({
  components: { Avatar, TestCard, NoteCard, PackagesCard, ForeignAvatar },
  data() {
    return {
      allSelected: false,
      loadInfo: {},
      loadPacks: [],
      loader: [],
      certifier: [],
      modal1: false,
      loadExists: true,
      mounted: false,
      showTestPannel: false,
      showBD: false,
      checkboxes: new Array<any>(),
    };
  },
  beforeMount() {
    this.checkboxes = new Array<any>();
    this.loadData();
    console.log("got here");
  },
  methods: {
    print(event) {
      if (event) {
        event.preventDefault();
      }
      const prtHtml = document.getElementById("printDiv")?.innerHTML;

      let htmlString = `${prtHtml}`;

      var oHideFrame = document.createElement("iframe") as HTMLIFrameElement;
      oHideFrame.onload = () => {
        oHideFrame.contentWindow!.focus();
        oHideFrame.contentWindow!.print();
        oHideFrame.contentWindow!.onafterprint = () => {
          document.body.removeChild(oHideFrame);
        };
      };
      oHideFrame.style.position = "fixed";
      oHideFrame.style.right = "0";
      oHideFrame.style.bottom = "0";
      oHideFrame.style.width = "0";
      oHideFrame.style.height = "0";
      oHideFrame.style.border = "0";
      oHideFrame.srcdoc = htmlString;
      document.body.appendChild(oHideFrame);
    },

    getdate() {
      return format(new Date(), "MMM d hh:mm a");
    },
    checkboxesLength() {
      return this.checkboxes.filter(Boolean).length;
    },
    isAnySelected() {
      if (this.checkboxesLength() >= 1) return this.checkboxesLength();
      return;
    },
    selectOne(index) {
      if (this.checkboxes[index] && this.allSelected) this.allSelected = false;
      else if (
        !this.checkboxes[index] &&
        this.checkboxesLength() == this.loadPacks.length - 1
      )
        this.allSelected = true;
    },
    uncheckAll() {
      this.allSelected = false;
    },
    selectAll() {
      if (!this.allSelected) {
        for (var key in this.loadPacks) this.checkboxes[key] = true;
      } else {
        for (var key in this.loadPacks) this.checkboxes[key] = false;
      }
    },
    getStatus(packageStatus) {
      if (packageStatus != null)
        if (packageStatus == true) return "Verified";
        else return "Failed";
      return "Unverified";
    },
    reloadTests() {
      this.loadData();
      return this.showTestPannel;
    },
    getBDParams(didBDTestPass) {
      return [this.showBD, didBDTestPass];
    },
    getBiParams(needsParamVerification, biInfo) {
      if (needsParamVerification && biInfo != null)
        return [true, biInfo.controlVialResult, biInfo.testVialResult];
      return [false, null, null];
    },
    isStringEmpty(notes)
    {
      return notes !== null && notes !== ""
    },
    passResult(result) {
      if (result !== null) return result;
      return 2;
    },
    dateParser(date) {
      return date.split("T")[0];
    },
    async loadData() {
      console.log(this.loadID);
      try {
        let loadInfo = (await ApiService.get(`Load/${this.loadID}`)).data;
        this.loadInfo = loadInfo;
        this.loadPacks = loadInfo.packs;
        this.loader = loadInfo.loader;
        this.certifier = loadInfo.certifier;
        console.log("fIRST ", this.loader);
        this.loadExists = true;
        /*         if (loadInfo.isBD) {
          this.showBD = true;
          this.showTestPannel = true;
          return;
        }

        if (!loadInfo.needsParamVerification) {
          return;
        }

        this.showTestPannel = true; */
      } catch (e: any) {
        if (e.response) if (e.response.status === 404) this.loadExists = false;
        return;
      }
    },
  },

  watch: {
    "$route.query.search": function () {
      let newLoadNumber = this.$route.query.search;
      if (newLoadNumber) {
        this.loadID = newLoadNumber;
        this.loadData();
      }
    },
  },

  setup() {
    const route = useRoute();
    const loadID = route.query.search;
    setCurrentPageTitle("Search By Load Number");
    //console.log("Packs ",list.packs)
    //setCurrentPageTitle(`Load #${loadID}`);
    return {
      loadID,
    };
  },
});
