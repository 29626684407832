
import { defineComponent, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Todo from "@/components/Todo.vue";
import Sterilizers from "@/components/Sterilizers.vue";
import MifuTestReminder from "@/components/MifuTest/MifuTestReminder.vue"
import ApiService from '@/core/services/ApiService';
export default defineComponent({
  name: "kt-widget-9",
  components: { Todo, Sterilizers, MifuTestReminder },
  props: {
    widgetClasses: String,
  },  
  setup() {
    setCurrentPageTitle("Dashboard");
    const checked = ref(false);
    const todoComponent = ref();
    const sterilizerComponent = ref();
   let showFoilReminders = ref(true);

    const onSubmitCompleted = () => {
      todoComponent.value.loadData()
    }
    const onIncubatorSubmitCompleted = () => {            
      sterilizerComponent.value.loadData()
    }

    return {
      checked,
      showFoilReminders,
      todoComponent,
      sterilizerComponent,
      onSubmitCompleted,
      onIncubatorSubmitCompleted
    };
  },
});
