
import { defineComponent, reactive, computed, onUpdated } from "vue";
import { Models } from "@/Models/Models";
import { required, helpers } from "@vuelidate/validators";
import ApiService from "@/core/services/ApiService";
import useValidate from "@vuelidate/core";

export default defineComponent({
  props: {
    selectedUltrasonic: {
      type: Object,
      required: true,
    },
  },
  updated() {
    if (this.selectedUltrasonic == null && this.ultrasonicId) {
      this.ultrasonicId = null;
      this.state.cleanerType = 1;
      this.state.name = "";
      this.state.model = "";
      this.state.foilTestIntervalInDays = 1;
      this.state.inService = true;
    }
    if (
      this.selectedUltrasonic &&
      this.ultrasonicId != this.selectedUltrasonic.cleanerId
    ) {
      this.ultrasonicId = this.selectedUltrasonic.cleanerId;
      this.state.name = this.selectedUltrasonic.name;
      this.state.cleanerType = this.selectedUltrasonic.cleanerType;
      this.state.model = this.selectedUltrasonic.model;
      this.state.foilTestIntervalInDays =
        this.selectedUltrasonic.mifuTestIntervalInDays;
      this.state.inService = this.selectedUltrasonic.inService;
    }
  },
  methods: {
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let payload: any = this.state;
        console.log("payload", payload);
        //payload.cleanerType = 2;
        payload.mifuTestIntervalInDays = this.state.foilTestIntervalInDays;
        if (this.selectedUltrasonic) {
          console.log("got here");
          payload.cleanerId = this.selectedUltrasonic.cleanerId;
        }
        console.log(payload);

        try {
          let res = await ApiService.postData("/Cleaner", payload, {});
          if (res.status === 200) {
            console.log(res.data);
            this.$emit("success");
          }
        } catch (exception) {
          console.warn(exception);
        }
      }
    },
  },
  setup(props, context) {
    // let timeConvert = (daysValue)=>{
    //     let days = Math.floor(daysValue);
    //     let hours = Math.floor((((daysValue-days)*24) / 60 ));
    //     let minutes = Math.round((((daysValue-days)*24)-hours)/60)
    //     return {
    //         days: days, hours: hours, minutes: minutes
    //     }
    // };
    // let intervalTime:any = reactive({
    //   days: props.selectedUltrasonic ? timeConvert(props.selectedUltrasonic.foilTestIntervalInDays).days : 0,
    //   hours: props.selectedUltrasonic ? timeConvert(props.selectedUltrasonic.foilTestIntervalInDays).hours : 0,
    //   minutes: props.selectedUltrasonic ? timeConvert(props.selectedUltrasonic.foilTestIntervalInDays).minutes : 0,
    // });
    // let intervalInDays = computed(() => {
    //   return (
    //     parseFloat(`${intervalTime.days}`) +
    //     parseFloat(`${intervalTime.hours / 24}`) +
    //     parseFloat(`${(intervalTime.minutes * 60) / 24}`)
    //   );
    // });

    console.log(props.selectedUltrasonic);
    let ultrasonicId = props.selectedUltrasonic
      ? props.selectedUltrasonic.cleanerId
      : null;
    let state: Models.AddUltrasonicCleaner = reactive({
      cleanerType: props.selectedUltrasonic
        ? props.selectedUltrasonic.cleanerType
        : 1,
      name: props.selectedUltrasonic ? props.selectedUltrasonic.name : "",
      model: props.selectedUltrasonic ? props.selectedUltrasonic.model : "",
      foilTestIntervalInDays: props.selectedUltrasonic
        ? props.selectedUltrasonic.mifuTestIntervalInDays
        : 1,
      inService: props.selectedUltrasonic
        ? props.selectedUltrasonic.inService
        : true,
    });
    const rules = computed(() => {
      return {
        cleanerType: {
          required: helpers.withMessage("Cleaner type is required.", required),
        },
        name: {
          required: helpers.withMessage("Name is required.", required),
        },
        model: {
          required: helpers.withMessage("Model is required.", required),
        },
        foilTestIntervalInDays: {
          required: helpers.withMessage("Interval is required.", required),
        },
      };
    });
    let v$ = useValidate(rules, state);

    return {
      ultrasonicId,
      state,
      v$,
    };
  },
});
