<template>
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="testModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Setup a New Device` }}
          </h5>

          <!--begin::Close-->
          <div class="d-flex align-items-center">
            <avatar />
            <div
              class="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ultrasonicAddEditSuccess"
            >
              <span class="svg-icon svg-icon-2x"></span>
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </div>
          </div>

          <!--end::Close-->
        </div>
        <add-edit-device
          v-if="showAddEdit"
          :selectedUltrasonic="selectedUtlrasonic"
          @success="ultrasonicAddEditSuccess"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!-- start delete modal -->
  <div class="modal fade" tabindex="-1" id="deleteUltra_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="ultraToDelete">
            Delete Ultrasonic Cleaner {{ ultraToDelete.name }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <p v-if="ultraToDelete">
            Are you sure you want to delete {{ ultraToDelete.name }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancel
          </button>
          <button
            v-if="ultraToDelete"
            type="button"
            class="btn btn-danger"
            @click="deleteultra(ultraToDelete.cleanerId)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end delete modal -->

  <!--end::Modal - New Card-->

  <div class="card" style="width: 100%; height: 100%">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Devices</span>
      </h3>

      <div
        class="card-toolbar mr-3"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
      >
        <!-- <a
              title="Click to generate a report"
          @click="print"
          class="btn btn-sm btn-light-primary add-user mx-5"
          style="font-size: 14px !important; box-shadow: 4px 4px 5px -3px grey !important;"
        >
          <span class="svg-icon svg-icon-3">
            <i class="fas fa-file-pdf"></i>
          </span>
          Generate Report
        </a> -->
        <a
          title="Click to preform a new test"
          @click="newTest"
          class="btn btn-sm btn-light-primary add-user"
          style="
            font-size: 14px !important;
            box-shadow: 4px 4px 5px -3px grey !important;
          "
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="\media\icons\duotone\Electric\Washer.svg" />
            <!-- <i class="fa fa-flask" aria-hidden="true"></i>  -->
          </span>
          New Device
        </a>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 row">
      <!--begin::Table container-->
      <div class="table-responsive row">
        <!--begin::Table-->
        <div class="row">
          <ag-grid-vue
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @grid-ready="onGridReady"
            @gridSizeChanged="gridSizeChanged"
            style="width: 100%; height: 100%"
            class="ag-theme-balham"
          >
          </ag-grid-vue>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>

  <!--end::Tables Widget 9-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import AddEditDevice from "@/components/MifuTest/AddEditDevice.vue";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns/esm";
import Avatar from "@/components/Avatar.vue";
import BtnCellRederer from "@/components/settings/BtnCellRederer.vue";

export default defineComponent({
  name: "FoilTest",
  components: {
    // CreateEditUser,
    AgGridVue,
    AddEditDevice,
    Avatar,
  },
  data() {
    return {
      modal: null,
      selectedUtlrasonic: null,
      showAddEdit: false,
      deleteModal: null,
      ultraToDelete: null,
      //ag-grid
      defaultColDef: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      api: null,
    };
  },
  async beforeMount() {
    this.refreshTable();
  },

  async mounted() {
    this.refreshTable();
    let modalElement = document.getElementById("testModal");
    this.modal = new bootstrap.Modal(modalElement);
    this.modal._backdrop._config.clickCallback = this.ultrasonicAddEditSuccess();

    let deleteModalElement = document.getElementById("deleteUltra_modal");
    this.deleteModal = new bootstrap.Modal(deleteModalElement);
  },
  methods: {
    async deleteultra(ultrasonicCleanerId) {
      try {
        console.log(ultrasonicCleanerId);
        let res = await ApiService.delete(`/Cleaner/${ultrasonicCleanerId}`);
        if (res.status === 200) {
          this.deleteModal.hide();
          this.refreshTable();
        }
      } catch (e) {
        console.warn(e);
      }
    },
    prepareDeleteUltra(data) {
      this.ultraToDelete = data;
      this.deleteModal.show();
    },
    foilTestCompleted() {
      this.modal.hide();
      this.refreshTable();
    },
    ultrasonicAddEditSuccess() {
      this.modal.hide();
      this.showAddEdit = false;
      this.selectedUtlrasonic = null;
      this.refreshTable();
    },
    editUltrasonic(data) {
      this.showAddEdit = true;
      this.selectedUtlrasonic = data;
      this.modal.show();
    },
    print() {
      //const doc = new jsPDF();
      const doc = new jsPDF("landscape", "mm", "letter");
      doc.addImage("/Logo.png", "PNG", 12, 8, 15, 15);
      doc.text("Foil Test Report", 30, 18);
      doc.text(this.currentClinicName, 265, 18, "right");
      doc.setTextColor("#777");
      doc.setFontSize(10);
      doc.text(format(new Date(Date.now()), "dd/MM/yyyy"), 30, 23);
      let filtered = null;
      filtered = [...this.rowData];

      let table = {
        styles: { fontSize: 7 },
        columns: this.columnDefs.map((c) => ({
          header: c.headerName,
          dataKey: c.field,
        })),
        body: filtered,
        startY: 28,
      };
      doc.autoTable(table);

      if (!filtered.length) {
        doc.setFont(undefined, "bold");
        doc.setTextColor("#B22222");
        doc.setFontSize(14);
        doc.text("No Records Found", 15, 50);
      }
      doc.save("SterilizationReport.pdf");
    },
    newTest() {
      this.showAddEdit = true;
      this.selectedUtlrasonic=null;
      this.modal.show();
    },
    async refreshTable() {
      let self = this;
      this.defaultColDef = {
        resizable: true,
        sortable: true,
        filter: false,
        floatingFilter: false,
        suppressMenu: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
      };

      this.columnDefs = [
        { field: "device", filter: true, headerName: "Device" },
        { field: "model", filter: true, headerName: "Model" },
        { field: "name", filter: true, headerName: "Clinic Name (Alias)" },
        { field: "testType", filter: true, headerName: "Test" },
        {
          field: "mifuTestIntervalInDays",
          filter: true,
          headerName: "Interval (days)",
        },
        { field: "inService", filter: true, headerName: "In Service" },
        {
          field: "edit",
          headerName: "",
          resizable: true,
          cellRendererFramework: BtnCellRederer,
          maxWidth: "100",
          cellRendererParams: {
            clicked: function (selectedData) {
              console.log("edit clicked", selectedData);
              self.editUltrasonic(selectedData);
            },
            deleteClicked: function (selectedData) {
              console.log("clicked delete", selectedData);
              self.prepareDeleteUltra(selectedData);
            },
          },
        },
      ];
      //2
      let ultrasonicCleaners = await ApiService.get("/Cleaner");
      if (ultrasonicCleaners.status === 200) {
        ultrasonicCleaners = ultrasonicCleaners.data;
        for (const mifu of ultrasonicCleaners) {
          if (mifu.cleanerType == 1) {
            mifu.device = "Ultrasonic";
            mifu.testType = "Foil Test";
          } else {
            mifu.device = "Washer";
            mifu.testType = "Wash Test";
          }
        }
      }
      for (let i = 0; i < ultrasonicCleaners.length; i++) {
        ultrasonicCleaners[i].edit = ultrasonicCleaners[i];
      }
      const data = ultrasonicCleaners;
      this.rowData = data;
    },
    onGridReady(params) {
      this.api = params.api;
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
  },
  setup() {
    setCurrentPageTitle("Devices");
    const store = useStore();
    const initialUser = store.getters.currentUser;
    const currentClinicName = initialUser.currentClinic.name;

    return {
      initialUser,
      currentClinicName,
    };
  },
});
</script>

<style lang="scss" scoped>
.mr-3 {
  margin-right: 3rem !important;
}
</style>
