
import { useStore } from "vuex";

import useValidate from "@vuelidate/core";
import { required, helpers, maxLength } from "@vuelidate/validators";
import { defineComponent, reactive, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";

interface FormState {
  name: string;
  includeBI: boolean;
}

export default defineComponent({
  name: "EditPackage",
  props: {
    selectedPackage: {
      required: true,
    },
    packages: {
      required: true,
      type: Array,
    },
  },
  components: {},
  methods: {
    async submit() {
      console.log(this.state.name);
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.state.name) {
          let payload = {
            clinicId: this.initialUser.currentClinic.clinicId,
            name: this.state.name,
            includeBI: this.state.includeBI,
          };
          if (this.selectedPackage) {
            let currentPackage = this.selectedPackage as Models.Package;
            let editpckgPayload = {
              packageId: currentPackage.packageId,
              clinicId: currentPackage.clinicId,
              name: this.state.name,
              includeBI: this.state.includeBI,
            };
            payload = editpckgPayload;
          }
          console.log(payload);

          try {
            console.log("trying with: ", payload);
            let editPckg = await ApiService.postData("Package", payload, {});
            if (editPckg.status === 200) {
              this.$emit("editedPackageSuccessfully");
            }
            console.log(editPckg);
          } catch (err) {
            console.warn(err);
          }
        }
      }
    },
    discard() {
      console.log("discard");
      this.$emit("closeEditPackageForm");
    },
  },
  updated() {
    if (this.selectedPackage) {
      this.allpackages = this.packages.filter((value) => {
        let val = value as any;
        let selected = this.selectedPackage as any;
        return val.name != selected.name;
      });
    }
    let package_to_edit = this.selectedPackage as Models.Package;
    if (this.selectedPackage && package_to_edit.packageId != this.packageId) {
      this.allpackages = this.packages.filter((value) => {
        let val = value as any;
        let selected = this.selectedPackage as any;
        return val.name != selected.name;
      });
      this.packageId = package_to_edit.packageId;
      this.state.name = package_to_edit.name;
      this.state.includeBI = package_to_edit.includeBI;
    }
  },

  setup(props, context) {
    // create user form
    console.log("props: ", props.packages);
    const store = useStore();
    const initialUser = store.getters.currentUser;
    let state: FormState = reactive({
      name: "",
      includeBI: false,
    });
    let packageId = 0;
    let allpackages = props.packages;
    if (props.selectedPackage) {
      allpackages = props.packages.filter((value) => {
        let val = value as any;
        let selected = props.selectedPackage as any;
        return val.name != selected.name;
      });
      let package_to_edit = props.selectedPackage as Models.Package;
      let stateEdit: FormState = reactive({
        name: package_to_edit.name,
        includeBI: package_to_edit.includeBI,
      });
      state = stateEdit;
      packageId = package_to_edit.packageId;
    }


    const checkpckgName = value => {
      let flag = true;
      allpackages.forEach((pckg: any) => {
        if (pckg.name.toLowerCase() == value.toLowerCase()) {
          console.log(pckg.name.toLowerCase() == value.toLowerCase());
          flag = false;
          return flag;
        }
      });
      return flag;
    };

    const mustBeUniqueName = value => checkpckgName(value);

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage("Name is required.", required),
          maxLength: helpers.withMessage(
            "Package name is longer than 18 characters.",
            maxLength(18)
          ),
          mustBeUniqueName: helpers.withMessage(
            "Package name must be unique.",
            mustBeUniqueName
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditPackageForm");
    };

    return {
      //  create new user
      mustBeUniqueName,
      state,
      v$,
      closeForm,
      packageId,
      allpackages,
      checkpckgName,
      initialUser,
      //  end create new user
    };
  },
});
