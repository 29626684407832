
import {
  defineComponent,
  onUpdated,
  onMounted,
  ref,
  onUnmounted,
  onBeforeUnmount,
  reactive,
  computed,
} from "vue";
import useValidate from "@vuelidate/core";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Avatar from "@/components/Avatar.vue";
import { helpers, required } from "@vuelidate/validators";
import MessageBox from '@/components/MessageBox.vue';

export default defineComponent({
  name: "LookupPackage",
  components: {
    Avatar, MessageBox
  },
  data() {
    return {
      example: "123-456",
    };
  },
  emits: ["submitCompleted"],
  methods: {
    discard() {
      this.$emit("submitCompleted", null, null);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const load=this.state.name.split("-");
        const loadNumber=load[0];
        const packageNumber=load[1];
        this.$emit("submitCompleted", loadNumber, packageNumber);
      }
    },
  },

  setup(props, context) {
    const state = reactive({
      name: "",
    });

    const formatCorrectly = (value) => checkFormat(value);
    const mustBeNumber = (value) => checkNumber(value);
    const checkFormat = (value) => {
      const load = value.split("-");
      if(load.length==2)
        return load[0]!=""&& load[1]!="";
      return false;
    };
    const checkNumber = (value) => {
      const load= value.replace("-","");
      return !isNaN(load);
    };
    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage(
            "Load Number and Package Number are required",
            required
          ),
          formatCorrectly: helpers.withMessage(
            'Format "Load Number-Package Number" not followed',
            formatCorrectly
          ),
          mustBeNumber: helpers.withMessage(
            'Load Number and Package Number must be numerical values',
            mustBeNumber
          ),
        },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      v$,
      state,
    };
  },
});
