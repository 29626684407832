<template>
  <div class="card p-5" style="width: 100%" v-if="!showCatalogue">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Your Sterilizers</span>

        <!--<span class="text-muted mt-1 fw-bold fs-7">Over 500 members</span>-->
      </h3>

      <div
        class="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a sterilizer"
      >
        <a @click="switchtoFromAddSterr()" class="btn btn-sm btn-light-primary">
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotone/Files/File-Plus.svg" />
          </span>
          New Sterilizer
        </a>
      </div>
    </div>
    <div class="card-body py-3 row">
      <!-- <ag-grid-vue
              :defaultColDef="defaultColDef"
              :columnDefs="columnDefs"
              :rowData="rowData"
              @grid-ready="onGridReady"
              @gridSizeChanged="gridSizeChanged"
              style="width: 100%; height: 100%;"
              class="ag-theme-balham"
            >
            </ag-grid-vue>
            -->
      <div
        v-for="(ster, index) in rowData"
        :key="index"
        class="col-12 col-sm-6 col-md-4 mb-10 d-flex align-items-stretch"
        :style="`${rowData.length == 1 ? 'height: fit-content;' : ''}`"
      >
        <div class="card mx-2 shadow-hover width-fill-available tex-center">
          <img
            class="catalogue-image mt-3"
            :src="`${
              ster.catalog && ster.catalog.imageUrl
                ? ster.catalog.imageUrl
                : '/sterilizers_images/DefaultSteri.png'
            }`"
            onerror="this.src='/sterilizers_images/DefaultSteri.png';"
          />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title d-flex justify-content-between">
              <span class="col">{{ ster.name }}</span>
              <span
                :class="`badge badge-${
                  ster.isDecommissioned ? 'danger' : 'success'
                }`"
                >{{
                  `${ster.isDecommissioned ? "Out of Service" : "In Service"}`
                }}</span
              >
            </h5>
            <p class="card-text"></p>
            <p v-if="ster.model">
              Model: {{ ster.model }}
              <!-- <p v-if="ster.make">Make: {{ ster.make }}</p> 
              <p v-if="ster.manufacturer">Manufacturer: {{ster.manufacturer}}</p>
              <p v-if="ster.serialNumber">Serial #: {{ster.serialNumber}}</p>
              <p v-if="ster.isPreVacuum">Pre-Vacuum: {{ ster.isPreVacuum ? "Yes" : "No" }} </p> -->
            </p>
            <!-- <button type="button" class="btn btn-primary" @click="addSter(ster)">Add Sterilizer</button> -->
            <div
              class="d-flex justify-content-end mt-auto"
              style="height: inherit"
            >
              <button
                type="button"
                class="btn btn-secondary btn-sm m-1"
                style="color: #0073ff !important"
                @click="
                  $router.push({
                    path: `/settings/specific/${ster.sterilizerId}`,
                  })
                "
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm m-1"
                @click="confirmDeleteSteri(ster)"
              >
                Delete
              </button>
              <!-- <a @click="$router.push({ path: `/settings/specific/${ster.sterilizerId}` })" class=" card-link ml-5">Edit</a> -->
              <!-- <a @click="confirmDeleteSteri(ster)" class=" text-danger card-link ml-5"
              >Delete</a
            > -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card p-5 my-5" style="width: 100%" v-if="showCatalogue">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Add a Sterilizer</span>
      </h3>

      <div
        class="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
      >
        <a
          v-if="initialUser.isSuperAdmin"
          @click="switchtoFromAddCat()"
          class="btn btn-sm btn-light-primary"
        >
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotone/Files/File-Plus.svg" />
          </span>
          New Sterilizer Catalog
        </a>
        <input
          placeholder="Search..."
          type="text"
          style="height: 35px"
          v-model="filter.model"
          class="form-control form-control-solid col mx-1"
          id="source"
        />
        <a @click="switchtoFromAddSterr()" class="btn btn-sm btn-light-primary">
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotone/Code/Stop.svg" />
          </span>
          Cancel
        </a>
      </div>
    </div>
    <div class="card-body py-3 row">
      <SterilizerCatalogue
        @addSterilizer="addSterilizer"
        :filter="filter"
        :isSuperAdmin="initialUser.isSuperAdmin"
      />
    </div>
  </div>
  <!-- modals -->
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="add_ster_modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              `
            ${currentClinicName}`
            }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <add-sterilizer
          v-if="initialUser && showAddSterilizer"
          :allSterilizers="rowData"
          :initialUser="initialUser"
          :selectedSterilizer="selectedSterilizer"
          @closeSterModal="closeAddSterilizer"
        />
      </div>
    </div>
  </div>
  <!-- start delete package confirmation -->
  <div class="modal fade" tabindex="-1" id="delete_steri_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="steriToDelete">
            Delete sterilizer {{ steriToDelete.name }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <p v-if="steriToDelete">
            Are you sure you want to delete sterilizer {{ steriToDelete.name }}?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancel
          </button>
          <button
            v-if="steriToDelete"
            type="button"
            class="btn btn-danger"
            @click="deleteSteri(steriToDelete.sterilizerId)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end delete package confirmation -->
  <!-- end modal -->
</template>
<script>
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import * as bootstrap from "bootstrap";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import AddSterilizer from "@/components/settings/Sterilizers/AddSterilizer.vue";
import BtnCellRederer from "@/components/settings/BtnCellRederer.vue";
import CheckBoxCellRenderer from "@/components/settings/CheckBoxCellRenderer.vue";
import SterilizerCatalogue from "@/components/settings/Sterilizers/SterilizersCatalogue.vue";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  components: {
    AddSterilizer,
    AgGridVue,
    SterilizerCatalogue,
  },
  data() {
    return {
      deleteSteriModal: null,
      steriToDelete: null,
      filter: {},
      showCatalogue: false,
      showAddSterilizer: false,
      selectedSterilizer: null,
      addSterModal: null,
      defaultColDef: null,
      columnDefs: null,
      rowData: null,
      api: null,
      columnApi: null,
    };
  },
  async beforeMount() {
    this.refreshTable();
  },
  mounted() {
    var modalElement = document.getElementById("add_ster_modal");
    this.addSterModal = new bootstrap.Modal(modalElement); //
    modalElement = document.getElementById("delete_steri_modal");
    this.deleteSteriModal = new bootstrap.Modal(modalElement); //
  },
  methods: {
    confirmDeleteSteri(ster) {
      this.steriToDelete = ster;
      this.deleteSteriModal.show();
    },
    async deleteSteri(id) {
      let res = await ApiService.delete(`/Sterilizer/${id}`);
      if (res.status == 200) {
        this.deleteSteriModal.hide();
        this.refreshTable();
      }
    },
    switchtoFromAddCat() {
      this.$router.push({ path: `/settings/addcatalog` });
    },
    switchtoFromAddSterr() {
      //this.showCatalogue = !this.showCatalogue;
      let pack = {
        name: null,
        isPreVacuum: null,
        model: null,
        make: null,
        startingCycleNumber: null,
      };
      this.addSterilizer(pack);
    },
    addSterilizer(sterrInfo) {
      this.showAddSterilizer = false;
      this.showAddSterilizer = true;
      this.selectedSterilizer = sterrInfo;
      this.addSterModal.show();
      console.log(sterrInfo);
    },
    async refreshTable() {
      let self = this;
      this.defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
      };
      this.columnDefs = [
        {
          field: "name",
          headerName: "Name",
          filter: true,
          resizable: true,
        },
        {
          field: "model",
          headerName: "Model",
          filter: true,
          resizable: true,
        },
        {
          field: "serialNumber",
          headerName: "Serial Number",
          filter: true,
          resizable: true,
        },

        {
          field: "isPreVacuum",
          headerName: "Pre Vacuum",
          resizable: true,
          filter: true,
          valueFormatter: this.preVacFormatter,
        },
        {
          field: "isDecommissioned",
          headerName: "Decommissioned",
          valueFormatter: this.DecommissionFormatter,
          resizable: true,
          filter: true,
        },

        {
          field: "edit",
          headerName: "Actions",
          sortable: false,
          filter: false,
          cellRendererFramework: BtnCellRederer,
          resizable: true,
          maxWidth: "100",
          cellRendererParams: {
            clicked: function (selectedData) {
              console.log(selectedData);
              delete selectedData.edit;
              self.$router.push({
                path: `/settings/specific/${selectedData.sterilizerId}`,
              });
              // edit sterilizer go to specific sterilizer page
            },
            deleteClicked: function (selectedData) {
              //  delete specific sterilizer then refresh table
            },
          },
        },
      ];
      console.log(this.$route.params.data);
      let res = await ApiService.get("/Sterilizer");
      console.log(res.data);
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].edit = res.data[i];
      }

      this.rowData = res.data;
    },
    preVacFormatter(params) {
      if (params) {
        if (params.value) {
          return "yes";
        } else {
          return "";
        }
      }
    },
    DecommissionFormatter(params) {
      if (params) {
        if (params.value) {
          return "yes";
        } else {
          return "";
        }
      }
    },
    onGridReady(params) {
      this.api = params.api;
      this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },

    addSter() {
      this.openAddSterilizer();
    },
    openAddSterilizer() {
      this.addSterModal.show();
    },
    closeAddSterilizer() {
      console.log("closing modal");
      this.refreshTable();
      this.addSterModal.hide();
      //this.showCatalogue = !showCatalogue;
    },
  },
  setup() {
    setCurrentPageTitle("Sterilizers");
    const store = useStore();
    const initialUser = store.getters.currentUser;
    console.log(initialUser);
    const currentClinicName = initialUser.currentClinic.name;

    return {
      initialUser,
      currentClinicName,
    };
  },
});
</script>
<style lang="scss">
// @import "/node_modules/ag-grid-community/dist/styles/ag-grid.css";
// @import "/node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
.btn-shadow {
  box-shadow: 4px 4px 5px -3px grey !important;
}
.shadow-hover {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 40%) !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 100%) !important;
}
.font-14 {
  font-size: 14px !important;
}
.width-fill-available {
  width: -webkit-fill-available;
}
.catalogue-image {
  width: 250px;
  height: 200px;
  object-fit: cover;
  margin: auto;
}
</style>
