
import { defineComponent, ref } from "vue";
import { useAuth0 } from "@/auth/auth";
import ApiService from "@/core/services/ApiService";
import { UserContext } from "@/auth/UserContext";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { ClinicInfo } from "@/store/modules/AuthModule";
import store from "@/store/";

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  props: {
    widgetClasses: String,
  },
  methods: {},
  async mounted() {
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    const domain = "dev-qpn294pj.us.auth0.com";
    const clientId = "9faOlN5h7HpBLPeIPVQMTFGmJ8wHyGyQ";
    const audience = "https://api.verify.ca";

    const redirectTo = this.$route.query.redirectFrom ?? "dashboard";
    console.log("redirectTo", redirectTo);
    console.log("vue query", this.$route.query);
    console.log("window query", window.location.pathname);

    const auth0 = await useAuth0({
      domain,
      clientId,
      audience,
      redirectUri: `${window.location.origin}/login?redirectFrom=${redirectTo}`,
      onRedirectCallback: (appState) => {
        console.log("appstate", appState);
        this.$router.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      },
    });

    const params = new URL(window.location.href).searchParams;
    const routeLoginHint = params.get("u");

    console.log(auth0);

    if (auth0.loading) {
      throw "something is wrong";
    }

    if (!this.$auth.isAuthenticated) {
      console.log("not authenticated");

      if (!this.$auth.error) {
        if (routeLoginHint) {
          console.log("routeLoginHint", routeLoginHint);
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.$auth.loginWithRedirect({
            login_hint: routeLoginHint as string,
          });
        } else {
          console.log("loginWithRedirect", "login");
          this.$auth.loginWithRedirect({});
        }
        await ApiService.setAuthHeader();
      }
    } else {
      await ApiService.setAuthHeader();
    }

    const context = new UserContext(this.$route, this.$store);
    await context.buildCurrentUser();

    let goToPin
    const clinicInfo : ClinicInfo = await (await ApiService.get("clinic")).data;    
    if (clinicInfo.enablePin) {
      const clinicUserToken = localStorage.getItem("ClinicUserToken");
      if (clinicUserToken) {
        ApiService.setClinicUserTokenHeader(clinicUserToken);
        await context.buildCurrentUser();
        goToPin = false;
      } else {
        goToPin = true;
      }
    }

    this.$store.commit(Mutations.SET_CLINIC, clinicInfo);

    if (goToPin){
      console.log("pushing pin");
      this.$router.push("/pin");
    } else {
      this.$router.push(redirectTo.toString());
    }
    store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    
  },
});
