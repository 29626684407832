<template>
  <div
    class="modal modal1 fade"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    :id="id"
    style="position: fixed; z-index: 1900;"
    :data-toggle="id" :data-target="id"
  >
    <div style="height: 100%;overflow-y: hidden;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ title }}
            </h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-light-primary ms-2"
              @click="close()"
              aria-label="Close"
            >
              <span class="svg-icon svg-icon-2x"></span>
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <p>{{ bodyText }}</p>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              :data-bs-dismiss="id"
              @click="no()"
            >
              {{ cancelText }}
            </button>
            <button type="submit" class="btn btn-primary" @click="yes()">
              {{ okText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import * as bootstrap from "bootstrap";
export default defineComponent({
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    closeAfterYes: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    bodyText: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    okText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal1: null,
    };
  },

  watch: {
    show: function(val) {
      if (val) {
        this.modal1.show();
        document.getElementById(this.id).style.display = "block";
      } else {
        // this.modal1.hide()
        document.getElementById(this.id).style.display = "none";
      }
    },
  },

  mounted() {
    const modalElement = document.getElementById(this.id);
    this.modal1 = new bootstrap.Modal(modalElement);
  },

  methods: {
    no() {
      this.cleanUp();
      this.$emit("no");
    },
    yes() {
      if (this.closeAfterYes) {
        this.$emit("yes");
        this.modal1.hide();
      } else {
        this.$emit("yes");
        this.cleanUp();
      }
    },
    close() {
      document.getElementById(this.id).style.display = "none";
      this.no();
    },
    cleanUp() {
      let backdrops = document.querySelectorAll(
        "#kt_body  div.modal-backdrop.fade.show"
      );
      if (backdrops[backdrops.length - 1]) {
        backdrops[backdrops.length - 1].remove();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.modal1 {
  background: rgba(0, 0, 0, 0.3);
}
</style>
