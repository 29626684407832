
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ImpactedPackages",
  props: {
    failNames: Array,
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      columnDefs: new Array<any>(),
      rowData: new Array<any>(),
    };
  },
  methods: {
    onFirstDataRendered(params) {
      params.api.setDomLayout("autoHeight");
      params.api.sizeColumnsToFit();
    },
  },

  async mounted() {
    this.columnDefs = [
      {
        field: "name",
        headerName: "Package Name",
        filter: false,
        sortable: true,
      },
      {
        field: "id",
        headerName: "Load Number - Package Number",
        sortable: true,
        filter: false,
      },
    ];
    this.rowData = this.failNames as String[];
  },
});
