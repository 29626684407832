
import { defineComponent, computed, ref } from "vue";
import { ErrorMessage, Field, Form, useField, useForm } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { BIState } from "@/core/Enums";
import Avatar from "@/components/Avatar.vue";
import QrcodeVue from "qrcode.vue";
import moment from "moment-timezone";
import { format } from "date-fns";
import ConfirmModal from "@/components/ConfirmModal.vue";
import MessageBox from "@/components/MessageBox.vue";
import Modal from "@/components/Modal.vue";
export default defineComponent({
  name: "CreateNewLoad",
  props: {
    sterilizer: Object,
  },
  components: {
    ErrorMessage,
    Field,
    Form,
    Avatar,
    QrcodeVue,
    ConfirmModal,
    MessageBox,
    Modal,
  },
  emits: ["submitCompleted"],
  data() {
    return {
      // list: [] as any[],
      programs: [] as any[],
      sterilizerSummary: null,
      biLots: [] as any[],
      latestLoadNumber: 1 as number,
      showConfirm: false as Boolean,
    };
  },
  methods: {
    saveWithoutPrinting() {
      this.usePrintConfirm = false;
      this.showSavePrintConfirm = false;
      (document.querySelector(
        "#submitSuccessButton"
      ) as HTMLButtonElement).click();
    },
    getdate() {
      return format(new Date(), "MMM d hh:mm a");
    },
    cancel(event) {
      if (event) {
        event.preventDefault();
      }
      // if (confirm('Are you sure you want to discard your selections and cancel this load?')){
      //   localStorage.removeItem(`Sterilizer_${this.sterilizer?.sterilizerId}_cart`);
      //   this.$emit("submitCompleted");
      // }
      console.log(this.selectedPackages.length)
      if (this.selectedPackages.length > 0) {
        this.showConfirm = true;
      } else {
        localStorage.removeItem(
          `Sterilizer_${this.sterilizer?.sterilizerId}_cart`
        );
        this.$emit("submitCompleted");
      }
    },
    confirmYes() {
      this.showConfirm = false;
      localStorage.removeItem(
        `Sterilizer_${this.sterilizer?.sterilizerId}_cart`
      );
      this.$emit("submitCompleted");
    },
    confirmNo() {
      this.showConfirm = false;
    },

    reprint(event) {
      if (event) {
        event.preventDefault();
      }
      while (this.alreadyPrintedPackages.length > 0) {
        this.alreadyPrintedPackages.pop();
      }
      setTimeout(() => {
        document.getElementById("printButton")?.click();
      }, 500);
      //this.print(event)
    },
    print(event) {
      let isFullScreen = !! document.fullscreenElement;
      if (event) {
        event.preventDefault();
      }
      const prtHtml = document.getElementById("printDiv")?.innerHTML;
      // const WinPrint = window.open(
      //   "",
      //   "",
      //   "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      // );
      // if (WinPrint !== null) {
      let htmlString = `
      <!doctype html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <title></title>
    <link href="labels.css" rel="stylesheet" type="text/css" >
    <style>
    body {
        width: 2in;
        margin: 0in;
        }
    .label{
        font-size: 12px;
        /* Avery 5160 labels -- CSS and HTML by MM at Boulder Information Services */
        width: 1.90in; /* plus .6 inches from padding */
        height: 0.90in; /* plus .125 inches from padding */
        /*padding: .125in .3in 0;
        margin-right: .125in; /* the gutter */
        margin: 0.05in;
        float: left;

        text-align: left;
        overflow: hidden;

        /*outline: 1px dotted;  outline doesn't occupy space like border does */
        }
    .page-break  {
        clear: left;
        display:block;
        page-break-after:always;
        }
    img{
        height:0.5in;
        width:2in;
    }
    </style>

</head>
<body>

${prtHtml}


<div class="page-break"></div>
<!--<img src="https://www.zebra.com/content/dam/zebra_new_ia/en-us/knowledge-articles/send-enter-key.jpg" />-->
</body>
</html>
      
      
      `;
      // WinPrint.document.write(htmlString)
      // WinPrint.document.close();
      // WinPrint.focus();
      // WinPrint.print();
      // WinPrint.close();
      // }
      var oHideFrame = document.createElement("iframe") as HTMLIFrameElement;
      oHideFrame.onload = () => {
        oHideFrame.contentWindow!.focus();
        oHideFrame.contentWindow!.print();
        oHideFrame.contentWindow!.onafterprint = () => {
          console.log(isFullScreen);
          if(isFullScreen){
           document.documentElement.requestFullscreen();
          }
          document.body.removeChild(oHideFrame);
        };
      };
      oHideFrame.style.position = "fixed";
      oHideFrame.style.right = "0";
      oHideFrame.style.bottom = "0";
      oHideFrame.style.width = "0";
      oHideFrame.style.height = "0";
      oHideFrame.style.border = "0";
      oHideFrame.srcdoc = htmlString;
      document.body.appendChild(oHideFrame);

      for (const p of this.packagesToPrint) {
        this.alreadyPrintedPackages.push(p);
      }
      this.updateCart();
    },
    getLabelInfo(sterilizerSummary, programSelection, item, currentUser) {
      return `~${item.name}: L#${this.latestLoadNumber + 1} P${
        item.packageNumber
      } ${sterilizerSummary.name} C#${+this
        .cycleNumber} ${this.getdate()} ${currentUser.firstName +
        currentUser.lastName.charAt(0).toUpperCase()}
`;
    },
    async load() {
      ApiService.get("Package").then((r) => {
        let flatList = r.data;
        flatList = flatList.map((p) => ({
          packageId: p.packageId,
          name: p.name,
          includeBI: p.includeBI,
        }));

        flatList.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        let packageList: any[] = [];
        let currentRow: any[] = [];
        let columnIndex = 0;
        let index = 0;
        let rowIndex = -1;

        for (let kit of flatList) {
          if (index % 100 === 0) {
            // means new line
            columnIndex = 0;
            rowIndex++;
            currentRow = [];
            packageList[rowIndex] = currentRow;
          }

          currentRow[columnIndex] = kit;
          index++;
          columnIndex++;
        }
        this.list = packageList;
        this.flatList = flatList;
      });

      ApiService.get(`load/latestLoadNumber`).then((r) => {
        this.latestLoadNumber = r.data;
      });

      ApiService.get(
        `Sterilizer/${this.sterilizer?.sterilizerId}/programStates`
      ).then((r) => {
        this.programs = r.data;
        this.programSelection = this.programs[0];
      });

      ApiService.get(`bi/activelot`).then((r) => {
        this.biLots = r.data;
        this.biLotSelection = this.biLots[0];
      });

      ApiService.get(`SterilizerSummary/${this.sterilizer?.sterilizerId}`).then(
        (r) => {
          let sterilizerSummary = r.data;
          if (sterilizerSummary.wrappedBIState === BIState.NeedsBI) {
            this.includeBITestForWrapped = true;
          } else {
            this.includeBITestForWrapped = false;
          }

          if (sterilizerSummary.unwrappedBIState === BIState.NeedsBI) {
            this.includeBITestForUnwrapped = true;
          } else {
            this.includeBITestForUnwrapped = false;
          }
          this.sterilizerSummary = sterilizerSummary;
          this.cycleNumber = sterilizerSummary.cycleNumber + 1;
        }
      );
    },
    onPackageSelection(pack, event) {
      const oldCount = this.selectedPackages.filter(
        (p) => p.packageId === pack.packageId
      ).length;
      const newCount = event.target.value === "" ? 0 : +event.target.value;
      if (newCount === oldCount) {
        return;
      } else if (newCount > oldCount) {
        for (let index = oldCount; index < newCount; index++) {
          this.packageNumber = +this.packageNumber + 1;
          const packageInfo = {
            packageId: pack.packageId,
            name: pack.name,
            includeBI: pack.includeBI,
            packageNumber: this.packageNumber,
          };
          this.selectedPackages.push(packageInfo);
        }
      } else if (newCount < oldCount) {
        console.log("need to remove some packages");
        for (let index = newCount; index < oldCount; index++) {
          this.selectedPackages.splice(
            this.selectedPackages.findIndex(
              (p) => p.packageId === pack.packageId
            ),
            1
          );
        }
      }
      this.updateCart();
    },
    updateCart() {
      console.log("packages", this.packages);
      const sterilizerCart = {
        packageNumber: +this.packageNumber,
        selectedPackages: this.selectedPackages,
        packages: this.packages,
        alreadyPrintedPackages: this.alreadyPrintedPackages,
      };
      localStorage.setItem(
        `Sterilizer_${this.sterilizer?.sterilizerId}_cart`,
        JSON.stringify(sterilizerCart)
      );
    },
  },

  async mounted() {
    await this.load();
  },
  setup(props, context) {
    console.log("props", props);
    let showSavePrintConfirm = ref<boolean>(false);
    let usePrintConfirm = ref<boolean>(true);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const includeBITestForUnwrapped = ref<boolean>(false);
    const includeBITestForWrapped = ref<boolean>(false);
    const flatList = ref<any[]>([]);
    const list = ref<any[]>([]);
    const packages = ref({});
    const cycleNumber = ref<number>(0);
    const alreadyPrintedPackages = ref<Array<Object>>(new Array<Object>());
    const selectedPackages = ref<Array<any>>(new Array<any>());
    const packageNumber = ref(0);
    const { handleSubmit, submitCount, validate } = useForm();
    let showPackageValidationModal = ref<boolean>(false);
    //check local storage to see if "any packages are in the cart" since last load
    const sterilizerCartString = localStorage.getItem(
      `Sterilizer_${props.sterilizer?.sterilizerId}_cart`
    );
    if (sterilizerCartString) {
      const sterilizerCart = JSON.parse(sterilizerCartString);
      packageNumber.value = sterilizerCart.packageNumber;
      selectedPackages.value = sterilizerCart.selectedPackages;
      packages.value = sterilizerCart.packages;
      alreadyPrintedPackages.value = sterilizerCart.alreadyPrintedPackages;
    }

    const {
      value: programSelection,
      errorMessage: programSelectionErrorMessage,
    } = useField<null | { programId: () => 0; biState: BIState; name: string }>(
      "Program",
      (inputValue) => {
        if (!inputValue) {
          return "Please select a program";
        } else {
          return true;
        }
      }
    );

    const {
      value: biLotSelection,
      errorMessage: biLotSelectionErrorMessage,
    } = useField<null | { biLotId: () => 0; biLotNumber: () => 0 }>(
      "BiLot",
      (inputValue) => {
        if (!biNotification.value.includeBI) {
          return true;
        } else if (!inputValue) {
          return "Please select a BI Lot";
        } else {
          return true;
        }
      }
    );

    const {
      value: confirmedBIIncluded,
      errorMessage: confirmedBIIncludedErrorMessage,
    } = useField<boolean>("confirmedBIIncluded", (inputValue) => {
      if (!biNotification.value.includeBI) {
        return true;
      } else if (!(inputValue as boolean)) {
        return "Please confirm that you have included a biological indicator in this load";
      } else {
        return true;
      }
    });

    // const selectedPackages = computed(() => {
    //   const expandedPackages: any[] = [];

    //   for (const packId in packages.value) {
    //     const count: number = +packages.value[packId];
    //     if (count > 0) {
    //       //get name of pack

    //       const pack = flatList.value.find((p) => +p.packageId === +packId);

    //       for (let index = 0; index < count; index++) {
    //         //packageNumber = packageNumber + 1;
    //         const packageInfo  = {
    //           packageId : pack.packageId,
    //           name: pack.name,
    //           includeBI: pack.includeBI,
    //           packageNumber: packageNumber
    //         }
    //         expandedPackages.push(packageInfo);
    //       }
    //     }
    //   }
    //   console.log("exapnded packages", expandedPackages)
    //   return expandedPackages;
    // });

    const biNotification = computed(() => {
      if (selectedPackages.value.some((p) => p.includeBI)) {
        return { includeBI: true, reason: "A Biological Indicator test is required for implants." };
      }
      if (programSelection.value) {
        if (programSelection.value.biState === BIState.LastBIFailed) {
          return { includeBI: true, reason: `The previous Biological Indicator test for the ${programSelection.value.name} failed. Include a Biological Indicator in this load and test again` };
        }
        if (programSelection.value.biState === BIState.NeedsBI) {
          return { includeBI: true, reason: `A daily Biological Indicator test is required for ${programSelection.value.name} programs on the ${props.sterilizer?.name}.` };
        } 
      }      
      return { includeBI: false, reason: "" };
    });

    const packagesToPrint = computed(() => {
      const printed = Array.from(alreadyPrintedPackages.value);
      const packagesToPrintTemp = new Array<Object>();
      for (const p of selectedPackages.value) {
        const index = printed.findIndex(
          (item) =>
            (item as any).packageId === p.packageId &&
            (item as any).packageNumber === p.packageNumber
        );
        if (index < 0) {
          packagesToPrintTemp.push(p);
        } else {
          printed.splice(index, 1);
        }
      }
      if (biNotification.value.includeBI) {
        const index = printed.findIndex(
          (item) => (item as any).biLotId === biLotSelection.value?.biLotId
        );
        if (index < 0) {
          packagesToPrintTemp.push({
            biLotId: biLotSelection.value?.biLotId,
            biLotNumber: biLotSelection.value?.biLotNumber,
          });
        } else {
          printed.splice(index, 1);
        }
      }
      return packagesToPrintTemp;
    });

    const printSummary = computed(() => {
      const showPrintAll =
        selectedPackages.value.length +
          (biNotification.value.includeBI ? 1 : 0) >
          0 && alreadyPrintedPackages.value.length > 0;
      const totalPackagesToPrint =
        selectedPackages.value.length +
        (biNotification.value.includeBI ? 1 : 0);

      let printAllMessage = "";
      if (
        biNotification.value.includeBI &&
        selectedPackages.value.length === 0
      ) {
        printAllMessage = "Print 1 BI label";
      } else if (
        !biNotification.value.includeBI &&
        selectedPackages.value.length > 0
      ) {
        printAllMessage = `Print ${selectedPackages.value.length} package labels`;
      } else if (
        biNotification.value.includeBI &&
        selectedPackages.value.length > 0
      ) {
        printAllMessage = `Print 1 BI label and ${selectedPackages.value.length} package labels`;
      }

      let printRemaimingMessage = "test";
      if (
        biNotification.value.includeBI &&
        packagesToPrint.value.length - 1 === 0
      ) {
        printRemaimingMessage = "Print 1 BI label";
      } else if (
        !biNotification.value.includeBI &&
        packagesToPrint.value.length > 0
      ) {
        printRemaimingMessage = `Print ${packagesToPrint.value.length} package labels`;
      } else if (
        biNotification.value.includeBI &&
        packagesToPrint.value.length - 1 > 0
      ) {
        printRemaimingMessage = `Print 1 BI label and ${packagesToPrint.value
          .length - 1} package labels`;
      }

      return {
        showPrintAll,
        totalPackagesToPrint,
        printAllMessage,
        printRemaimingMessage,
      };
    });

    const submit = handleSubmit(async (values) => {
      console.log(values);
      if (!submitButtonRef.value) {
        return;
      }

      if(selectedPackages.value.length == 0){
        showPackageValidationModal.value = true;
        return;
      }

      if (packagesToPrint.value.length > 0 && usePrintConfirm.value) {
        showSavePrintConfirm.value = true;
        return;
      } else {
        showSavePrintConfirm.value = false;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      localStorage.removeItem(
        `Sterilizer_${props.sterilizer?.sterilizerId}_cart`
      );

      class Pack {
        PackageId: number;
        PackageNumber: number;

        constructor(packageId: number, packageNumber: number) {
          this.PackageId = packageId;
          this.PackageNumber = packageNumber;
        }
      }

      interface Program {
        ProgramId: number;
      }

      try {
        const packsForBackend: Pack[] = [];

        console.log("packages");
        console.log(programSelection);
        for (const pack of selectedPackages.value) {
          packsForBackend.push(new Pack(+pack.packageId, +pack.packageNumber));
        }

        let programId = 0;
        const shouldDoSomething = programSelection !== null;
        if (shouldDoSomething) {
          programId = + programSelection.value!.programId;
        }

        let biLogId: number | null = null;
        if (biNotification.value.includeBI && biLotSelection.value?.biLotId) {
          biLogId = Number(biLotSelection.value!.biLotId);
        }

        await ApiService.postData(
          "Load",
          {
            SterilizerId: props.sterilizer?.sterilizerId,
            UserId: 1,
            ProgramId: programId,
            BILotId: biLogId,
            Packages: packsForBackend,
            CycleNumber: cycleNumber.value,
          },
          {}
        );

        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          context.emit("submitCompleted");
        }
      } catch (ex) {
        alert(ex);
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
      }
    });

    return {
      showPackageValidationModal,
      submit,
      submitButtonRef,
      packages,
      programSelection,
      programSelectionErrorMessage,
      biLotSelection,
      biLotSelectionErrorMessage,
      selectedPackages,
      flatList,
      list,
      includeBITestForUnwrapped,
      includeBITestForWrapped,
      biNotification,
      submitCount,
      confirmedBIIncludedErrorMessage,
      confirmedBIIncluded,
      cycleNumber,
      alreadyPrintedPackages,
      packagesToPrint,
      packageNumber,
      printSummary,
      usePrintConfirm,
      showSavePrintConfirm,
    };
  },
});
