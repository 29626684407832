
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { defineComponent, reactive, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";

interface FormState {
  name: string;
  isWrapped: boolean;
  isBD: boolean;
}

export default defineComponent({
  props: {
    sterilizerId: {
      type: Number,
      required: true,
    },
    sterName: {
      type: String,
      required: true,
    },
    programToEdit: {
      required: true,
      type: Object,
    },
  },
  updated() {
    if (this.programToEdit && this.programId != this.programToEdit.programId) {
      this.programId = this.programToEdit.programId;
      this.state.name = this.programToEdit.name;
      this.state.isWrapped = this.programToEdit.isWrapped;
      this.state.isBD = this.programToEdit.isBD;
    }
  },
  methods: {
    hidePrograms() {
      this.$emit("closeAddPrograms");
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let payload: any = {
          name: this.state.name,
          sterilizerId: this.sterilizerId,
          isWrapped: this.state.isWrapped,
          isBD: this.state.isBD,
        };
        if (this.programToEdit.programId) {
          payload.programId = this.programToEdit.programId;
        }
        console.log(payload);
        const res = await ApiService.postData("", payload, {});
        if (res.status === 200) {
          // ok
          // let user know that program x has been added to ster y
          // reset form
          //
        } else {
          // not okay
        }
      }
    },
  },
  setup(props, context) {
    let programId = -10;
    if (props.programToEdit) {
      programId = props.programToEdit.id;
    }
    let state: FormState = reactive({
      name: "",
      isWrapped: false,
      isBD: false,
    });
    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage("Name is required.", required),
        },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      state,
      v$,
      programId,
    };
  },
});
